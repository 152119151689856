import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/model/Category';

@Component({
  selector: 'app-th-compinfo',
  templateUrl: './compinfo.th.component.html',
  styleUrls: ['./compinfo.th.component.css']
})
export class CompinfoThComponent implements OnInit {

  category: Category[];

  constructor() { }

  ngOnInit() {
    this.category = this.getCompanyInfo()
  }

  getCompanyInfo() {
    return [
      {
        image: 'img/compinfo/infoImage01.jpg',
        name: "ธุรกิจหลักขององค์กร",
        link: '/th/whatsanken'
      }, {
        image: 'img/compinfo/infoImage02.jpg',
        name: 'แนวคิดการดำเนินธุรกิจ',
        link: '/th/business'
      }, {
        image: 'img/compinfo/infoImage03.jpg',
        name: 'สาส์นจากผู้บริหาร',
        link: '/th/topmsg'
      }, {
        image: 'img/compinfo/infoImage04.jpg',
        name: 'ข้อมูลบริษัท',
        link: '/th/outline'
      }, {
        image: 'img/compinfo/infoImage05.jpg',
        name: 'ประวัติความเป็นมา',
        link: '/th/history'
      }, {
        image: 'img/compinfo/infoImage06.jpg',
        name: 'ที่ตั้ง',
        link: '/th/bizlocate'
      }, {
        image: 'img/compinfo/infoImage07.jpg',
        name: 'บริษัทในเครือ/หุ้นส่วนทางการค้า',
        link: '/th/partner'
      }, {
        image: 'img/compinfo/infoImage08.jpg',
        name: 'กิจกรรมเพื่อสิ่งแวดล้อม',
        link: '/th/environment'
      }
    ]
  }

}
