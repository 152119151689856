<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Business Philosophy</h3>
          </div>
          <p>
            Introduction of our business philosophy.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="COMP" menuIndex="1"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>Business Philosophy</h3>
            <br />
            <div class="text-center">
              <img src="img/business/business01.jpg" alt="" />
            </div>
          </div>

          <div class="blog-post-style-one">
            <h3>Meaning of Sanken's logo</h3>
            <br />
            <div class="text-center">
              <img src="img/business/business02.gif" alt="" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
