<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>การวิจัยและการพัฒนา</h3>
          </div>
          <!-- <p>
            Introduction of our present and past research & development.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="3"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>การทำให้เหล็กเย็นตัวลงอย่างรวดเร็ว(Air Quench)</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_01.jpg" alt="" />
            </div>
            <p>เราได้สร้างเครื่องจักรสำหรับทดลองและ พัฒนาเทคโนโลยี การทำให้เหล็กเย็นตัวลงอย่างรวดเร็ว(Air Quenching)
              เพื่อแก้ไขปัญหา ความเค้นตกค้าง (Residual stress)และการผิดรูปของชิ้นงาน ที่เกิดจากวิธีการแบบเดิมๆ
              ซึ่งเราได้พยายามทำการทดลองปรับเปลี่ยนปัจจัยต่างๆให้เกิดความหลากหลายเพื่อให้ได้ผลลัพธ์ที่ดีที่สุด
              นอกจากนี้เรายังทำการทดสอบการชุบแข็งด้วยโลหะผสมไทเทเนียม และจากประสบการณ์และการทดสอบที่ผ่านมา
              ทำให้เราสามารถจัดหาอุปกรณ์ที่ดีและเหมาะสมที่สุดตามความต้องการ (requirement) ของลูกค้า
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>การวิเคราะห์และออกแบบเพื่อปรับให้เหมาะสม</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <img src="img/rd/research_08.jpg" alt="" />
            </div>
            <p>เราได้ทำการวิเคราะห์และออกแบบผลิตภัณฑ์ให้ดีขึ้นโดยใช้ วิธีของ Taguchi (MT)
              ในการที่จะตอบโจทย์ความต้องการของลูกค้า เราเริ่มจากการนำเสนอแผนการทดลอง
              จนถึงขั้นตอนที่กำลังพัฒนาในอุปกรณ์จริง รวมถึงการคาดเดาประสิทธิภาพการทำงานและความสอดคล้องโดยยึดมาตรฐานสากล
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>อุปกรณ์แช่กรดแบบไหลย้อน (counter-flow acid pickling equipment) สำหรับผลิตอุปกรณที่เป็นลวดชุบสังกะสี</h3>
            <br />
            <div class="col-md-12 shift-bottom">
              <div class="col-md-6 text-center">
                <img src="img/rd/research_03.jpg" alt="" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/rd/research_02.jpg" alt="" />
              </div>
            </div>
            <p>อุปกรณ์แช่กรดของเราได้นำดีไซน์แบบไหลย้อนมาใช้เพื่อเร่งปฏิกริยาระหว่างลวดและกรด
              ซึ่งทำให้ระยะเวลาโดยรวมของการใช้แทงค์แช่กรดสั้น รูปแบบลักษณะนี้จะช่วยลดปริมาณการระเหยของ ไอ/ควัน
              hydrochloric เพื่อให้สภาวะแวดล้อมในการทำงานดีขึ้นและลดอัตราการผลาญของกรด hydrochloric
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>การพัฒนาของ หัวแก๊ซเกิดใหม่แบบมีม่านอากาศ (Slit Nozzle Regenerartive Burner)</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_04.jpg" alt="" />
            </div>
            <p>เราได้ทำการพัฒนา หัวแก๊ซเกิดใหม่แบบมีม่านอากาศ ซึ่งเหมาะสำหรับการตีเหล็กโดยใช้เตาหลอม-อุ่น
              ขนาดกลางหรือเล็ก เมื่อเตาหลอมที่มีอยู่ถูกปรับปรุงใหม่จากหัวแก๊ซดั้งเดิมไปเป็นหัวแก๊ซแบบเกิดใหม่
              จะเกิดปัญหาต่างๆเช่นการจำกัดวงในการหลอม (localized heating)
              และการสูญเสียโครงสร้างของเหล็กของชิ้นงานที่ถูกหลอม หัวแก๊ซเกิดใหม่แบบมีม่านอากาศ (Slit Nozzle
              Regenerartive Burner) ของเราช่วยในการขยายวงกว้าง(ในแนวนอน)ของหัวแก๊ซ
              และด้วยเหตุผลนี้เองจึงมั่นใจได้ว่าความสูงของพื้นที่ในการหลอมนั้นมีประสิทธิภาพเสมือนก่อนการปรับปรุงใหม่
              และมันยังสามารถช่วยให้เตาหลอมมีขนาดกะทัดรัดเมื่อถูกสร้างขึ้นใหม่
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>การพัฒนาของ เตาหลอม-อุ่นอลูมิเนียมที่เป็นฉนวนกันความร้อน</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-6 text-center shift-bottom">
                <img src="img/rd/research_05.jpg" alt="" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/rd/research_06.jpg" alt="" />
              </div>
            </div>
            <p>เราได้ทำการวิจัยเกี่ยวกับวัตถุต้านทานไฟ และวิธีในการติดตั้ง
              เรายังได้พัฒนาเตาหลอม-อุ่นอลูมิเนียมเหลวที่มีความต้านทานไฟสูงเพื่อปรับปรุงอัตราในการใช้พลังงาน
              และสภาวะแวดล้อมในการทำงาน ทำให้ผลิตภัณฑ์ของเรามีความต้านทานไฟได้สูงขึ้น 20%
              เมื่อเทียบกับเตาอุ่นโลหะแบบดั้งเดิมของเรา
              เทคโนโลยีนี้ถูกใช้ประโยชน์อย่างเต็มที่ในเครื่อง เตาหลอม-อุ่นโลหะพลังงานไฟฟ้า “S-MIC”
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>อุปกรณ์การทดลองเผาไหม้สำหรับวัสดุติดไฟได้</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_07.jpg" alt="" />
            </div>
            <p>ในการที่จะเพิ่มความปลอดภัยในการทำงานของระบบการทำให้ชิ้นส่วนเครื่องจักรแห้ง IDEX และ
              เตาอนุรักษ์สิ่งแวดล้อม
              เราได้เก็บข้อมูลมากมายซึ่งได้จากอุปกรณ์การทดลองเผาไหม้ของพวกเราจากการเผาไหม้ของสารหล่อเย็น (the combustion
              of coolant) ซึ่งถูกใช้ในกระบวนการทางเครื่องจักรของผลิตภัณฑ์อลูมิเนียม
              โดยเราพยายามที่จะเลือกพารามิเตอร์เช่น อุณหภูมิและระดับออกซิเจนในระบบทำให้แห้งที่กำลังใช้งานจริง (actual
              drying system)
              เรายังคงเดินหน้าพัฒนาผลิตภัณฑ์ของเราต่อเพื่อที่เราจะสามารถจัดหาเตาอบแห้งที่ปลอดภัยและถูกควบคุมอย่างเหมาะสมให้กับท่านลูกค้าของเรา
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>