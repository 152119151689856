<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>การประหยัดพลังงาน</h3>
          </div>
          <!-- <p>
            Introduction of energy-saving technologies we have worked on.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="5"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>เทคโนโลยีประหยัดพลังงานที่เรานำมาใช้</h3>
            <br />
            <div class="col-md-6 text-left">
              <img src="img/energy/ene_save01.gif" alt="" />
            </div>
            <p>ในปีค.ศ. 1970, เราได้สร้างเตาหลอมอุตสาหกรรมประหยัดพลังงานเครื่องแรกในญี่ปุ่น
              พร้อมด้วยฉนวนกันความร้อนเซรามิกส์ไฟเบอร์แบลงค์เก็ทแบบผืนผ้า (Ceramic Fiber Blanket)
              ตั้งแต่นั้นเป็นต้นมาเราทำการส่งเสริม/สนับสนุนให้มีการประหยัดพลังงานในเตาหลอมอุตสาหกรรม
              โดยพัฒนาเทคโนโลยีการสันดาปแบบใหม่ (new combustion) และเทคโนโลยีการกู้คืนพลังงานความร้อน (waste heat
              recovery) ตามลำดับ
              <br />
              <br />
            </p>
            <hr />
          </div>

          <div class="service-single-content">
            <h3>การปรับปรุงอุปกรณ์เพื่อช่วยให้ประหยัดพลังงาน</h3>
            <div class="row image-col">
              <div class="col-md-6">
                <p>ปัจจัยต่างๆ ที่จะช่วยให้ประหยัดพลังงาน มี 3 ข้อดังนี้</p>
                <ul class="checklist">
                  <li><img src="img/service-bulleted-list.png" alt="Awesome Image" />การออกแบบเตาหลอม
                    (มีขนาดกระทัดรัด, การเพิ่มการต้านทานความร้อนของเตา, การลดการสูญเสียในรูปแบบต่างๆ เช่น
                    การระบายความร้อนด้วยน้ำ เป็นต้น)</li>
                  <li><img src="img/service-bulleted-list.png"
                      alt="Awesome Image" />การนำเอาพลังงานความร้อนเหลือทิ้งกลับมาใช้ประโยชน์ (เครื่องแลกเปลี่ยนความร้อน
                    [recuperator], หัวเผาแบบรีเจนเนอเรทีฟ [regenerative burner] เป็นต้น)</li>
                  <li><img src="img/service-bulleted-list.png" alt="Awesome Image" />ระบบควบคุมการเผาไหม้
                    (ระบบควบคุมอัตราส่วนการเผาไหม้:เชื้อเพลิง-อากาศ, ระบบควบคุมแรงดันเตาหลอม,
                    การเพิ่มประสิทธิภาพรูปแบบการให้ความร้อน[heating curve] เป็นต้น)</li>
                </ul>
              </div>
              <div class="col-md-6 text-right">
                <img src="img/energy/ene_save02.gif" alt="" />
              </div>
            </div>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>
              ตัวอย่างของดีไซน์เตาหลอม (มีขนาดกระทัดรัด, การเสริมฉนวนกันความร้อน, การลดการสูญเสียในรูปแบบต่างๆ)
            </h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/energy/ene_save01.jpg" alt="" />
            </div>
            <h4>การออกแบบเตาหลอมให้มีขนาดเล็กลง</h4>
            <br />
            <p>
              การที่เตามีขนาดเล็กลงช่วยลดการแผ่ไอร้อนลงถึง25% จึงช่วยประหยัดพลังงาน และลดการใช้พื้นที่เพื่อทำการติดตั้ง
            </p>
            <br />
          </div>

          <br />
          <br />

          <div class="blog-post-style-one">
            <h4>การเสริมฉนวนกันความร้อน</h4>
            <br />
            <p>
              การใช้วัสดุฉนวนกันความร้อนที่มีขนาดเบา จะช่วยลดความร้อนสะสมและการสูญเสียความร้อนจากตัวเตา
            </p>
            <div class="col-md-12 text-center shift-bottom shift-top">
              <div class="col-md-6">
                <img src="img/energy/ene_save02.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save03.gif" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save03.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save04.gif" alt="" />
              </div>
            </div>
          </div>

          <br />

          <div class="blog-post-style-one">
            <h4>การลดลงของการสูญเสียความร้อนของน้ำหล่อเย็น</h4>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/energy/ene_save04.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save05.jpg" alt="" />
              </div>
            </div>
            <br />
            <p>
              การปรับปรุงของผลิตภัณฑ์ต้านทานความร้อนสูงได้ช่วยลด การสูญเสียความร้อนของน้ำหล่อเย็นถึง30%
              ไม่เพียงเท่านั้นเราได้ เราได้กำจัดปัญหา
              “การสูญเสียความร้อนของน้ำหล่อเย็นของเตาหลอม-อุ่นโลหะขนาดใหญ่เพื่อการขึ้นรูปอย่างอิสระ”
              ได้ด้วยการทำให้คานทับหลัง(lintel) ของเตาหลอมปราศจากน้ำ
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>ตัวอย่างการนำเอาพลังความร้อนที่เหลือทิ้งกลับมาใช้ใหม่</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/energy/ene_save05.gif" alt="" />
            </div>
            <p>หัวเตาเผาแบบ Self-recuperative burners มีการสูญเสียพลังงงานความร้อนน้อยกว่าเมื่อเทียบกับ หัวเตาเผาแบบ
              conventional central recuperator ซึ่งจะช่วยประหยัดพลังงานได้ประมาณ 15%
              หัวเตาเผาที่ได้รับการจดทะเบียนของเรานี้มีประสิทธิภาพสูงในการควบคุมอุณหภูมิในเตาหลอมด้วยรูปแบบเปลวไฟแบบกระจาย
              (distributed flame patterns)
              เมื่อนำเทคโนโลยีนี้มารวมกับเทคโนโลยีควบคุมอัตราส่วนการเผาไหม้ของอากาศ-เชื้อเพลิงของหัวเตาเผาแต่ละอัน
              จะช่วยให้ประหยัดพลังานได้อย่างแน่นอน
              <br />
              <br />
              <br />
              <br />
            </p>
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/energy/ene_save06.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save07.jpg" alt="" />
              </div>
            </div>
            <p style="clear: both;"></p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>ระบบควบคุมการเผาไหม้ (ควบคุมอัตราส่วนอากาศ-เชื้อเพลิง, ควบคุมแรงดันในเตาหลอม, การให้ความร้อน[heating
              curve] อย่างเหมาะสม เป็นต้น)</h3>
            <br />
            <h4>เทคโนโลยีควบคุมอัตราส่วนอากาศ-เชื้อเพลิง</h4>
            <br />
            <p>
              เทคโนโลยีควบคุมอัตราส่วน อากาศ-เชื้อเพลิง สำหรับหัวเตาเผาแต่ละอัน (แบบดั้งเดิม)
              ของเราเป็นระบบควบคุมซึ่งสามารถปรับอัตราการเผาไหม้ ให้เหมาะสมกับทุกสถานการณ์ โดยเฉพาะอย่างยิ่ง ระบบควบคุม
              regenerative burner ที่สามารถลดระดับออกซิเจนในเตาหลอม และช่วยลดการใช้พลังงานเครื่องเผาไหม้ (combustion air
              blower) รวมถึงลดการใช้พลังงานพัดลมดูดอากาศ (exhasut induced draft Fan)
            </p>
            <br />
            <h4>การประหยัดพลังงานด้วย การให้ความร้อน[heating curve] อย่างเหมาะสม</h4>
            <br />
            <p>
              ระบบควบคุมแบบ เปิด/ปิด
              รุ่นใหม่ของเรามีรูปแบบการทำงานที่จะช่วยลดปริมาณการเบี่ยงเบนระหว่างกราฟการให้ความร้อน(heating
              curve)กับอุณหภูมิที่ถูกกำหนด และมีการแบ่งโซนควบคุมโดยอัตโนมัติเพื่อปรับปรุงปริมาณของเสียในการเผาไหม้
            </p>
            <br />
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>