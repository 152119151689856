<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>News</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">

        <div class="sidebar">
          <div class="single-sidebar service-list">
            <ul>
              <li><a href="#">2019</a></li>
            </ul>
          </div>
        </div>

      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>News</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of news">
                <div class="col-md-4 text-box">
                  <h4>{{item.date | date: 'd/M/yy, h:mm a'}}</h4>
                </div>
                <div class="text-box angular-with-newlines">
                  <p>
                    <a href="#" [routerLink]="['/en/newsdetail']">{{item.title}}</a>
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
