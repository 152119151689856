<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>นโยบายความเป็นส่วนตัว</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>นโยบายความเป็นส่วนตัว</h2>
      <p>บริษัท ซังเค็นซังเกียว จำกัด (ต่อไปนี้จะเรียกในนาม "SANKEN") ตระหนักดีว่า
        การนำข้อมูลส่วนบุคคลที่ได้รับมาไปใช้ในกิจกรรมทางธุรกิจของบริษัท จะต้องจัดการ ดูแลรักษา
        และปกป้องข้อมูลดังกล่าวด้วยความระมัดระวังสูงสุด ดังนั้น SANKEN จะปฏิบัติตามนโยบายพื้นฐาน ดังนี้
      </p>
      <ol class="checklist">
        <li>SANKEN จะไม่รับข้อมูลส่วนบุคคลใด ๆ ด้วยวิธีการที่ไม่ถูกต้องหรือไม่เหมาะสม</li>
        <li>SANKEN จะใช้ข้อมูลส่วนบุคคลตามขอบเขตหรือวัตถุประสงค์ที่ระบุไว้เท่านั้น
          ซึ่งจะมีการประกาศหรือแจ้งให้บุคคลที่เกี่ยวข้องกับข้อมูลทราบอย่างชัดเจน</li>
        <li>SANKEN จะพยายามรักษาข้อมูลส่วนบุคคลให้ถูกต้องและเป็นปัจจุบัน</li>
        <li>SANKEN จะใช้มาตรการที่เหมาะสมเพื่อรักษาความปลอดภัยของข้อมูลส่วนบุคคล
        </li>
        <li>SANKEN จะดำเนินการจัดหาพนักงานและผู้รับเหมา เพื่อทำหน้าที่ดูแลรักษาข้อมูลส่วนบุคคล
          ภายใต้แนวทางและวิธีการที่เหมาะสม</li>
        <li>SANKEN จะไม่ให้ข้อมูลส่วนบุคคลแก่บุคคลที่สาม โดยไม่ได้รับความยินยอมจากบุคคลที่เกี่ยวข้องกับข้อมูลนั้นๆ</li>
        <li>หาก SANKEN ได้รับการสอบถามข้อมูลจากบุคคลที่มีส่วนเกี่ยวข้องกับข้อมูลหรือเนื้อหาใดๆที่เกี่ยวข้องกับบุคคลนั้นๆ
          ทางบริษัทเร่งตรวจสอบและดำเนินการตามความเหมาะสม</li>
        <li>หาก SANKEN ได้รับข้อร้องเรียนใด ๆ เกี่ยวกับการจัดการข้อมูล
          ทางบริษัทจะเร่งดำเนินการจัดการแก้ไขข้อร้องเรียนดังกล่าวอย่างรวดเร็วและเหมาะสมที่สุด</li>
        <li>SANKEN มีการกำหนดมาตราการ กฎ และวิธีการจัดการ
          เพื่อนำมาใช้ในการจัดการและปกป้องข้อมูลอย่างเหมาะสมเพื่อผลประโยชน์สูงสุด</li>
        <li>SANKEN ตระหนักถึงความสำคัญของการจัดการและปกป้องข้อมูลส่วนบุคคล โดยจะมีการทบทวนและปรับปรุงนโยบาย มาตราการ กฎ
          และวิธีการในการจัดการข้อมูลส่วนบุคคลอย่างสม่ำเสมอ</li>
        <li>SANKEN
          จะปฏิบัติตามกฎหมายและข้อบังคับของประเทศไทยที่เกี่ยวข้องกับการจัดการข้อมูลส่วนบุคคลทุกประการอย่างเคร่งครัด</li>
      </ol>
    </div>

    <div class="title">
      <h2>1. การใช้งานข้อมูลส่วนบุคคล</h2>
      <p>
        SANKEN จะรับและใช้ข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ในการดำเนินธุรกิจเท่านั้น
      </p>
    </div>

    <div class="title">
      <h2>2. การจัดการข้อมูลส่วนบุคคล</h2>
      <p>
        SANKEN มีการใช้มาตรการการป้องกันข้อมูลอย่างเหมาะสม เพื่อให้ท่านมั่นใจในความปลอดภัยของข้อมูลที่ทาง SANKEN
        ได้ครอบครองอยู่ รวมถึงพยายามรักษาข้อมูลต่างๆให้ถูกต้องและเป็นปัจจุบันที่สุด
        เพื่อวัตถุประสงค์ในการดำเนินธุรกิจเท่านั้น และจะดำเนินการกำจัดข้อมูลที่ไม่จำเป็นในทางธุรกิจทันที
        ด้วยวิธีการที่เหมาะสม
      </p>
    </div>

    <div class="title">
      <h2>3. บริษัทมีแนวทางการจัดการข้อมูลส่วนบุคคลบนเว็บไซต์อย่างไร</h2>
      <p>
        SANKEN จะไม่ เปิดเผย/ เช่า/ขาย ข้อมูลส่วนบุคคลที่อยู่บนเว็บไซต์นี้ นอกจากกรณีดังต่อไปนี้
      </p>
      <ul>
        <li>ลูกค้ายิมยอมให้เปิดเผยหรือเผยแพร่ข้อมูลดังกล่าวด้วยตนเอง</li>
        <li>หน่วยงานราชการ เช่น ศาล, กรมตำรวจ ขอให้ทางบริษัทเปิดเผยข้อมูลให้หน่วยงานตามกฎหมาย</li>
        <li>การกระทำของลูกค้าบนเว็บไซต์มีการละเมิดข้อตกลง ทางบริษัทจำเป็นจะต้องเปิดเผยข้อมูลดังกล่าวเพื่อเป็นการปกป้องลิขสิทธิ์ ทรัพย์สิน และบริการของ SANKEN</li>
      </ul>
    </div>

    <!-- <div class="title">
      <h2>4. Contact for Personal Information</h2>
      <p>
        Sanken Sangyo Co., Ltd. <br />
        3-1-2 Tomonishi, Asaminami-ku, Hiroshima, 731-3169, Japan<br />
        Telephone +61-82-849-6790<br />
        Facsimile +61-82-849-6890<br />
      </p>
    </div> -->

  </div>
</section>

<app-footer lang="th"></app-footer>