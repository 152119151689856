<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>


<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>จุดเด่นของเรา</h3>
          </div>
          <!-- <p>Introduction of our own original product supporting industry.</p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="0"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>เตาหลอมโลหะแบบการอัดความร้อน (Heating Furnace for Hot Pressing)</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_01.jpg" alt="" />
            </div>
            <p>การอัดความร้อน (Hot Pressing) เป็นเทคโนโลยีรูปแบบใหม่ที่นำมาใช้ เพื่อช่วยลดน้ำหนักของชิ้นส่วนยานยนต์
              รวมถึงช่วยทำให้ตัวถังของยานยนต์ให้มีความแข็งแรงมากขึ้น
              ด้วยวิธีการกดแผ่นเหล็กที่มีความร้อนสูงในระดับหลอมละลาย เข้ากับแผ่นเหล็กที่เย็นตัวลงแล้วพร้อมๆกัน
              เราได้ทุ่มเทในการพัฒนาเตาหลอมโลหะแบบลูกกลิ้งสายพาน (roller hearth heating furnace)
              เพื่อใช้ในการอัดความร้อน ทำให้ผลิตภัณฑ์ของเราได้รับการปรับปรุงอย่างต่อเนื่อง
              ส่งผลให้ลดการปล่อยก๊าซคาร์บอนในส่วนของการผลิตโลหะยานยนต์
              ซึ่งขณะนี้เรามีผลิตภัณฑ์เตาหลอมทั้งแบบที่ใช้ก๊าซธรรมชาติ (gas-fired)และแบบใช้ความร้อนที่เกิดจากไฟฟ้า
              (electric heating)
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอมโลหะแบบ Walking Beam “ITG-T6”</h3>
            <br />
            <div class="col-md-5 text-center">
              <img src="img/onlyone/one_02.jpg" alt="" />
            </div>
            <p>เราได้มีการพัฒนาเตาหลอมโลหะแบบ Walking Beam ที่มีลักษณะเป้นสายพานเคลื่อนที่อย่างต่อเนื่องแบบหลายขั้นตอน
              (multiple-stage continuous walking beam) ในชื่อรุ่นว่า T6 เพื่อใช้ในการผลิตล้อแมกซ์รถยนต์ (alloy motor
              wheels) โดยในแต่ละขั้นตอน (stage) เตาหลอมจะทำงานด้วยลักษณะที่ใช้คานมายก
              ชิ้นงานภายในเตาจะถูกควบคุมให้เคลื่อนที่ไปตามจังหวะการพาของคานโดยอัตโนมัติ
              คุณสมบัติพิเศษอย่างหนึ่งของเตาหลอมรูปแบบนี้คือช่วยประหยัดพลังงานได้ดี
              เมื่อนำคุณสมบัติพิเศษส่วนนี้มาผสมผสานกับระบบแก๊สความร้อนแบบหมุนเวียน (แบบดั้งเดิม) ที่ติดตั้งอยู่ในเตาหลอม
              รวมถึงมีช่องว่างระหว่างชิ้นงาน เพื่อให้สัมผัสกับความร้อนได้อย่างทั่วถึง
              จะทำให้ได้การเร่งอัตราหลอมที่รวดเร็วขึ้น และการสามารถควบคุมอุณหภูมิให้ได้เท่ากันทุกชิ้นงาน
            </p>
            <hr />
          </div>


          <div class="blog-post-style-one">
            <h3>เตาเผา แบบ Rotary / แบบ IDEX / แบบ ECO สำหรับรีไซเคิลอลูมีเนียมเพื่อนำกลับมาใช้ใหม่ (Aluminium Recycling
              Rotary Kiln / IDEX and Eco-Kiln)</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_03.jpg" alt="" />
            </div>
            <p>การปรับสภาพวัตถุ ก็สามารถทำได้โดย เตาเผาแบบหมุน/IDEX/ เตาเผาแบบอนุรักษ์สิ่งแวดล้อม
              โดยจะใช้ความร้อนเพื่อทำให้ชิ้นส่วนที่เปียกจากกระบวนการหล่อเย็น แห้งอย่างต่อเนื่องก่อนกระบวนการหลอม
              และยังถูกใช้ระหว่างขั้นตอนการทำให้แห้ง
              เพื่อดักจับและลดความเป็นพิษของไอหรือควันร้อนที่เกิดจากกระบวนการเผาไหม้จากขั้นตอนการหล่อเย็น
              ซึ่งปัจจัยเหล่านี้ช่วยลดการใช้พลังงานและทรัพยากร พร้อมทั้งยังทำให้ผลิตได้ในอัตราที่สูงขึ้นอีกด้วย
              มันยังช่วยอำนวยความสะดวกต่อการรีไซเคิลในโรงงาน (in-house recycling)
              ของชิ้นส่วนเครื่องจักรและช่วยลดต้นทุนการผลิต
              นอกจากนี้เรายังมีอีกหลายตัวอย่างในการทำให้ชิ้นเครื่องจักรให้แห้ง
              รวมถึงเครื่องจักรสำหรับคัดแยกกระป๋องน้ำดื่มที่เปิดใช้แล้วออก (Decoating UBC)
              เตาเผาแบบEco เหมาะสำหรับการอบแห้งชิปขนาดเล็กสูงสุดประมาณ 350 กิโลกรัมต่อชั่วโมง
              IDEX เหมาะสำหรับการอบแห้งชิปขนาดใหญ่และการทำ UBC
              เราให้บริการเครื่องจักรเพื่อรองรับทั้งสายการปรับสภาพวัสดุ เช่น เครื่องอบแห้ง เครื่องกำจัดขยะ เครื่องบด
              เครื่องคัดแยก เป็นต้น
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-อุ่นโลหะพลังงานไฟฟ้า “S-MIC”</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_04.jpg" alt="" />
            </div>
            <p>S-MIC เป็นเตาหลอม/อุ่นโลหะด้วยวิธีการไฟฟ้า ที่มีการติดตั้งฮีตเตอร์จุ่ม (immersion heaters) แบบพิเศษ
              ซึ่งมีประสิทธิภาพและพลังงานสูง ลักษณะการออกแบบนี้จะช่วยลดการเกิดปฏิกิริยาอ๊อกซิเดชั่น (Oxidation)
              ของโลหะหลอมเหลวได้อย่างมากโดยไม่มีการปล่อยคาร์บอน
              นอกจากนี้ยังเป็นเตาประหยัดพลังงานที่สามารถผลิตโลหะหลอมเหลวคุณภาพสูงในราคาประหยัดอีกด้วย
            </p>
            <br />
            <br />
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-อุ่นร้อนสำหรับก้อนโลหะ/เหล็กกล้าขนาดใหญ่เพื่อการตีเหล็กแบบอิสระ (free forging)</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_05.jpg" alt="" />
            </div>
            <p>เตาหลอมโลหะที่ใหญ่ที่สุดในโลกที่ให้ความร้อนกับแท่งเหล็กขนาดใหญ่พิเศษ 650 ตัน
              เตาหลอมแบบแบตช์เหล่านี้ติดตั้งระบบหัวเผาแบบหลายพอร์ตที่ได้รับการจดสิทธิบัตรของเรา
              และเทคโนโลยีการควบคุมการเผาไหม้ที่ทันสมัย มีประสิทธิภาพในการทำความร้อนและประหยัดพลังงานสูง
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-หล่อเย็นด้วยไฟฟ้าแบบแท่งแนวตั้ง “VSEF”</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_06.jpg" alt="" />
            </div>
            <p>VSEF ถูกใช้รักษาความร้อนสำหรับชิ้นส่วนยาว เช่น เพลาใบพัดกังหัน และเพลาขับเคลื่อนสำหรับเรือขนาดใหญ่
              โครงสร้างเตาแบบสองชิ้นช่วยให้สามารถจัดการชิ้นงานในแนวนอนได้โดยไม่จำเป็นต้องมีหลุมลึกและเครนเหนือศีรษะแบบยกระดับ
            </p>
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-หล่อเย็นแบบต่อเนื่อง สำหรับงานท่อเหล็ก</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_08.jpg" alt="" />
            </div>
            <p>“เตาหลอม-หล่อเย็นแบบสายพาน สำหรับงานท่อเหล็ก” ช่วยเน้นคุณภาพของกรรมวิธีทางความร้อนต่างๆให้ชิ้นงานของคุณ
              ระบบการลำเลียงวัสดุของเตาชนิดนี้จะไม่ทำให้เกิดริ้วรอยบนชิ้นงานระหว่างการเคลื่อนที่ภายในเตา
              ระบบพัดลมยังช่วยหมุนเวียนความร้อนและควบคุมอุณหภูมิได้อย่างดี นอกจากนั้นมันยังถูกออกแบบให้ประหยัดพลังงาน
              และมีการติดตั้งเครื่องแลกเปลี่ยนความร้อน (recuperator)
              และระบบควบคุมสัดส่วนของอากาศ-เชื้อเพลิงเข้ามาอีกด้วย
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>