import { Component, OnInit, Input } from '@angular/core';
import { MenuInfo } from 'src/app/model/Menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() lang: string;
  @Input() menuType: string;
  @Input() menuIndex: string;

  sidebar: MenuInfo[];

  constructor() { }

  ngOnInit() {

    this.sidebar = [];

    this.loadMenu()

    this.setActiveMenu()

  }

  loadMenu() {
    if (this.menuType == 'INTRO') {
      this.getMenuIntroduction()
    } else if (this.menuType == 'COMP') {
      this.getMenuCompany()
    }
  }

  getMenuIntroduction() {
    if (this.lang == 'en') {
      this.sidebar = [
        { name: 'Our Only-One', link: '/' + this.lang + '/onlyone', active: false },
        { name: 'Products (Aluminium Areas)', link: '/' + this.lang + '/aluminium', active: false },
        { name: 'Products (Steel Areas)', link: '/' + this.lang + '/steel', active: false },
        { name: 'Research & Development', link: '/' + this.lang + '/rd', active: false },
        { name: 'Overseas References', link: '/' + this.lang + '/oversea', active: false },
        { name: 'Energy Saving', link: '/' + this.lang + '/energy', active: false },
        { name: 'Proposal of Renovation', link: '/' + this.lang + '/proposal', active: false },
        { name: 'Maintenance', link: '/' + this.lang + '/maintenance', active: false }
      ]
    } else {
      this.sidebar = [
        { name: 'จุดเด่นของเรา', link: '/' + this.lang + '/onlyone', active: false },
        { name: 'ผลิตภัณฑ์(กลุ่มอลูมิเนียม)', link: '/' + this.lang + '/aluminium', active: false },
        { name: 'ผลิตภัณฑ์(กลุ่มเหล็ก)', link: '/' + this.lang + '/steel', active: false },
        { name: 'การพัฒนาและค้นคว้าวิจัย', link: '/' + this.lang + '/rd', active: false },
        { name: 'ลูกค้าและผลงาน', link: '/' + this.lang + '/oversea', active: false },
        { name: 'การประหยัดพลังงาน', link: '/' + this.lang + '/energy', active: false },
        { name: 'นวัตกรรมและเทคโนโลยี', link: '/' + this.lang + '/proposal', active: false },
        { name: 'การซ่อมบำรุง', link: '/' + this.lang + '/maintenance', active: false }
      ]
    }
  }

  getMenuCompany() {
    if (this.lang == 'en') {
      this.sidebar = [
        { name: "What's sanken", link: '/' + this.lang + '/whatsanken', active: false },
        { name: 'Business Philosophy', link: '/' + this.lang + '/business', active: false },
        { name: 'Top Message', link: '/' + this.lang + '/topmsg', active: false },
        { name: 'Outline of The Company', link: '/' + this.lang + '/outline', active: false },
        { name: 'History', link: '/' + this.lang + '/history', active: false },
        { name: 'Business Locations', link: '/' + this.lang + '/bizlocate', active: false },
        { name: 'Group Company / Partner', link: '/' + this.lang + '/partner', active: false },
        { name: 'Environmental Activities', link: '/' + this.lang + '/environment', active: false }
      ]
    } else {
      this.sidebar = [
        { name: "ธุรกิจหลักขององค์กร", link: '/' + this.lang + '/whatsanken', active: false },
        { name: 'แนวคิดการดำเนินธุรกิจ', link: '/' + this.lang + '/business', active: false },
        { name: 'สาส์นจากผู้บริหาร', link: '/' + this.lang + '/topmsg', active: false },
        { name: 'ข้อมูลบริษัท', link: '/' + this.lang + '/outline', active: false },
        { name: 'ประวัติความเป็นมา', link: '/' + this.lang + '/history', active: false },
        { name: 'ที่ตั้ง', link: '/' + this.lang + '/bizlocate', active: false },
        { name: 'บริษัทในเครือ/หุ้นส่วนทางการค้า', link: '/' + this.lang + '/partner', active: false },
        { name: 'กิจกรรมเพื่อสิ่งแวดล้อม', link: '/' + this.lang + '/environment', active: false }
      ]
    }
  }

  setActiveMenu() {
    let num = 0;

    for (let item of this.sidebar) {
      if (num === (+this.menuIndex)) {
        item.active = true;
        break;
      }
      num++;
    }
  }

}
