import { Component, OnInit } from '@angular/core';
import { Research } from 'src/app/model/Research';

@Component({
  selector: 'app-oversea',
  templateUrl: './oversea.component.html',
  styleUrls: ['./oversea.component.css']
})
export class OverseaComponent implements OnInit {

  asia: Research[];
  northAmerica: Research[];
  europe: Research[];

  constructor() { }

  ngOnInit() {

    this.asia = [{
      country: "China",
      description: "Tower type aluminium melting furnace, Aluminium chip melting furnace, Combination aluminium melting furnace, Aluminium dosing furnace, Aluminuim Chip processing line (with IDEX) before melting, Roller hearth T6 furnace, Walking beam (basketless) T6 furnace, Aluminium billet heating furnace, heating furnace for hot pressing, Large steel structure batch heating furnace, Ladle preheater, Coiling furnace for stainless steel mill, Steel wire zinc galvanizing line, DX gas generator."
    }, {
      country: "Korea",
      description: "Tower type aluminium melting furnace, Aluminium chip processing line before melting, Roller hearth T6 furnace, Batch T6 furnace, Rotary hearth solution furnace, Pusher billet reheating furnace, Large batch reheating furnace for free forging, Car bottom heat treatment furnace, Tundish preheater, Steel pipe zinc galvanizing furnace."
    }, {
      country: "Taiwan",
      description: "Tower type aluminium melting furnace, Aluminium chip processing line before melting, Combination aluminium melting furnace, T6 heat treatment furnace, Pusher billet reheating furnace, Tundish preheater, Car bottom furnace, Continuous wire annealing / zinc galvanizing furnaces, Steel pipe zinc galvanizing furnace, Rotary hearth solution furnace for stainless steel."
    }, {
      country: "Indonesia",
      description: "Tower type aluminium melting furnace, Aluminium chip melting furnace, Combination aluminium melting furnace (HIRARO), Aluminium dosing furnace, Aluminium chip processing line (with IDEX) before melting, Roller hearth T2, T5 & T6 furnaces, Walking beam (basketless) Aluminium wheel T6 furnace, Quenching furnace for construction machinery rollers, Air heater."
    }, {
      country: "Thailand",
      description: "Tower type aluminium melting furnace, Aluminium chip melting furnace, Combination aluminium melting furnace, Aluminium chip processing line before melting, Aluminium continuous heat treatment furnace, Continuous tempering furnace for automotive component."
    }, {
      country: "Malaysia",
      description: "Tower type aluminium melting furnace, Aluminium continuous heat treatment furnace, Pusher billet reheating furnace."
    }, {
      country: "Vietnam",
      description: "Tower type aluminium melting furnace, Pusher billet reheating furnace."
    }, {
      country: "Philippines",
      description: "Tower type aluminium melting furnace, Pusher billet reheating furnace, Roller hearth heat treatment furnace for forged automotive component, Zinc galvanizing furnace."
    }, {
      country: "Myanmar",
      description: "Pusher billet reheating furnace, Ladle preheater."
    }, {
      country: "Mongolia",
      description: "Pusher billet reheating furnace."
    }, {
      country: "India",
      description: "Tower type aluminium melting furnace, Aluminium dosing furnace, Car bottom heat treatment furnace."
    }, {
      country: "Australia",
      description: "Aluminium chip processing line & melting furnace."
    }];

    this.northAmerica = [{
      country: "United States",
      description: "Continuous aluminium billet heating furnace, Roller hearth T5 furnace, Tower type aluminium melting furnace, Large vertical split heat treatment electric furnace."
    }, {
      country: "Mexico",
      description: "Tower type aluminium melting furnace, Aluminium dosing furnace, Car bottom reheating furnace, Car bottom heat treatment furnace, Rapid differential heating furnace for mill rolls, Slow cooling furnace, Vertical heat treatment gas-fired furnace, Electric furnace for measurement of thermal strain."
    }, {
      country: "Brazil",
      description: "Pusher billet reheating furnace."
    }];

    this.europe = [{
      country: "England",
      description: "Tower type aluminium melting furnace, T6 heat treatment furnace."
    }, {
      country: "Spain",
      description: "Aluminium melting furnace."
    }, {
      country: "Bulgaria",
      description: "Car bottom reheating furnace for free forging, Car bottom annealing furnace, Car bottom heat treatment furnace, Roll rapid differential heating furnace, Vertical heat treatment gas-fired furnace, Ladle preheater, Drying oven."
    }, {
      country: "Turkey",
      description: "	Pusher billet reheating furnace, Roller hearth heat treatment furnace."
    }, {
      country: "Iran",
      description: "Copper alloy intermediate annealing furnace."
    }, {
      country: "Algeria",
      description: "Car bottom reheating furnace for free forging, Batch reheating furnace, Car bottom annealing furnace, Car bottom preheating furnace, Drying oven."
    }, {
      country: "Egypt",
      description: "Reheating furnace for forging, Drying oven."
    }, {
      country: "Nigeria",
      description: "Pusher billet reheating furnace, Reheating furnace for forging."
    }];

  }

}
