import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onlyone',
  templateUrl: './onlyone.component.html',
  styleUrls: ['./onlyone.component.css']
})
export class OnlyoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
