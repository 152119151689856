<div class="header-top-home-two">
  <div class="thm-container clearfix">
    <div class="logo pull-left">
      <a [routerLink]="['/']">
        <img src="img/sanken_logo.web.png" class="header-logo" alt="Awesome Image" />
      </a>
    </div><!-- /.logo pull-left -->
    <div class="header-top-contact pull-right">
      <div class="single-header-top-content">
        <div class="icon-box">
          <i class="icon icon-Phone2"></i>
        </div>
        <div *ngIf="toggle" class="text-box">
          <h4> Telephone: (02) 130 6236 </h4>
          <p> Fax : (02) 130 6238 </p>
        </div>
        <div *ngIf="!toggle" class="text-box">
          <h4> โทรศัพท์: (02) 130 6236 </h4>
          <p> แฟกซ์ : (02) 130 6238 </p>
        </div>
      </div><!-- /.single-header-top-content -->
      <div class="single-header-top-content">
        <div class="icon-box">
          <i class="icon icon-Timer"></i>
        </div><!-- /.icon-box -->
        <div *ngIf="toggle" class="text-box">
          <h4>Mon - Fri 8.30 - 17.00</h4>
          <p>Satuesday & Sunday Closed</p>
        </div>
        <div *ngIf="!toggle" class="text-box">
          <h4>จันทร์ - ศุกร์ 8.30 - 17.00</h4>
          <p>หยุด วันเสาร์ และ วันอาทิตย์</p>
        </div>
      </div><!-- /.single-header-top-content -->
    </div><!-- /.header-top-contact -->
  </div><!-- /.thm-container -->
</div><!-- /.header-top -->
