import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.css']
})
export class EnvironmentComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: 'Eco Action 21 Certification No.', description: '0000723' },
      { name: 'Registrant', description: 'Sanken Sangyo Co., Ltd.' },
      { name: 'Subject of office and factory', description: 'Head Office, Tokyo Office, Osaka Office, Chubu Office, Hokkaido Office, Hokuriku Office and Factory.' },
      { name: 'Date of Certification', description: 'March 31, 2006' },
      { name: 'Last updated date', description: 'March 31, 2012' },
      { name: 'Certification Organization', description: 'Institute for Promoting Sustainable Societies' },
    ]
  }

}
