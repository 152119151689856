<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Environmental Activities</h3>
          </div>
          <p>
            Introduction of our environmental activities.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="COMP" menuIndex="7"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Environmental Activities</h3>
            <br />

            <h4>Eco Action 21 Certification</h4>
            <br />
            <div class="col-md-4 text-left">
              <img src="img/environment/ea21.gif" alt="" />
            </div>
            <p>In order to aim conservation of the global environment in our business activities, we have decided to
              tackle "Eco Action 21" one of the environmental management systems.

              "Eco Action 21" is an environmental management system based on the Eco-Action 21 guidelines Ministry of
              the Environment has developed. It started operations in March 2006 in the head office first, and then we
              have obtained/renewed its certification across our company, including factory and all branch offices in
              March, 2012.

              Taking this certification as a new starting point, we will make a effort to contribute to the harmonious
              development of human society and conservation of the global environment in the future, too.
              <br />
              <br />
            </p>
            <a href="https://www.sanken-sangyo.co.jp/en/company/ea21/images/report2011.pdf" target="_blank">2011 Edition Environmental Activity (312KB/PDF)</a>
            <hr />
          </div>

          <div class="blog-post-style-one">
           
            <div class="comments-area">
              <div class="single-comment" *ngFor="let item of outline">
                <div class="col-md-5 text-box">
                  <h4>{{item.name}} : </h4>
                </div>
                <div class="text-box">
                  <p>{{item.description}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
