<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ข้อตกลงในการใช้งาน</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>การดำเนินงานของเว็บไซต์</h2>
      <p>คำอธิบายนี้ใช้กับข้อมูลบนเว็บไซต์ (ต่อไปนี้จะเรียกในนาม "เว็บไซต์") ภายใต้การดำเนินการโดย Sanken Sangyo Co. ,
        Ltd. หรือตัวแทนของบริษัท (ต่อไปนี้จะเรียกในนาม "SANKEN")</p>
    </div>

    <div class="title">
      <h2>ลิขสิทธิ์และสิทธิบัตรทางการค้า</h2>
      <p>ลิขสิทธิ์ของข้อมูลบนเว็บไซต์นั้น SANKEN และบริษัทในเครือ SANKEN เป็นผู้ครอบครอง
        และได้รับการคุ้มครองภายใต้กฎหมายลิขสิทธิ์และข้อกำหนดที่เกี่ยวข้องกับลิขสิทธิ์อย่างถูกต้อง
        ห้ามมิให้นำข้อมูลบนเว็บไซต์ไปใช้เพื่อการส่วนตัว หรือเพื่อประโยชน์อื่น (หรือคัดลอก, ดัดแปลง หรือแจกจ่าย)
        โดยไม่ได้รับการการอนุมัติจาก SANKEN
      </p>
      <p>
        เครื่องหมายการค้า สัญลักษณ์การค้า และเครื่องหมายแสดงบริการ ที่ใช้บนเว็บไซต์ ถือเป็นทรัพย์สินของ SANKEN
        และบริษัทในเครือของ SANKEN
        ผู้อื่นนอกเหนือจากที่กล่าวไว้ข้างต้นไม่มีสิทธิ์ใช้งานเครื่องหมายและสัญลักษณ์ดังกล่าวในทุกกรณี
        และไม่อนุญาตให้ส่งต่อให้ลูกค้า นอกจากนี้ ห้ามมิให้ใช้โอนและทำซ้ำเครื่องหมายการค้า สัญลักษณ์การค้า
        และเครื่องหมายแสดงบริการดังกล่าวโดยไม่ได้รับการอนุมัติจาก SANKEN
      </p>
    </div>

    <div class="title">
      <h2>คุณลักษณะต้องห้าม</h2>
      <p>
        การเข้าถึงข้อมูลบนเว็บไซต์ ห้ามการกระทำการดังต่อไปนี้
      </p>
      <ul class="checklist">
        <li>การนำข้อมูลบนเว็บไซต์มาใช้งาน เพื่อละเมิดหรือคุกคามความเป็นส่วนตัวของ SANKEN</li>
        <li>การนำข้อมูลบนเว็บไซต์ไปให้บุคคลอื่น ซึ่งก่อให้เกิดความเสียหายต่อบริษัทและบุคคลที่สาม</li>
        <li>การนำข้อมูลบนเว็บไซต์ไปใช้งาน เพื่อก่อให้เกิดความแตกแยกและศีลธรรมอันดีของประชาชน</li>
        <li>การนำข้อมูลบนเว็บไซต์ไปใช้งาน เพื่อก่ออาชญากรรม หรือการกระทำใด ๆ ที่นำไปสู่อาชญากรรม</li>
        <li>การนำข้อมูลเท็จมาใช้งาน เช่น การลงทะเบียนรับข้อมูลข่าวสารด้วย Email ของบุคคลอื่น
          โดยไม่ได้รับอนุญาตจากผู้เป้นเจ้าของข้อมูล</li>
        <li>การนำข้อมูลบนเว็บไซต์มาจัดทำหรือก่อให้เกิดผลประโยชน์ในเชิงธุรกิจส่วนตน และกระทบกับการดำเนินธุรกิจของ SANKEN
        </li>
        <li>การนำข้อมูลบนเว็บไซต์มาอ้างอิง ทำให้กระทบต่อเกียรติและชื่อเสียงของ SANKEN หรือบุคคลที่สามเสียหาย
          หรือส่งผลให้ได้รับผลกระทบในทางธุรกิจ</li>
        <li>การนำข้อมูลบนเว็บไซต์มาใช้งาน หรือส่งต่อ เพื่อก่อให้เกิดการคุกคามในเชิงที่เป็นอันตรายต่อโปรแกรมอื่น
          รวมถึงไวรัสคอมพิวเตอร์</li>
        <li>การนำข้อมูลบนเว็บไซต์มาใช้งาน โดยมีการละเมิดกฎหมายและข้อบังคับ
          หรือนำมาข่มขู่คุกคามว่าจะนำไปใช้เพื่อละเมิดกฎหมายและข้อบังคับใด ๆ</li>
        <li>การกระทำอื่น ๆ นอกเหนือจากข้างต้น ที่ SANKEN เห็นว่าไม่เหมาะสม</li>
      </ul>
    </div>

    <div class="title">
      <h2>บริษัทมีแนวทางการจัดการข้อมูลส่วนบุคคลบนเว็บไซต์อย่างไร</h2>
      <p>
        SANKEN จะไม่ เปิดเผย/ เช่า/ขาย ข้อมูลส่วนบุคคลที่อยู่บนเว็บไซต์นี้ นอกจากกรณีดังต่อไปนี้
      </p>
      <ul class="checklist">
        <li>ลูกค้ายิมยอมให้เปิดเผยหรือเผยแพร่ข้อมูลดังกล่าวด้วยตนเอง</li>
        <li>หน่วยงานราชการ เช่น ศาล, กรมตำรวจ ขอให้ทางบริษัทเปิดเผยข้อมูลให้หน่วยงานตามกฎหมาย</li>
        <li>การกระทำของลูกค้าบนเว็บไซต์มีการละเมิดข้อตกลง
          ทางบริษัทจำเป็นจะต้องเปิดเผยข้อมูลดังกล่าวเพื่อเป็นการปกป้องลิขสิทธิ์ ทรัพย์สิน และบริการของ SANKEN</li>
      </ul>
    </div>

    <div class="title">
      <h2>การเชื่อมโยงข้อมูลบนเว็บไซต์</h2>
      <p>
        การเชื่อมโยงจากเว็บไซต์ SANKEN ไปยังเว็บไซต์อื่นที่เป็นของบุคคลที่สาม
        หรือเว็บไซต์ของบุคคลที่สามเชื่อมโยงมายังเว็บไซต์ SANKEN นั้น (ต่อไปนี้ขอเรียกว่า "ลิงค์เว็บไซต์")
        อยู่นอกเหนือความรับผิดชอบของ SANKEN และ SANKEN
        ไม่มีส่วนรับผิดชอบต่อความเสียหายที่เกิดขึ้นบนเว็บไซต์ของบุคคลที่สาม
        อันเนื่องมาจากการเข้าถึงหรือการใช้เว็บไซต์ลิงค์ดังกล่าว
      </p>
      <ul class="checklist">
        <li>• SANKEN ไม่มีส่วนรับผิดชอบต่อความเสียหายใด ๆ ที่เกิดขึ้นจากการเชื่อมโยงกับเว็บไซต์
        </li>
        <li>• ข้อมูลหรือ URL บนเว็บไซต์อาจมีการเปลี่ยนแปลงโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
        <li>• ห้ามเชื่อมโยงเว็บไซต์ SANKEN กับเว็บไซต์อื่น ที่อาจนำไปสู่การละเมิดสิทธิ์ในทรัพย์สิน หรือสิทธิ์อื่นใดที่
          SANKEN ครอบครอง หรือแทรกแซงกิจกรรมทางธุรกิจของ SANKEN
        </li>
        <li>ห้ามลิงค์จากเว็บไซต์ใด ๆ หรืออีเมลที่มีข้อมูลต่อไปนี้เชื่อมมายังเว็บไซต์ SANKEN
          <ul class="checklist">
            <li>ข้อมูลที่ผิดต่อสาธารณะและศีลธรรม</li>
            <li>ข้อมูลที่ผิดต่อสาธารณะและศีลธรรม</li>
            <li>เนื้อหาลามกอนาจาร</li>
            <li>ข้อมูลเกี่ยวกับการละเมิดสิทธิ์หรือข้อมูลที่ทำให้เกิดความเสียหายต่อ SANKEN หรือ บริษัท ในเครือ</li>
          </ul>
        </li>
        <li>SANKEN ไม่ได้มีความสัมพันธ์ทางสัญญากับฝ่ายที่เชื่อมโยงในทุกกรณี</li>
      </ul>
    </div>

    <div class="title">
      <h2>คำแนะนำในการเข้าใช้งานเว็บไซต์</h2>
      <p>
        การเข้าใช้งานเว็บไซต์ด้วยอุปกรณ์ที่ไม่ตรงตามข้อกำหนด
        อาจทำให้ท่านไม่สามารถเข้าใช้งานได้หรือระบบอาจจะแสดงข้อมูลไม่ถูกต้อง
        ทางบริษัทขอแนะนำให้ท่านปฏิบัติตามข้อกำหนดของระบบที่แสดงด้านล่างเพื่อผลลัพธ์ที่ดีที่สุดเมื่อเข้าใช้งานเว็บไซต์
      </p>
    </div>
    <div class="title">
      <h2>อื่นๆ</h2>
      <p>
        เงื่อนไขการใช้งานนี้อาจมีการเปลี่ยนแปลงโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
      </p>
    </div>

    <div class="title">
      <h2>Windows</h2>
      <ul class="checklist">
        <li>Internet Explorer 6 or higher</li>
        <li>Mozilla Firefox 3 or higher</li>
        <li>Macintosh Safari 3 or higher</li>
      </ul>
    </div>

    <div class="title">
      <h2>Download the latest browser is here</h2>
      <ul class="checklist">
        <li><a href="http://www.microsoft.com/japan/windows/ie/default.mspx" title="A new window will open"
            target="_blank" class="styleArrow">Download the latest browser Microsoft Internet Explorer</a></li>
        <li>
          <a href="http://mozilla.jp/firefox/" title="A new window will open" target="_blank"
            class="styleArrow">Download the latest browser Mozilla Firefox</a>
        </li>
        <li>
          <a href="http://www.apple.com/jp/safari/" title="A new window will open" target="_blank"
            class="styleArrow">Download the latest browser Safari</a>
        </li>
      </ul>
    </div>

  </div>
</section>

<app-footer lang="th"></app-footer>