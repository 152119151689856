<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Top Message</h3>
          </div>
          <p>
            Introduction of top message.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="COMP" menuIndex="2"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>Top Message</h3>
            <br />
            <p>
              Since its foundation in 1949, we Sanken Sangyo have been challenging every problem concerning "heat and
              energy" and accomplished them one by one with efforts of our employees being guided by customers.
			  <br><br>
              It is also a history of our employees' wisdom and courage challenging the powerful tide of insatiable
              times as being supported and raised by heart and trust of all of people involved in Sanken Sangyo.
			  <br><br>
              Now, it is a problem for all mankind to prevent the global worming, which is also a serious mission to
              solve for industrial furnace manufacturers.
			  <br><br>
              We Sanken Sangyo make efforts all together in keeping with the perpetuated spirit of founding company and
              a sense of mission so that we will be a very proud company for the future generations.
			  <br><br>
              Your warm advice and guidance would be greatly appreciated.
            </p>
            <br />
            <div class="text-center">
              <img src="img/topmsg/vision01.jpg" alt="" />
              <div class="col-md-6">
                Takashi MANDAI / Chairman
              </div>
              <div class="col-md-6">
                Kosei DAIDA / President
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
