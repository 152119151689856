<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>News</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="blog-page blog-with-sidebar sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12">
        <div class="has-right-sidebar">
          <div class="blog-post-style-one">
            <div class="date">01 Apr. 2019</div>
            <a href="#">
              <h3>Released to the home page Sanken Industrial Furnace (Thailand) Co., Ltd.</h3>
            </a>
            <p class="meta-info">
              By: <a href="#">Admin</a><span class="sep">/</span><a href="#">business, sales</a><span
                class="sep">/</span>
            </p>
            <p>Released to the home page... <br/> Sanken Industrial Furnace (Thailand) Co., Ltd.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
