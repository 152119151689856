import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-outline',
  templateUrl: './outline.component.html',
  styleUrls: ['./outline.component.css']
})
export class OutlineComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: 'Name', description: 'Sanken Sangyo Co., Ltd.' },
      { name: 'Established', description: 'August 17,1949' },
      { name: 'Capital', description: '95,000,000' },
      {
        name: 'Board of Directors',
        description: ' Mr. Takashi MANDAI Chairman of the Board \n Mr. Kosei DAIDA President \n Mr. Keizo ARISUMI Executive Director \n Mr. Koji AKITA Director \n Mr. Yoshihiro KATSURA Director \n Mr. Kazushi NISHIHORI Supervisory Board'
      },
      { name: 'Number of Employees', description: 'Sanken Sangyo Total 150 Sanken group-wide Total 450' },
      { name: 'Main Business Line', description: 'Engineering & design, manufacture, sales, construction and maintenance of industrial furnaces.' },
      { name: 'Construction permit number', description: 'No.34943' },
      { name: 'Our Bank', description: 'Bank of Tokyo-Mitsubishi UFJ Co.,Ltd. \nHiroshima Bank Co.,Ltd. \nThe Shoko Chukin Bank Co.,Ltd.' },
    ]
  }

}
