<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<app-carousel lang="th"></app-carousel>

<app-article lang="th"></app-article>

<app-introduction lang="th"></app-introduction>

<app-footer lang="th"></app-footer>
