import { Component, OnInit, Input } from '@angular/core';
import { MenuInfo } from 'src/app/model/Menu';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  @Input() lang: string;
  toggle: boolean;

  constructor() { }

  ngOnInit() {

    console.log(this.lang)
    this.toggle = (this.lang == 'en')

  }

}
