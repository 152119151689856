import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-th-energy',
  templateUrl: './energy.th.component.html',
  styleUrls: ['./energy.th.component.css']
})
export class EnergyThComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
