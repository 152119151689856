import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aluminium',
  templateUrl: './aluminium.component.html',
  styleUrls: ['./aluminium.component.css']
})
export class AluminiumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
