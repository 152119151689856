import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-th-newsdetail',
  templateUrl: './newsdetail.th.component.html',
  styleUrls: ['./newsdetail.th.component.css']
})
export class NewsdetailThComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
