import { Component, OnInit } from '@angular/core';
import { GroupCompany } from 'src/app/model/GroupCompany';

@Component({
  selector: 'app-th-partner',
  templateUrl: './partner.th.component.html',
  styleUrls: ['./partner.th.component.css']
})
export class PartnerThComponent implements OnInit {

  groupCompany: GroupCompany[];
  partner: GroupCompany[];

  constructor() { }

  ngOnInit() {

    this.groupCompany = [
      {
        image: 'img/partner/group02.gif',
        companyName: 'Sanken Access Co., Ltd. (ญี่ปุ่น)',
        address: '1-1-72 Higashisendamachi, Naka-ku, Hiroshima 730-0053, Japan',
        phone: 'โทรศัพท์ : +81-82-249-1139',
        facsimile: 'แฟกซ์ : +81-82-248-2809',
        description: 'Sales and construction(equipment of dust collection, deodorization and incineration). Engineering & design, construction(equipment of organic waste treatment & recycling) ',
        url: 'http://www.sanken-access.co.jp/'
      }, {
        image: 'img/partner/group03.gif',
        companyName: 'Sanken Tech Co., Ltd. (ญี่ปุ่น)',
        address: '3-1-2 Tomonishi Asaminami-ku, Hiroshima 731-3169, Japan',
        phone: 'โทรศัพท์: +81-82-849-6390',
        facsimile: 'แฟกซ์: +81-82-849-6391',
        description: 'Engineering & design, manufacture, and construction (industrial furnaces. transport equipment, pressure vessel, plate working)',
        url: ''
      }, {
        image: 'img/partner/group04.gif',
        companyName: 'Shenyang NEU-Sanken Industrial Furnace Mfg. Co., Ltd. (จีน)',
        address: 'NO.12 Xuelian Street, Sujiatun District , Shenyang, China',
        phone: 'โทรศัพท์: +86-24-8915-6411',
        facsimile: 'แฟกซ์: +86-24-8915-3177',
        description: 'Engineering & design, manufacture, sales, construction and maintenance of industrial furnaces.',
        url: 'http://www.neu-sanken.com/'
      }, {
        image: 'img/partner/group05.gif',
        companyName: 'Sanken Insertec Europe S.A. (สเปน)',
        address: 'Avda. Cervantes, 6-Apartado 109 48970 BASAURI,Vizcaya,Spain',
        phone: 'โทรศัพท์: +34-944-409-420',
        facsimile: 'แฟกซ์: +34-944-496-624',
        description: 'Engineering & design, manufacture, sales, construction and maintenance of aluminium melting furnaces and heat treatment furnaces.',
        url: 'http://www.sanken-insertec.com/'
      }, {
        image: '',
        companyName: 'PT. SWIF ASIA  (อินโดนีเซีย)',
        address: 'Greenland International Industrial Center Blok CC No.10 - Kota Deltamas Cikarang Pusat - Bekasi 17530 - Indonesia',
        phone: 'โทรศัพท์: +62-21-300-32-929',
        facsimile: 'แฟกซ์: +62-21-300-32-930',
        description: '',
        url: 'http://www.sankenwin.com/'
      }, {
        image: 'img/partner/group11.jpg',
        companyName: 'บริษัท ซังเค็น อินดัสเทรียล เฟอร์เนส (ประเทศไทย) จำกัด (ไทย)',
        address: 'เลขที่ 88/31 นิคมอุตสาหกรรมเอเชีย หมู่ที่4  ตำบลคลองสวน อำเภอบางบ่อ จังหวัดสมุทรปราการ 10560 ประเทศไทย',
        phone: 'โทรศัพท์: +66-2-130-6236',
        facsimile: 'แฟกซ์: +66-2-130-6238',
        description: '',
        url: 'http://www.sanken.co.th/'
      }];

    this.partner = [
      {
        image: 'img/partner/group06.gif',
        companyName: 'INSERTEC (สเปน)',
        address: 'Avda.Cervantes,6-Apartado 109 48970 BASAURI, Vizcaya, Spain',
        phone: 'โทรศัพท์: +34-944-409-420',
        facsimile: 'แฟกซ์: +34-944-496-624',
        description: 'Aluminium melting furnaces and aluminium heat treatment furnaces.',
        url: 'http://www.insertec.biz/'
      }, {
        image: 'img/partner/group08.gif',
        companyName: 'SAC (เกาหลีใต้)',
        address: '1036, Gulmae-ri, Inju-myun, Asan City, Chungcheongnam-do, Korea',
        phone: 'โทรศัพท์: +82-41-582-6301',
        facsimile: 'แฟกซ์: +82-41-582-6310',
        description: 'Large reheating furnace (licencing)',
        url: 'http://www.sacfurnace.com/'
      }, {
        image: 'img/partner/group09.gif',
        companyName: 'HIGHTEMP FURNACES LTD.(อินโดนีเซีย)',
        address: 'JI.Jembatan Tiga Raya No.6A Jakarta 14440-Indonesia',
        phone: 'โทรศัพท์: +62-21-666 000 50',
        facsimile: 'แฟกซ์: +62-21-661 6789',
        description: 'Heat Treatment Furnaces',
        url: 'http://www.hightemp-furnaces.com/'
      }, {
        image: 'img/partner/group10.jpg',
        companyName: 'SUN FURNACE CO.,LTD. (ญี่ปุ่น)',
        address: '14-10 Toyotsucho, Suita-shi, Osaka, 564-0051, Japan',
        phone: 'โทรศัพท์: +81-6-6338-3900',
        facsimile: 'แฟกซ์: +81-6-6338-3955',
        description: 'Engineering & design, manufacture of industrial furnaces',
        url: ''
      }
    ];
  }

}
