import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() lang: string;
  toggle: boolean;

  constructor() { }

  ngOnInit() {
    this.toggle = (this.lang == 'en')
  }

}
