import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavComponent } from './component/nav/nav.component';
import { CarouselComponent } from './component/carousel/carousel.component';
import { IntroductionComponent } from './component/introduction/introduction.component';
import { ArticleComponent } from './component/article/article.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';

import { HomeComponent } from './en/page/home/home.component';
import { OnlyoneComponent } from './en/page/onlyone/onlyone.component';
import { AluminiumComponent } from './en/page/aluminium/aluminium.component';
import { SteelComponent } from './en/page/steel/steel.component';
import { RdComponent } from './en/page/rd/rd.component';
import { OverseaComponent } from './en/page/oversea/oversea.component';
import { EnergyComponent } from './en/page/energy/energy.component';
import { ProposalComponent } from './en/page/proposal/proposal.component';
import { MaintenanceComponent } from './en/page/maintenance/maintenance.component';
import { WhatsankenComponent } from './en/page/whatsanken/whatsanken.component';
import { BusinessComponent } from './en/page/business/business.component';
import { TopmsgComponent } from './en/page/topmsg/topmsg.component';
import { OutlineComponent } from './en/page/outline/outline.component';
import { HistoryComponent } from './en/page/history/history.component';
import { BizlocateComponent } from './en/page/bizlocate/bizlocate.component';
import { PartnerComponent } from './en/page/partner/partner.component';
import { EnvironmentComponent } from './en/page/environment/environment.component';
import { IntrobizComponent } from './en/page/introbiz/introbiz.component';
import { CompinfoComponent } from './en/page/compinfo/compinfo.component';
import { TermofuseComponent } from './en/page/termofuse/termofuse.component';
import { PrivacyComponent } from './en/page/privacy/privacy.component';
import { NewsComponent } from './en/page/news/news.component';
import { ContactusComponent } from './en/page/contactus/contactus.component';
import { NewsdetailComponent } from './en/page/newsdetail/newsdetail.component';

import { HomeThComponent } from './th/page/home/home.th.component';
import { CompinfoThComponent } from './th/page/compinfo/compinfo.th.component';
import { IntrobizThComponent } from './th/page/introbiz/introbiz.th.component';
import { EnvironmentThComponent } from './th/page/environment/environment.th.component';
import { PartnerThComponent } from './th/page/partner/partner.th.component';
import { BizlocateThComponent } from './th/page/bizlocate/bizlocate.th.component';
import { HistoryThComponent } from './th/page/history/history.th.component';
import { OutlineThComponent } from './th/page/outline/outline.th.component';
import { TopmsgThComponent } from './th/page/topmsg/topmsg.th.component';
import { BusinessThComponent } from './th/page/business/business.th.component';
import { WhatsankenThComponent } from './th/page/whatsanken/whatsanken.th.component';
import { MaintenanceThComponent } from './th/page/maintenance/maintenance.th.component';
import { ProposalThComponent } from './th/page/proposal/proposal.th.component';
import { EnergyThComponent } from './th/page/energy/energy.th.component';
import { OverseaThComponent } from './th/page/oversea/oversea.th.component';
import { RdThComponent } from './th/page/rd/rd.th.component';
import { SteelThComponent } from './th/page/steel/steel.th.component';
import { AluminiumThComponent } from './th/page/aluminium/aluminium.th.component';
import { OnlyoneThComponent } from './th/page/onlyone/onlyone.th.component';
import { ContactusThComponent } from './th/page/contactus/contactus.th.component';
import { NewsdetailThComponent } from './th/page/newsdetail/newsdetail.th.component';
import { NewsThComponent } from './th/page/news/news.th.component';
import { PrivacyThComponent } from './th/page/privacy/privacy.th.component';
import { TermofuseThComponent } from './th/page/termofuse/termofuse.th.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    CarouselComponent,
    IntroductionComponent,
    ArticleComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    //English Language
    HomeComponent,
    OnlyoneComponent,
    AluminiumComponent,
    SteelComponent,
    RdComponent,
    OverseaComponent,
    EnergyComponent,
    ProposalComponent,
    MaintenanceComponent,
    WhatsankenComponent,
    BusinessComponent,
    TopmsgComponent,
    OutlineComponent,
    HistoryComponent,
    BizlocateComponent,
    PartnerComponent,
    EnvironmentComponent,
    IntrobizComponent,
    CompinfoComponent,
    TermofuseComponent,
    PrivacyComponent,
    NewsComponent,
    ContactusComponent,
    NewsdetailComponent,
    //Thai Language
    HomeThComponent,
    OnlyoneThComponent,
    AluminiumThComponent,
    SteelThComponent,
    RdThComponent,
    OverseaThComponent,
    EnergyThComponent,
    ProposalThComponent,
    MaintenanceThComponent,
    WhatsankenThComponent,
    BusinessThComponent,
    TopmsgThComponent,
    OutlineThComponent,
    HistoryThComponent,
    BizlocateThComponent,
    PartnerThComponent,
    EnvironmentThComponent,
    IntrobizThComponent,
    CompinfoThComponent,
    TermofuseThComponent,
    PrivacyThComponent,
    NewsThComponent,
    ContactusThComponent,
    NewsdetailThComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
