<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ผลิตภัณฑ์ (กลุ่มอลูมิเนียม)</h3>
          </div>
          <p>เรามีส่วนแบ่งทางการตลาดขนาดใหญ่ในญี่ปุ่น ทางด้านเตาหลอมอลูมิเนียมสำหรับการผลิตชิ้นส่วนยานยนต์
            เราเป็นเจ้าแรกๆที่ทำการพัฒนาเทคโนโลยีประหยัดพลังงาน ประหยัดทรัพยากร ประหยัดแรงงาน
            พร้อมทั้งออกแบบมาเพื่อคุณภาพและความปลอดภัยทางด้านสุขภาพและพร้อมที่จะมอบสุดยอดเทคโนโลยีที่ทันสมัยที่สุดให้แก่คุณลูกค้า
            เราสามารถจัดหาอุปกรณ์ที่มีความก้าวหน้าที่สุดของยุคนี้ เช่น หลอม, อุ่น, dosing,กรรมวิธีทางความร้อน,
            การนำชิ้นส่วนเครื่องจักร/กระป๋องน้ำดื่มแล้ว (UBC)กลับมาใช้ใหม่
            สำหรับขั้นตอนและกรรมวิธีต่างๆที่เกิดขึ้นในโรงหล่ออลูมิเนียม
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="1"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>วัตถุดิบสำหรับการหลอม (Materials Melted)</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-2">
                <img src="img/aluminium/alumi_01.jpg" alt="" />
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_02.jpg" alt="" />
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_03.jpg" alt="" />
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_04.jpg" alt="" />
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_05.jpg" alt="" />
              </div>
            </div>
            <br />
            <p>
              เรานำเสนออุปกรณ์ที่ใช้ในการหลอมและแปรรูปวัตถุดิบอลูมิเนียมต่างๆหลายประเภทที่ให้ผลผลิตที่สูง
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>กระบวนการหลอม / เตาหลอมประเภทต่างๆ</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/aluminium/alumi_07.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/aluminium/alumi_06.jpg" alt="" />
              </div>
            </div>
            <br />
            <p>เรามีทั้งเตาหลอมแบบทาวเวอร์ (เตาหลอมเพลา), เตาหลอมแบบจุ่ม, เตาหลอมแบบแนวนอน (reverberatory furnace)
              เตาหลอมแบบรวมกันระหว่างเตาหลอมแบบทาวเวอร์ (เตาหลอมเพลา) และเตาหลอมแบบจุ่ม
              เตาหลอมอลูมิเนียมของเราใช้วัสดุทนไฟที่มีประสิทธิภาพสูงและวัสดุทนไฟที่มีประสิทธิภาพสูง
              (ได้มาพร้อมสิทธิการขายพิเศษในญี่ปุ่น)
              ซึ่งช่วยลดการเกาะตัวติดกันของอลูมิเนียมซึ่งช่วยยืดระยะเวลาในการซ่อมบำรุงออกไปเท่าตัว หรือนานกว่านั้น
              เราได้เป็นผู้จำหน่ายสินค้าเหล่านี้แต่เพียงผู้เดียวในประเทศญี่ปุ่นและเกาหลี
            </p>
            <hr />
          </div>

          <br />
          <div class="blog-post-style-one">
            <h3>กระบวนการอุ่นโลหะ/ เตา Dosing</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_08.jpg" alt="" />
            </div>
            <p>เตาหลอมรูปแบบนี้ใช้สำหรับอุ่นและหลอมเหลวโลหะด้วยพลังงานไฟฟ้า มีการติดตั้งท่อแบบเซลฟ์-รีคูเพอเรทีฟ
              และมีการติดตั้งฮีตเตอร์จุ่ม (immersion heaters) แบบพิเศษที่ด้านบนหรือด้านล่าง
              เพื่อช่วยประสิทธิภาพและให้พลังงานสูง
              รวมถึงพัฒนาวิธีการติดตั้งและปรับปรุงคุณสมบัติทางด้านการต้านทานความร้อน
              เพื่อเพิ่มประสิทธิภาพและประหยัดพลังงาน
              เรานำเสนอให้เครื่อง SMIC ซึ่งเป็นเตาหลอมที่ใช้พลังงานไฟฟ้ารุ่นล่าสุดของเรา ที่มีครบทุกฟังก์ชั่นการหลอม
            </p>
            <br/>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>กระบวนการอบชุบโลหะ/ เตาอบชุบร้อน รุ่น T2, T4, T5, T6 & T7</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_10.jpg" alt="" />
            </div>
            <p>เรานำเสนอเตาอบชุบร้อนแบบ walking beam ภายใต้ชื่อรุ่น T6
              ที่เป็นผลิตภัณฑ์ใหม่ล่าสุดของเราที่พัฒนาขึ้นมาเพื่อใช้สำหรับอบชุบล้อรถยนต์
              เตาประเภทนี้จะทำการเคลื่อนย้ายล้อเข้าสู่เตาโดยอัตโนมัติในระยะห่างที่เท่าๆกัน
              ทำให้ได้รับความร้อนเท่ากันทั้งชิ้นงาน และด้วยการออกแบบของเราบวกกับระบบหมุนเวียนก๊าซร้อนของเตา
              ทำให้เราสามารถลดความยาวของเตาหลอม พร้อมทั้งลดพื้นที่ผิวทั้งหมดของเตาหลอมไป25%
              (เทียบกับดีไซน์แบบดั้งเดิมของเรา) และรวมถึงลดการใช้พลังงานไฟฟ้าถึง 30% (ditto)
              <br />
              แต่เรายังคงมีเตาหลอมโลหะแบบสายพานและแบบแกนหมุน ( roller hearth ) ให้บริการอีกด้วย
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>กระบวนการแปรรูปและอบแห้งก่อนทำการหลอม/ เตาเผาโรตารี IDEX</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-6 text-center">
                <img src="img/aluminium/alumi_04.gif" alt="" style="width: 100%;" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/aluminium/alumi_11.jpg" alt="" />
              </div>
            </div>
            <p>IDEX ป็นเตาเผาแบบโรตารี่ที่ทำกระบวนการแปรรูปอลูมิเนียนและอบแห้งชิ้นส่วนที่เปียกก่อนทำกระบวนการหลอม เตาแบบ
              IDEX ประกอบด้วย เตาเผาหมุนที่ใช้ปั่นเพื่อทำให้แห้ง ระบบกำจัดกลิ่น-ควัน(Afterburner)และ เตาเผาแกลบไซโคลน
              และตัวดักจับและลดความเป็นพิษของไอหรือควันร้อน(fume, whte smoke)
              ที่เกิดขึ้นจากวัตถุให้ความเย็นระหว่างขั้นตอนการทำให้แห้งจะถูกเผาในเครื่องกำจัดกลิ่น-ควัน (Afterburner)
              ก่อนที่จะปล่อยคืนสู่อากาศข้างนอก น้ำยาหล่อเย็นที่จะทำให้แห้งเป็น VOC ชนิดไวไฟ (สารประกอบอินทรีย์ระเหย)
              ความร้อนของ Nitrogen oxides (NOx) ของ VOC จะถูกนำไปใช้ประโยชน์ต่ออย่างมีประสิทธิภาพ
              โดยจะถูกนำไปใช้เป็นพลังงานความร้อนที่ต้องใช้สำหรับระบบทำให้แห้งของ IDEX
              ดังนั้นมันจึงเป็นเครื่องจักรที่ประหยัดพลังงานมาก
              <br />
              เราซื้อเทคโนโลยีIDEXมาจากประเทศอังกฤษในฐานะสินทรัพย์ รวมถึงสิทธิบัตร
              และตอนนี้มันเป็นหนึ่งในเทคโนโลยีของเรา
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>ระบบปล่อยน้ำหลอม/ ระบบปล่อยน้ำหลอมแบบไม่มีช่อง (tap-holeless tapping system) KAMOGAWA</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_12.jpg" alt="" />
            </div>
            <p>แทนที่จะเป็นระบบแบบดั้งเดิมที่มีช่องปล่อยของเหลวออก (tap-hole) เครื่อง Kamogawa
              ของเราเป็นระบบช่องทางน้ำหลอมไหลผ่าน tapping out ที่ใช้เทคโนโลยีปั๊มเกลียว
              ความเร็วในการปล่อยน้ำหลอมให้ไหลผ่านและความแรงปั๊ม (pumping head) คือ 500กิโลกรัม/นาที และ 360มิลลิเมตร
              ผู้คุมเครื่องจักรสามารถกดปุ่มเพื่อระบายน้ำหลอมให้ไหลออกอย่างปลอดภัย
              มันถูกออกแบบมาเพื่อติดตั้งในเตาหลอม-อุ่นโลหะที่คุณมีอยู่และใช้งานได้ดี นอกจากนี้มันดูแลรักษา เกลียว และ
              กระบอก(sleeve)ง่าย
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>