import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-th-history',
  templateUrl: './history.th.component.html',
  styleUrls: ['./history.th.component.css']
})
export class HistoryThComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: '1949', description: 'ก่อตั้งบริษัท ซังเค็นซังเกียว จำกัด ที่ประเทศญี่ปุ่น' },
      { name: '1970', description: 'ทำข้อตกลงทางด้านเทคนิกกับบริษัท Chugai Ro Co., Ltd ทำเตาหลอมแบบต่อเนื่อง (continuous reheating furnace)' },
      { name: '1980', description: 'ร่วมมือกับบริษัท Sun Furnace Co., Ltd ทำเตาไฟฟ้า (electric furnaces)' },
      { name: '1990', description: 'ก่อตั้งบริษัท Sanken Tech Co.,Ltd.' },
      { name: '1992', description: 'ก่อตั้งบริษัท Established Sanken Access Co.,Ltd.' },
      { name: '1994', description: 'ได้รับรางวัลชนะเลิศทางด้านการอนุรักษ์พลังงานจาก สมาคมอุตสาหกรรมเครื่องจักรกลญี่ปุ่น' },
      { name: '1996', description: 'ทำข้อตกลงทางด้านเทคนิกกับบริษัท Insertec ของประเทศสเปน' },
      { name: '1997', description: 'ก่อตั้ง  NEU—Sanken กับมหาวิทยาลัยนอร์ทอีสเทิร์นในประเทศจีน' },
      { name: '1999', description: 'ได้รับใบรับรอง ISO 9001' },
      { name: '2000', description: 'ทำข้อตกลงทางด้านเทคนิกกับบริษัท SAC ของประเทศเกาหลีใต้' },
      { name: '2002', description: 'ก่อตั้ง Sanken Insertec Europe (SIE) กับบริษัท Insertc ของประเทศสเปน' },
      { name: '2008', description: 'ย้ายที่ตั้งสำนักงานใหญ่มาอยู่ที่  3-1-2 Tomonishi, Asaminami-ku, Hiroshima 731-3169, Japan ซึ่งเป็นที่ตั้งปัจจุบัน' },
      { name: '2009', description: 'ทำการแยกบริษัทออกเป็น บริษัท Sanken Holdings Co., Ltd.ที่ดำเนินธุรกิจในลักษณะบริษัทโฮลดิ้ง (Holding company)  และบริษัท Sanken Sangyo Co., Ltd. ที่ดำเนินธุรกิจเดิมของตนเอง (Operational Company)' },
      { name: '2011', description: 'ก่อตั้ง PT. SWIF ASIA และ  PT. WIN ที่ประเทศอินโดนีเซีย' },
    ]
  }

}
