<footer class="footer">
  <div class="footer-top">
    <div class="thm-container">
      <div class="row">

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="footer-widget links-widget service-widget">
            <div class="title">
              <h3>Products & Services</h3>
            </div>
            <ul class="link-list">
              <li *ngFor="let item of footer.business"><a [routerLink]="[ item.link ]">{{item.name}}</a></li>
            </ul>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="footer-widget links-widget service-widget">
            <div class="title">
              <h3>About Us</h3>
            </div>
            <ul class="link-list">
              <li *ngFor="let item of footer.compInfo"><a [routerLink]="[ item.link ]">{{item.name}}</a></li>
            </ul>
          </div>
        </div>



        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="footer-widget links-widget">
            <div class="title">
              <h3>Links</h3>
            </div>
            <ul class="link-list">
              <li>
                <a href="https://www.sanken-sangyo.co.jp/en/" target="_blank">Sanken Sangyo Co.,Ltd</a>
              </li>
              <li *ngFor="let item of footer.quicklink"><a [routerLink]="[ item.link ]">{{item.name}}</a></li>
            </ul>
          </div>
        </div>



        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="footer-widget contact-widget">
            <div class="about-widget footer-widget">
              <a href="#" class="footer-logo"><img src="img/head_logo1.jpg" alt="Awesome Image" /></a>
              <p>{{footer.intro}}</p>
            </div>
            <div class="title">
              <h3>SANKEN INDUSTRIAL FURNACE (THAILAND) CO.,LTD</h3>
            </div>
            <p class="address">88/31 Moo.4, Tambol Klong Suan, Amphur Bangbor, Samutprakarn Province, 10560 THAILAND
            </p>
            <br />
            <p class="phone-number">TEL: +66-2-130-6236</p>
            <p class="phone-number">FAX: +66-2-130-6238</p>
            <p class="phone-number">Email: inquiry@sanken.co.th</p>
            <!-- <div class="social">
                      <a href="#" class="fa fa-facebook"></a>
                      <a href="#" class="fa fa-linkedin"></a>
                      <a href="#" class="fa fa-twitter"></a>
                      <a href="#" class="fa fa-google-plus"></a>
                    </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom text-center">
    <div class="thm-container">
      <p>&copy; Copyright (C) Sanken Industrial Furnace (Thailand) Co., Ltd. All Rights Reserved.</p>
    </div>
  </div>

</footer>