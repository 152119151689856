<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>สาน์สจากผู้บริหาร</h3>
          </div>
          <!-- <p>
            Introduction of top message.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="COMP" menuIndex="2"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>สาน์สจากผู้บริหาร</h3>
            <br />
            <p>
              นับตั้งแต่ก่อตั้งขึ้นในปี 2492 ในนามบริษัทซังเคน ซังเกียว จำกัด บริษัทผู้ดำเนินธุรกิจเตาอุตสาหกรรม
              ได้พบกับความท้าทายในเรื่องของ "พลังงานและความร้อน" มาโดยตลอด แต่เราก็ประสบความสำเร็จในการแก้ปัญหาทุกครั้ง
              ด้วยความพยายามและความทุ่มเทของพนักงาน และในขณะเดียวกันเราก็ได้รับความช่วยเหลือจากลูกค้าเป็นอย่างดี
              ซึ่งถือเป็นบันทึกหน้าหนึ่ง ที่พนักงานเราทุกคนได้แสดงให้เห็นว่า
              เราได้ใช้สติปัญญาและความกล้าหาญที่จะท้าทายต่อสู้ร่วมกันมาตลอดในช่วงเวลาที่ผ่านมา
              จึงขอสนับสนุนและเป็นกำลังใจที่ดียิ่ง และไว้วางใจในตัวบุคลากรทุกคนในบริษัท
              <br />
              <br />
              ขณะนี้ภาวะโลกร้อนถือเป็นปัญหาที่มวลมนุษยชาติจะต้องช่วยกันป้องกัน
              และนั่นก็เป็นอีกหนึ่งภารกิจที่กลุ่มผู้ผลิต เตาอุตสาหกรรมจะต้องช่วยกันแก้ปัญหา และพวกเราจะใช้ความพยายาม
              และความร่วมมือร่วมใจกันในการรักษาอุดมการณ์ที่เป็นจุดกำเนิดของการก่อตั้งบริษัท และปฏิบัติตามภารกิจ
              เพื่อที่เราจะได้เป็นบริษัทที่น่าภาคภูมิใจสำหรับคนรุ่นต่อไปในอนาคต
              <br />
              <br />
              ขอชื่นชมจากใจของเรา สำหรับคำแนะนำและกำลังใจที่ดีตลอดมา

            </p>
            <br />
            <div class="text-center">
              <img src="img/topmsg/vision01.jpg" alt="" />
              <div class="col-md-6">
                Takashi MANDAI / Chairman
              </div>
              <div class="col-md-6">
                Kosei DAIDA / President
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>