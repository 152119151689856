import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-th-outline',
  templateUrl: './outline.th.component.html',
  styleUrls: ['./outline.th.component.css']
})
export class OutlineThComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: 'ชื่อบริษัท', description: 'บริษัทซังเคน ซังเกียว จำกัด' },
      { name: 'วันที่ก่อตั้ง', description: '17 สิงหาคม 2492' },
      { name: 'เงินทุน', description: '95,000,000 เยน' },
      {
        name: 'Board of Directors',
        description: ' Mr. Takashi MANDAI ประธานบริษัท \n Mr. Kosei DAIDA ประธานกรรมการบริษัท \n Mr. Keizo ARISUMI ประธานกรรมการผู้บริหารระดับสูง \n Mr. Koji AKITA กรรมการผู้บริหาร \n Mr. Yoshihiro KATSURA กรรมการผู้บริหาร \n Mr. Kazushi NISHIHORI ผู้บริหาร'
      },
      { name: 'จำนวนพนักงาน', description: 'บริษัทซังเคน ซังเกียว จำกัด มีพนักงานทั้งหมด 150 คน และ บริษัทในเครือซังเคน มีพนักงานทั้งหมด 450 คน' },
      { name: 'ธุรกิจหลัก', description: 'วิศวกรรมและการออกแบบ การผลิต การขาย การก่อสร้างและการบำรุงรักษาเตาอุตสาหกรรม' },
      { name: 'เลขที่ใบอนุญาตก่อสร้าง', description: 'No.34943' },
      { name: 'Our Bank', description: 'ธนาคารโตเกียว - มิตซูบิชิยูเอฟเจ จำกัด \n ธนาคารฮิโรชิมา จำกัด \n ธนาคารโชโกะจูคิน จำกัด' },
    ]
  }

}
