import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './en/page/home/home.component';
import { OnlyoneComponent } from './en/page/onlyone/onlyone.component';
import { AluminiumComponent } from './en/page/aluminium/aluminium.component';
import { SteelComponent } from './en/page/steel/steel.component';
import { RdComponent } from './en/page/rd/rd.component';
import { OverseaComponent } from './en/page/oversea/oversea.component';
import { EnergyComponent } from './en/page/energy/energy.component';
import { ProposalComponent } from './en/page/proposal/proposal.component';
import { MaintenanceComponent } from './en/page/maintenance/maintenance.component';
import { EnvironmentComponent } from './en/page/environment/environment.component';
import { PartnerComponent } from './en/page/partner/partner.component';
import { BizlocateComponent } from './en/page/bizlocate/bizlocate.component';
import { HistoryComponent } from './en/page/history/history.component';
import { OutlineComponent } from './en/page/outline/outline.component';
import { TopmsgComponent } from './en/page/topmsg/topmsg.component';
import { BusinessComponent } from './en/page/business/business.component';
import { WhatsankenComponent } from './en/page/whatsanken/whatsanken.component';
import { IntrobizComponent } from './en/page/introbiz/introbiz.component';
import { CompinfoComponent } from './en/page/compinfo/compinfo.component';
import { TermofuseComponent } from './en/page/termofuse/termofuse.component';
import { PrivacyComponent } from './en/page/privacy/privacy.component';
import { NewsComponent } from './en/page/news/news.component';
import { ContactusComponent } from './en/page/contactus/contactus.component';
import { NewsdetailComponent } from './en/page/newsdetail/newsdetail.component';
import { HomeThComponent } from './th/page/home/home.th.component';
import { CompinfoThComponent } from './th/page/compinfo/compinfo.th.component';
import { IntrobizThComponent } from './th/page/introbiz/introbiz.th.component';
import { EnvironmentThComponent } from './th/page/environment/environment.th.component';
import { PartnerThComponent } from './th/page/partner/partner.th.component';
import { BizlocateThComponent } from './th/page/bizlocate/bizlocate.th.component';
import { HistoryThComponent } from './th/page/history/history.th.component';
import { OutlineThComponent } from './th/page/outline/outline.th.component';
import { TopmsgThComponent } from './th/page/topmsg/topmsg.th.component';
import { BusinessThComponent } from './th/page/business/business.th.component';
import { WhatsankenThComponent } from './th/page/whatsanken/whatsanken.th.component';
import { MaintenanceThComponent } from './th/page/maintenance/maintenance.th.component';
import { ProposalThComponent } from './th/page/proposal/proposal.th.component';
import { EnergyThComponent } from './th/page/energy/energy.th.component';
import { OverseaThComponent } from './th/page/oversea/oversea.th.component';
import { RdThComponent } from './th/page/rd/rd.th.component';
import { SteelThComponent } from './th/page/steel/steel.th.component';
import { AluminiumThComponent } from './th/page/aluminium/aluminium.th.component';
import { OnlyoneThComponent } from './th/page/onlyone/onlyone.th.component';
import { ContactusThComponent } from './th/page/contactus/contactus.th.component';
import { NewsdetailThComponent } from './th/page/newsdetail/newsdetail.th.component';
import { NewsThComponent } from './th/page/news/news.th.component';
import { PrivacyThComponent } from './th/page/privacy/privacy.th.component';
import { TermofuseThComponent } from './th/page/termofuse/termofuse.th.component';

const routes: Routes = [
  {
    path: '',
    component: HomeThComponent
  },
  {
    path: 'en/home',
    component: HomeComponent
  },
  {
    path: 'en/termofuse',
    component: TermofuseComponent
  },
  {
    path: 'en/privacy',
    component: PrivacyComponent
  },
  {
    path: 'en/news',
    component: NewsComponent
  },
  {
    path: 'en/newsdetail',
    component: NewsdetailComponent
  },
  {
    path: 'en/contactus',
    component: ContactusComponent
  },
  {
    path: 'en/onlyone',
    component: OnlyoneComponent
  },
  {
    path: 'en/aluminium',
    component: AluminiumComponent
  },
  {
    path: 'en/steel',
    component: SteelComponent
  },
  {
    path: 'en/rd',
    component: RdComponent
  },
  {
    path: 'en/oversea',
    component: OverseaComponent
  },
  {
    path: 'en/energy',
    component: EnergyComponent
  },
  {
    path: 'en/proposal',
    component: ProposalComponent
  },
  {
    path: 'en/maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'en/whatsanken',
    component: WhatsankenComponent
  },
  {
    path: 'en/business',
    component: BusinessComponent
  },
  {
    path: 'en/topmsg',
    component: TopmsgComponent
  },
  {
    path: 'en/outline',
    component: OutlineComponent
  },
  {
    path: 'en/history',
    component: HistoryComponent
  },
  {
    path: 'en/bizlocate',
    component: BizlocateComponent
  },
  {
    path: 'en/partner',
    component: PartnerComponent
  },
  {
    path: 'en/environment',
    component: EnvironmentComponent
  },
  {
    path: 'en/introbiz',
    component: IntrobizComponent
  },
  {
    path: 'en/compinfo',
    component: CompinfoComponent
  },
  // Thai Language
  {
    path: 'th/home',
    component: HomeThComponent
  },
  {
    path: 'th/termofuse',
    component: TermofuseThComponent
  },
  {
    path: 'th/privacy',
    component: PrivacyThComponent
  },
  {
    path: 'th/news',
    component: NewsThComponent
  },
  {
    path: 'th/newsdetail',
    component: NewsdetailThComponent
  },
  {
    path: 'th/contactus',
    component: ContactusThComponent
  },
  {
    path: 'th/onlyone',
    component: OnlyoneThComponent
  },
  {
    path: 'th/aluminium',
    component: AluminiumThComponent
  },
  {
    path: 'th/steel',
    component: SteelThComponent
  },
  {
    path: 'th/rd',
    component: RdThComponent
  },
  {
    path: 'th/oversea',
    component: OverseaThComponent
  },
  {
    path: 'th/energy',
    component: EnergyThComponent
  },
  {
    path: 'th/proposal',
    component: ProposalThComponent
  },
  {
    path: 'th/maintenance',
    component: MaintenanceThComponent
  },
  {
    path: 'th/whatsanken',
    component: WhatsankenThComponent
  },
  {
    path: 'th/business',
    component: BusinessThComponent
  },
  {
    path: 'th/topmsg',
    component: TopmsgThComponent
  },
  {
    path: 'th/outline',
    component: OutlineThComponent
  },
  {
    path: 'th/history',
    component: HistoryThComponent
  },
  {
    path: 'th/bizlocate',
    component: BizlocateThComponent
  },
  {
    path: 'th/partner',
    component: PartnerThComponent
  },
  {
    path: 'th/environment',
    component: EnvironmentThComponent
  },
  {
    path: 'th/introbiz',
    component: IntrobizThComponent
  },
  {
    path: 'th/compinfo',
    component: CompinfoThComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
