import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/model/Category';

@Component({
  selector: 'app-compinfo',
  templateUrl: './compinfo.component.html',
  styleUrls: ['./compinfo.component.css']
})
export class CompinfoComponent implements OnInit {

  category: Category[];

  constructor() { }

  ngOnInit() {
    this.category = this.getCompanyInfo()
  }

  getCompanyInfo() {
    return [
      {
        image: 'img/compinfo/infoImage01.jpg',
        name: "What's sanken",
        link: '/en/whatsanken'
      }, {
        image: 'img/compinfo/infoImage02.jpg',
        name: 'Business Philosophy',
        link: '/en/business'
      }, {
        image: 'img/compinfo/infoImage03.jpg',
        name: 'Top Message',
        link: '/en/topmsg'
      }, {
        image: 'img/compinfo/infoImage04.jpg',
        name: 'Outline of The Company',
        link: '/en/outline'
      }, {
        image: 'img/compinfo/infoImage05.jpg',
        name: 'History',
        link: '/en/history'
      }, {
        image: 'img/compinfo/infoImage06.jpg',
        name: 'Business Locations',
        link: '/en/bizlocate'
      }, {
        image: 'img/compinfo/infoImage07.jpg',
        name: 'Group Company / Partner',
        link: '/en/partner'
      }, {
        image: 'img/compinfo/infoImage08.jpg',
        name: 'Environmental Activities',
        link: '/en/environment'
      }
    ]
  }

}
