<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>การปรับปรุงผลิตภัณฑ์</h3>
          </div>
          <!-- <p>
            Introduction of innovation examples we have worked on in energy saving.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="6"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>เตาหลอมเหล็กแบบต่อเนื่อง – เปลี่ยรูปแบบการปล่อยเชื้อเพลิง (Fuel Conversion)</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_01.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_02.jpg" alt="" />
              </div>
            </div>
            <p>เป็นการปรับปรุงที่เน้นในในเรื่องการเปลี่ยนเชื้อเพลิงและการปรับปรุงช่วงบนของเตาหลอมแบบ walking beam
              โดยใช้เวลาในการปรับปรุงตามที่ออกแบบไว้เพียง 35วันเท่านั้น ภายหลังจากการปรับปรุงใหม่
              ความสามารถในการทำความร้อนเพิ่มขึ้นประมาณ 8% และอัตราการใช้เชื้อเพลิงลดลงประมาณ9% นอกจากนั้น
              การควบคุมความดันอย่างเหมาะสมของเตาหลอม และ
              การเพิ่มฉนวนกันความร้อนเพื่อช่วยลดอุณหภูมิของพื้นผิวเตาหลอมในอัตราที่สูงมาก
              และยังช่วยปรับปรุงสภาพแวดล้อมในที่ทำงาน
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอมอลูมิเนียม - เปลี่ยนการใช้เชื้อเพลิงให้เป็นแก๊ซ</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_03.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_04.jpg" alt="" />
              </div>
            </div>
            <p>เป็นโครงการปรับปรุงที่เน้นในด้านการเปลี่ยนแปลงหัวเตาให้เป็นหัวเตาเผาแบบที่ใช้แก๊สเป็นเชื้อเพลิง
              (gas-fired regenrative burners)
              ซึ่งนอกจากจะช่วยลดปริมาณแรงงานด้วยระบบควบคุมอุณหภูมิอัตโนมัติสำหรับทั้งโลหะเหลวและอากาศในเตาหลอมแล้ว
              ยังมีอัตราการใช้เชื้อเพลิงลดลงประมาณ 30% และมีอัตราการปล่อยคาร์บอนไดออกไซด์ลดลง 50%
              ด้วยการทำงานร่วมกันและการเปลี่ยนแปลงไปใช้ก๊าซธรรมชาติ
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Roll Heat Treatment Furnace – เปลี่ยนการใช้เชื้อเพลิงให้เป็นแก๊ซ</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_05.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_06.jpg" alt="" />
              </div>
            </div>
            <p>การเปลี่ยนเชื้อเพลิงของ car bottom roll heat treatment furnace โดยเราได้ทำการติดตั้งเตาเผาความเร็วสูง
              (Downwardly high velocity burners) ที่เพดานเตาเพื่อทำการกระจายอุณหภูมิในเตาเผา โดยมีการควบคุมวงจร เปิด/ปิด
              จึงเป็นผลให้การกระจายอุณหภูมิในเตาหลอมมีประสิทธิภาพดีขึ้น และลดอัตราการใช้เชื้อเพลิง รวมถึงลดอัตราการปล่อย
              NOx, CO2 และ CO อีกด้วย
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>การปรับปรุงเตาหลอม-เผาโลหะแบบหมุนสำหรับการขึ้นรูป</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_07.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_08.jpg" alt="" />
              </div>
            </div>
            <p>เราได้ทำการปรับปรุงเชิงปฏิวัติเตา rotary hearth heating furnace ซึ่งใช้สำหรับการขึ้นรูปด้วยแม่พิมพ์ (die
              forging) ภายหลังการปรับปรุงจะช่วยประหยัดพลังงานถึง 50% หรือมากกว่า และช่วยลดการสูญเสียขนาด
              นอกจากนี้ระบบการลำเลียงวัสดุที่รอการหลอมโดยอัตโนมัติทำให้ผู้ควบคุมเครื่องจักรไม่ต้องเผชิญกับความร้อนในระหว่างการทำงานอีกต่อไป
              นอกจากนี้เรายังติดตั้งกลไกที่สามารถนำเตาแยกออกมาเพื่อทำการบำรุงรักษาได้
            </p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>