<section class="service-style-one dark-bg">
  <div class="thm-container">
    <div class="title">
      <h3>{{header}}</h3>
    </div>
    <div class="row">

      <div *ngIf="toggle">
        <div class="col-md-3" *ngFor="let item of menu">
          <div class="single-service-style-one">
            <div class="text-box">
			  <div>
				<img src="{{item.icon}}" alt="Awesome Image" />
			  </div>
              <h3>{{item.name}}</h3>
              <p>{{item.description}}</p>
              <a [routerLink]="[item.link]" class="read-more">{{readmore}}</a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!toggle">
        <div class="col-md-3" *ngFor="let item of menu">
          <div class="single-service-style-one">
            <div class="text-box">
		      <div>
				<img src="{{item.icon}}" alt="Awesome Image" />
			  </div>
              <h3>{{item.name}}</h3>
              <p>{{item.description}}</p>
              <a [routerLink]="[item.link]" class="read-more">{{readmore}}</a>
            </div>
          </div>
        </div>
      </div>

    </div><!-- /.row -->
  </div><!-- /.thm-contaienr -->
</section><!-- /.service-section dark-bg -->