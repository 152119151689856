import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-th-onlyone',
  templateUrl: './onlyone.th.component.html',
  styleUrls: ['./onlyone.th.component.css']
})
export class OnlyoneThComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
