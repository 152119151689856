<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Company Information</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="feature-product">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12" *ngFor="let item of category">
        <div class="single-featured-product">
          <a [routerLink]="[item.link]" href="#">
            <div>
              <img src="{{item.image}}" alt="Awesome Image" />
            </div>
            <div class="text-box">
              <h3>{{item.name}}</h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
