
export class Link {
    public name?: string;
    public link?: string;
}

export class FooterInfo {
    public intro?: string;
    public quicklink?: Link[];
    public business?: Link[];
    public compInfo?: Link[];
    public phone?: string;
    public address?: string;
    public email?: string;

    public facebook?: string;
    public twitter?: string;
    public google?: string;
}
