<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Products (Aluminium Areas)</h3>
          </div>
          <p>We have the largest market share in Japan in aluminium melting furnaces for the production of automotive
            components. We have been early to work on technology development in energy saving, resources saving, labor
            saving, quality and safety & health, and are ready to provide customer with the state of the art
            technologies.
			<br>
            We can offer most advanced equipment such as melting, holding, dosing, heat treatment, recycling of
            machining chip and UBC, in various processes in aluminium foundries.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="1"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Materials Melted</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-2">
                <img src="img/aluminium/alumi_01.jpg" alt="" />
				<p>Ingot</p>
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_02.jpg" alt="" />
				<p>Foundry Returns</p>
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_03.jpg" alt="" />
				<p>Machining Chip</p>
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_04.jpg" alt="" />
				<p>Scrap</p>
              </div>
              <div class="col-md-2">
                <img src="img/aluminium/alumi_05.jpg" alt="" />
				<p>UBC</p>
              </div>
            </div>
            <br />
            <p>We make a proposal of installation being capable of melting/processing various kinds of feedstock with
              higher production yield.
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Melting Process / Various Melting Furnaces</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/aluminium/alumi_07.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/aluminium/alumi_06.jpg" alt="" />
              </div>
            </div>
            <br />
            <p>Based on customer's needs, we can offer most suitable melting furnace, such as tower melter (shaft
              melter), immersion heating melter, reverberatory furnace and/or combination furnace of tower and immersion
              melters.
			  <br>
              Our melting furnaces, in pivotal points, apply high abrasion resistance refractory and the US made high
              performance refractory castable to which aluminium oxide is less likely to stick, extending the
              maintenance cycle double or more. We have exclusive distributorship of the said castable in both Japan and
              Korea.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Holding Process / Dosing Furnaces & Casting Cell Side Melting & Dosing Furnaces</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_08.jpg" alt="" />
            </div>
            <p>These furnaces are for holding and dosing of molten metal on the side of casting cell. Self-recuperative
              gas-fired immersion tube design and high thermally efficient electric heater design (roof mounted heater
              or under heater) are available.　We have developed our own refractory formation and installation
              methodology, and improve the thermal insulating properties. 
			  <br>
			  Particularly, we make a proposal of our S-MIC,
              fully electrified melting & holding furnace, as the newest melting furnace model.
              S-MIC, our product name, means Sanken Melting, Immersion and Circulation.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Heat Treatment Process / T2, T4, T5, T6 & T7 Heat Treatment Furnaces</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_10.jpg" alt="" />
            </div>
            <p>Among of our various types of heat treatment furnaces, we have the newest one of Intelligent (ITG) waking
              beam T6 furnace design which has been developed for hear treatment for alloy motor wheels. This furnace
              can automatically transport wheels at a minimal beam traveling pitch, according to the different diameter,
              thus increasing the filling rate of wheels in the furnace chamber. The ITG design plus our own hot gas
              reciculation system allow us to shorten the furnace length, decreasing the entire furnace surface area by
              25% (compared to our conventional design) and also decreasing the electric power consumption by 30%
              (ditto).
			  <br>
              Also, we can offer conventional basket-loading or basketless roller hearth designs.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Machining Chip Drying before Melting / IDEX Rotary Kiln</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-6 text-center shift-bottom">
                <img src="img/aluminium/alumi_04.gif" alt="" style="width: 100%;" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/aluminium/alumi_11.jpg" alt="" />
              </div>
            </div>
            <p>IDEX, our product name, is an enhanced rotary kiln system which can dry machining chips wetted with
              coolant generating from the machining process of cast products, prior to melting. IDEX consists of a
              drying rotary kiln, an afterburner and a cyclone, and fume (white smoke) generating from the coolant
              during drying process is incinerated within the afterburner chamber, prior to exiting to the air. Coolant
              to be dried is a kind of flammable VOC (volatile organic compounds). Combustion heat of the VOC is
              utilized effectively as a part of the heat required for the IDEX drying system so that the IDEX can be an
              energy saving drying system.
			  <br>
              IDEX system can be applied not only for machining chip drying, but also for decoating UBC, window frames,
              laminates, etc. Experiences, experiments and its analyses available from a lot of our IDEX references
              secure safety operation.
			  <br>
              We purchased IDEX technology from UK as our asset, including patents, and it is now one of our own
              technologies.
            </p>
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Tap-holeless Tapping System / KAMOGAWA</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/aluminium/alumi_12.jpg" alt="" />
            </div>
            <p>In stead of conventional tap-hole system, our Kamogawa is a tapping out system using screw pump
              technology.
			  <br>
              Available tapping out speed and pumping head are 500 kg/min and 360 mm. Operator can tap out molten metal
              safely by pressing a pushbutton. It is designed to install in the existing open well of melting/holding
              furnaces. Also, it is easy to maintain screw and sleeve.
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
