<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Contact Us </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>Address</h2>
      <p>
        SANKEN INDUSTRIAL FURNACE (THAILAND) CO.,LTD <br />
        88/31 Moo.4, Tambol Klong Suan, Amphur Bangbor, Samutprakarn Province, 10560 THAILAND<br />
        Telephone : +66-2-130-6236<br />
        Fax : +66-2-130-6238<br />
        Email : inquiry@sanken.co.th
      </p>
    </div>

  </div>
</section>

<app-footer lang="en"></app-footer>