<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Research & Development</h3>
          </div>
          <p>
            Introduction of our present and past research & development.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="3"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Air Quench</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_01.jpg" alt="" />
            </div>
            <p>We have built a testing machine and worked to develop air quenching technologies in order to solve the
              problems of residual stress and distortion of the work-pieces coming from conventional hot water quenching
              that is commonly performed in the heat treatment process for aluminium products. We have obtained
              necessary design parameters after repeated quenching tests varying heating/cooling temperatures and time.
              Testing of heat treatment for titanium alloy has been also performed. Based on such testing machine and
              experiences, we can offer a best suitable equipment according to customer's own requirements.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Analysis and Design Optimization</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <img src="img/rd/research_08.jpg" alt="" />
            </div>
            <p>We have been introducing design optimization utilizing Taguchi Method (MT). In order to meet customer's
              requirements, we work on from proposing a test plan to its developing in actual equipment, including
              prediction of performances and conformity with global standards.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Counter-flow Acid Pickling Equipment for Wire Zinc Galvanizing Line</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-6 text-center shift-bottom">
                <img src="img/rd/research_03.jpg" alt="" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/rd/research_02.jpg" alt="" />
              </div>
            </div>
            <p>Our acid pickling equipment applies counter-flow design so as to accelerate a reaction between wire and
              acid, which also makes overall length of the acid pickling tank short. Our new design to minimize
              evaporation of hydrochloric fume also can improve the working environment and reduce the hydrochloric acid
              consumption.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Development of Slit Nozzle Regenerative Burner</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_04.jpg" alt="" />
            </div>
            <p>We have developed slit nozzle regenerative burner which is suitable for medium or small-sized reheating
              furnaces for forging. When existing furnaces are renovated from conventional burners to regenerative
              burners, there are problems such as localized heating and destruction of metallographic structure of the
              work-pieces to be heated. Our developed slit nozzle regenerative burners expand the burner flame
              horizontally, for this reason, the effective heating zone height of the furnace can be secured as it was
              before renovation. This also can make the furnace height compact when is newly built.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Development of Highly-insulated Aluminium Holding Furnace</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-6 text-center shift-bottom">
                <img src="img/rd/research_05.jpg" alt="" />
              </div>
              <div class="col-md-6 text-center">
                <img src="img/rd/research_06.jpg" alt="" />
              </div>
            </div>
            <p>We have made researches of refractory constitution and how to install and have developed a
              highly-insulated aluminium molten metal holding furnace so as to improve energy consumption and working
              environment. It improves the thermal insulating properties by 20% compared to our conventional holding
              furnaces. This technology is fully utilized in our original electric melting & holding furnace "S-MIC".
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Combustion Experimental Equipment for Combustibles</h3>
            <br />
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/rd/research_07.jpg" alt="" />
            </div>
            <p>In order to enhance safe operability of our machining chip drying system IDEX and Eco-Kiln, we have
              collected lots of data produced from our own combustion experimental equipment on the combustion of
              coolant which is used in the machining process of aluminium products. Based on these data, we try to
              choose design parameters such as temperature and oxygen level in the actual drying system. We are
              continuing further development so that we can provide safe and suitably-controlled machining chip dryer to
              our customer.
            </p>
			<br>
			<br>
            <p>
              Contents we introduce in our home page is merely a part of our research and development. We have worked on
              not only our own development, but also we have made a lot of proposals against our customers' requirements
              and worries. Our Sanken Sangyo s establish management principle "To provide something of value for
              customers, and continuously enhance customer's confidences and satisfactions", and from now on also, we
              will work on research and development together with our customers.
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>