<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Privacy Policy</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>Privacy Policy</h2>
      <p>Sanken Sangyo Co., Ltd. (hereinafter "SANKEN") recognizes that all personal information managed and used in its
        business activities must be handled and protected with the utmost care. Therefore, SANKEN will follow the basic
        policy stated below.
      </p>
      <ol class="checklist">
        <li>SANKEN will not acquire any personal information through false or other improper means.</li>
        <li>SANKEN will use personal information only to the extent and for the purposes specified, which will be
          announced or noticed to the persons to whom the information pertains.</li>
        <li>SANKEN will endeavor to keep such personal information accurate and up-to-date.</li>
        <li>SANKEN will take necessary and appropriate measures to maintain the security of such personal information.
        </li>
        <li>SANKEN will furnish its employees and contractors handling such personal information with the necessary and
          appropriate guidance and supervision.</li>
        <li>SANKEN will not provide personal information to any third party without the consent of the person involved.
        </li>
        <li>If SANKEN receives an inquiry from a person about the use or content of personal information related to that
          person, it will provide a reasonable response.</li>
        <li>If SANKEN receives any complaints regarding the handling of personal information, it will resolve such
          complaints in a prompt and appropriate manner.</li>
        <li>SANKEN will establish rules and management systems for proper handling and protection of personal
          information and will thoroughly adhere to them.</li>
        <li>SANKEN will engage in a strong effort to further enhance personal information protection systems by
          regularly reviewing and updating all rules and procedures regarding the handling of personal information,
          including this policy.</li>
        <li>SANKEN will comply with all applicable Japanese laws and regulations regarding the handling of personal
          information.</li>
      </ol>
    </div>

    <div class="title">
      <h2>1. Use of Personal Information</h2>
      <p>
        SANKEN will acquire and use personal information only as needed for its business performance according to its
        business purposes.
      </p>
    </div>

    <div class="title">
      <h2>2. Personal Information Management</h2>
      <p>
        SANKEN will take necessary and appropriate measures to ensure the security of the personal information possessed
        by it and endeavor to maintain the contents of such personal information as accurate and up-to-date to the
        extent necessary for business purposes.
      </p>
      <p>
        Personal information which is no longer necessary for business purposes will be disposed of promptly in an
        appropriate method.
      </p>
    </div>

    <div class="title">
      <h2>3. How to Handle Personal Information on the Website</h2>
      <p>
        We will not disclose personal information or sell/rent any items on this web site. However, the personal
        information may be disclosed in the following cases.
      </p>
      <ul>
        <li>In case that the customer agreed the disclosure or sharing of the information.</li>
        <li>In case that public agency such as a court and police department requested us to disclose the information to
          them
          legally.</li>
        <li>In case that the customer's action on the web site violates the agreement, and disclosure is judged to be
          necessary to protect our right, properties, services, etc.</li>
      </ul>
    </div>

    <div class="title">
      <h2>4. Contact for Personal Information</h2>
      <p>
        Sanken Sangyo Co., Ltd. <br />
        3-1-2 Tomonishi, Asaminami-ku, Hiroshima, 731-3169, Japan<br />
        Telephone +61-82-849-6790<br />
        Facsimile +61-82-849-6890<br />
      </p>
    </div>

  </div>
</section>

<app-footer lang="en"></app-footer>
