<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>กิจกรรมส่งเสริมสิ่งแวดล้อม</h3>
          </div>
          <!-- <p>
            Introduction of our environmental activities.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="COMP" menuIndex="7"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>กิจกรรมส่งเสริมสิ่งแวดล้อม</h3>
            <br />

            <h4>Eco Action 21 Certification</h4>
            <br />
            <div class="col-md-4 text-left">
              <img src="img/environment/ea21.gif" alt="" />
            </div>
            <p>เนื่องจากบริษัทมีเป้าหมายในเรื่องการดำเนินกิจกรรมทางธุรกิจควบคู่ไปกับการอนุรักษ์สภาพแวดล้อมของโลก
              ทางบริษัทจึงเลือกดำเนินการตาม "Eco Action 21” ซึ่งเป็น
              ระบบการจัดการสิ่งแวดล้อมตามแนวทางที่กระทรวงสิ่งแวดล้อมได้พัฒนาขึ้นโดย เริ่มดำเนินการครั้งแรกในเดือนมีนาคม
              2549 ที่สำนักงานใหญ่ จากนั้นในเดือนมีนาคม 2012 บริษัทในเครือ โรงงานและสำนักงานสาขาทั้งหมด
              ก็ได้รับการรับรองดังกล่าวทั้งหมด
              <br/>
              การดำเนินการเพื่อให้ได้การรับรอง "Eco Action 21” ถือเป็นจุดเริ่มต้น
              ที่บริษัทแสดงจุดยืนในการอนุรักษ์สภาพแวดล้อมของโลก ควบคู่ไปกับพัฒนาความเป็นอยู่และสังคมของมนุษย์
              <br />
              <br />
            </p>
            <a href="https://www.sanken-sangyo.co.jp/en/company/ea21/images/report2011.pdf" target="_blank">2011 Edition
              Environmental Activity (312KB/PDF)</a>
            <hr />
          </div>

          <div class="blog-post-style-one">
           
            <div class="comments-area">
              <div class="single-comment" *ngFor="let item of outline">
                <div class="col-md-5 text-box">
                  <h4>{{item.name}} : </h4>
                </div>
                <div class="text-box">
                  <p>{{item.description}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>