<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ประวัติความเป็นมา</h3>
          </div>
          <!-- <p>
            Introduction of our history.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="COMP" menuIndex="4"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>ประวัติความเป็นมา</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of outline">
                <div class="col-md-3 text-box">
                  <h4>{{item.name}}</h4>
                </div>
                <div class="text-box angular-with-newlines">
                  <p>{{item.description}}</p>
                </div>
              </div>

            </div>
          </div>

          <div class="blog-post-style-one">
            <div class="col-md-12 text-center">
              <div class="col-md-6 ">
                <img src="img/history/history01.jpg" alt="" />
                <br />
                (2008)
              </div>
              <div class="col-md-6">
                <img src="img/history/history02.jpg" alt="" />
                <br />
                (2011)
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>
