<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Products (Steel Areas)</h3>
          </div>
          <p>
            Introduction of our proud furnaces for reheating, heat treatment and zinc galvanizing for steel industry.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="2"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Large Steel Ingot Reheating Furnace for Free Forging</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_01.jpg" alt="" />
            </div>
            <p>Our car bottom batch reheating furnaces for free forging industry minimize heat losses by cooling
              waterless design and mechanical seal design sealing openings closely, and are energy-saving furnaces
              excellent in uniform heating performance using our patented roof-mounted multi-port regenerative burner
              technology. Also, they have realized both electric power-saving and low NOx emission, applying our own
              fuel-air ratio control technology per each individual burner. We have a lot of references of large
              reheating furnaces, including the world's largest one for 650 tons steel ingot.
              <br />
            </p>
            <hr />
          </div>
		  <br><br>
          <div class="blog-post-style-one">
            <h3>Vertical Split Heat Treatment Furnace</h3>
            <br />
            <div class="col-md-12">
              <div class="col-md-4 text-center shift-bottom">
                <img src="img/steel/steel_03.jpg" alt="" />
              </div>
              <div class="col-md-4 text-center">
                <img src="img/steel/steel_04.jpg" alt="" />
              </div>
              <div class="col-md-4 text-center">
                <img src="img/steel/steel_12.jpg" alt="" />
              </div>
            </div>
            <p>Our unique split heat treatment furnaces have been developed rethinking furnace construction, seal
              mechanism, electric heater and temperature control system from a fundamental point of view so that both of
              the feature of vertical furnace and the benefit of split design can exist together. We have many
              references, ranging from small to large ones, in Japan and overseas countries. One of the benefits of
              sprit design is to move work-piece horizontally, which requires no conventional deep pit and high-lift
              overhead crane, thus safety operation also can be enhanced.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Furnace Body Traveling Heat Treatment Furnace</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_05.jpg" alt="" />
            </div>
            <p>Car bottom design has been traditionally used for large batch heat treatment furnaces. We have developed
              furnace body traveling heat treatment furnace by changing our way of thinking: not car bottom but furnace
              body to travel. This design can reduce costs of foundation and car bottom repair, and can produce a lot of
              benefits such as improved workability and safety during loading/unloading work-pieces, enhanced product
              yield by reducing deformation of work-pieces during heat treating, etc. Also, our batch heat treatment
              furnaces are equipped with not only self-recuperative burners, but also regenerative burners which have
              been said to be difficult to apply in medium or low temperature heart treatment furnaces however recently
              have been put into practical use by our newly developed combustion control technology, achieving
              substantial energy-saving effects.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Coiling Furnace</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_06.jpg" alt="" />
            </div>
            <p>Coiling furnace is thermal equipment used to coil hot stainless steel sheet at high velocities, heat and
              hot it during rolling in Steckel mill. s, heat and hot. This is one of our unique products. Our coiling
              furnaces include waste heat recovery system by recuperator and low NOx emission burners as standard.
              <br />
              <br />
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Non-oxidation Reheating Furnace for Rolling Special Steel</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_07.jpg" alt="" />
            </div>
            <p>These non-oxidation continuous furnaces are used to reheat special alloy steel such as nickel alloy prior
              to rolling and application of our newly developed special hearth roller allows us to heat up to atmosphere
              temperature of 1,300℃ for the material dimensioned up to 1,350 mm wide, 200 mm thick and 12,000 mm long.
              <br />
              <br />
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Continuous Reheating Furnace</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_08.jpg" alt="" />
            </div>
            <p>We have delivered various kinds of continuous reheating furnaces such as walking beam, waking hearth,
              rotary hearth and so on in the steel/non-ferrous industries. As for large walking beam furnace, we have
              technical collaboration with Chugai Ro Co., Ltd., Japan.
		      <br>
              Recently, application of regenerative burner has mass appeal, in addition to this, we incorporate state of
              the art control systems including our own fuel-air ratio control technology per each individual burner,
              and have high reputation from our customers for energy saving as well as improved productivity.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Heating Furnace for Hot Pressing</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/steel/steel_09.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_10.jpg" alt="" />
              </div>
            </div>
            <p>In order to design a specific heating furnace meeting with customer's own product characteristic, we
              examine the best suitable heating conditions on temperature and time required with our test furnace,
              according to customer's various requirements such as shapes and thickness of material, with or without
              galvanized, thickness of galvanized layer, etc.
			  <br>
              For the furnace style, we principally apply a roller hearth design, but we can offer in walking beam or
              heat-proof conveyor designs. For the heat source, electric heating design or gas-fired design lowering
              oxygen level in the furnace under the low fuel-air ratio combustion is available.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Ceramic Bath – Zinc Galvanizing Furnace</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_11.jpg" alt="" />
            </div>
            <p>We are proud of the top market share of zinc galvanizing furnaces in continuous ones for hard steel wire
              such as wire rope and soft steel wire such as fence as well as batch ones for large steel structure such
              as bridge. For the continuous galvanizing line, we have newly developed fully-electrified zinc galvanizing
              facility as well as highly-efficient and fume-shield acid pickling tank. A substantial energy-saving
              version, using a combination of self-recuperative burner (Germany-made REKUMAT burner series) and
              immersion ceramic tube which heats molten zinc from within, is also available.
			  <br>
              We make a proposal of a ceramic bath design which can cope with Zn-Al galvanizing. Ceramic baths do not
              require periodic replacement unlike conventional steel kettles, which can reduce maintenance costs and
              zinc metal losses generating from replacement of the kettles, as well as, dramatically reduce the risks of
              sudden production shutdown and opportunity losses caused by metal run-out accident.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Continuous Steel Pipe Heat Treatment Furnace</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/steel/steel_13.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_14.jpg" alt="" />
              </div>
            </div>
            <p>Our roller hearth steel pipe heat treatment furnaces are equipped with our newly developed side burners
              with radiation tube, and our suitable arrangement of the said burner system improves temperature
              uniformity of the cross direction of the furnace and facilitate set up longitudinal heating patterns
              within the furnace. Also, we design freely each length for heating, soaking and cooling zones, according
              to the customer's needs of heat treatment for elongated pipes. Also, we protect pipes from scratching in
              the furnace by our own pipe guide mechanism.
			  <br>
              In order to be energy-saving design, EBC (easy burner control) system that can combust gas with preheated
              combustion air through a recuperator at proper air-fuel ratio at any turn-down ratio is applied.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Roll Rapid Differential Heating Furnace and Heat Treatment Furnace</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/steel/steel_15.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_16.jpg" alt="" />
              </div>
            </div>
            <p>These are dedicated furnaces for heat treatment of mill rolls which are used for hot rolling process.
              Rotating equipment of roller and heating length of in-furnace can be remotely set up, according to
              diameter and length of the roll to be treated.
			  <br>
              Furnace body is designed to be split to accept a roll and a lot of radiant cup burners installed in the
              furnace walls can heat body surface of the roll rapidly and uniformly.
              <br />
              <br />
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>