<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<app-carousel lang="en"></app-carousel>

<app-article lang="en"></app-article>

<app-introduction lang="en"></app-introduction>

<app-footer lang="en"></app-footer>
