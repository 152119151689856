<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>การซ่อมบำรุง</h3>
          </div>
          <p>
            การบริการทางด้านการซ่อมบำรุงของเรา ครอบคลุมเตาหลอมอลูมิเนียม (aluminium melting furnaces), เตาชุบอลูมิเนียม
            (aluminium heat treatment furnaces), เตาหลอมเหล็ก (steel reheating furnaces), เตาชุบเหล็ก (steel heat
            treatment furnaces) และเตาชุบสังกะสี (zinc galvanizing furnaces) เป็นต้น
            โดยมีพนักงานที่มีความชำนาญสูงคอยให้บริการ ซึ่งจะช่วยสนับสนุนการดำเนินงานของลูกค้าได้อย่างเต็มประสิทธิภาพ
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="7"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>ฐานการซ่อมบำรุง</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <img src="img/maintenance/mainte_01.gif" alt="" />
            </div>
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_02.jpg" alt="" />
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_03.jpg" alt="" />
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_04.jpg" alt="" />
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_05.jpg" alt="" />
              </div>
            </div>
          </div>

          <div class="blog-post-style-one">
            <p>เรามีพนักงานที่มีความชำนาญสูงและพร้อมให้บริการอย่างรวดเร็ว จากฐานซ่อมบำรุงภายในประเทศญี่ปุ่น 6 แห่ง
              และต่างประเทศ 3 แห่ง
            </p>
            <hr />
          </div>


          <div class="blog-post-style-one">
            <h3>Maintenance activities</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_06.jpg" alt="" />
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_07.jpg" alt="" />
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_08.jpg" alt="" />
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_09.jpg" alt="" />
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_10.jpg" alt="" />
              </div>
            </div>
          </div>


          <div class="blog-post-style-one">
            <h3>ความปลอดภัยด้านซ่อมบำรุง</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/maintenance/mainte_11.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/maintenance/mainte_12.jpg" alt="" />
              </div>
            </div>
            <p>บริษัทมีการดำเนินงานตามข้อปฏิบัติ ข้อกฏหมายและกฎระเบียบข้อบังคับต่างๆ
              ที่เกี่ยวข้องกับความปลอดภัยในการทำงานอย่างเคร่งครัด นอกจากนี้ยังมีการดูแลและจัดการทางด้านความปลอดภัยอื่นๆ
              ภายในโรงงานเพิ่มเติม
              เพื่อป้องกันอุบัติเหตุที่อาจเกิดขึ้นในการปฏิบัตงานและเพิ่มระดับความรักษาความปลอดภัยในการทำงาน
              รวมถึงมีการสนับสนุนกิจกรรมเพื่อช่วยส่งเสริมสุขภาพและความปลอดภัยของพนักงานอีกด้วย เช่น KY
              (การคาดการณ์อันตรายต่างๆที่อาจจะเกิดขึ้น), การประชุมเพื่อพูดคุยและชี้แจงเกี่ยวกับความปลอดภัยในการทำงาน,
              tool box meeting (TBM) , การตรวจสอบและตรวจตราภายในโรงงาน เป็นต้น
              พร้อมกันนี้ยังมีการจัดการประชุมด้านความปลอดภัยร่วมกับผู้รับเหมา
              เพื่อให้เรียนรู้และตระหนักถึงความปลอดภัยในการทำงานไปพร้อมๆกัน
            </p>
          </div>

          <div class="blog-post-style-one">
            <h3>การฝึกอบรมพนักงาน</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/maintenance/mainte_13.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/maintenance/mainte_14.jpg" alt="" />
              </div>
            </div>
            <p>ปัจจุบัน นอกจากเราจะมีพนักงานซ่อมบำรุงที่คอยดูแลรักษาตลอด 24 ชั่วโมงแล้ว
              เรายังมุ่งมั่นฝึกอบรมพนักงานอย่างต่อเนื่องเพื่อให้พนักงานทุกคนมีความรู้ความเข้าใจและสามารถปฏิบัติงานได้ด้วยความชำนาญ
              เพื่อสนับสนุนธุรกิจของลูกค้าได้อย่างเต็มประสิทธิภาพ
            </p>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>