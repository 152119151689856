<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Term of Use</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>Operation of the site</h2>
      <p>This description applies to the Website opened and operated by Sanken Sangyo Co., Ltd. or its representative
        (hereinafter "SANKEN") (hereinafter "the Website").</p>
    </div>

    <div class="title">
      <h2>Copyright and Trademark Right</h2>
      <p>The copyright to the information accessible from the Website is possessed by SANKEN affiliated companies and
        protected under the copyright laws of the relevant countries, treaties and any other applicable laws and
        regulations. It is prohibited to use for any private use by an individual, or to otherwise use (or copy, modify
        or distribute) the information on the Website beyond the extent that is authorized by the copyright laws without
        obtaining a prior approval by SANKEN.
      </p>
      <p>
        The trademarks, logos and service marks used on the Website are the properties of SANKEN affiliated companies.
        No license and right to use such trademarks, logos and service marks are granted to any customer of SANKEN. It
        is prohibited to use, divert and reproduce such trademarks, logos and service marks without SANKEN's approval.
      </p>
    </div>

    <div class="title">
      <h2>Prohibitions</h2>
      <p>
        The following actions in accessing to the information on the Website are prohibited.
      </p>
      <ul class="checklist">
        <li>Infringing or threatening to infringe SANKEN's or any third part's own properties or privacy.</li>
        <li>Giving or threatening to give any loss or damage to any third party or SANKEN.</li>
        <li>Acting or threatening to act against public order and morals. </li>
        <li>Committing or threatening to commit a crime or any act leading to a crime.</li>
        <li>Making any false application or notice including registration of the electronic mail address of any other
          person.</li>
        <li>Making or preparing to make any business activity or profit-making activity.</li>
        <li>Injuring the honor or credit of SANKEN or any third party.</li>
        <li>Using or providing, or threatening to use or provide any harmful software program including computer
          virus.</li>
        <li>Violating or threatening to violate any laws ordinances and regulations.</li>
        <li>Other acts as SANKEN determines to be inappropriate.</li>
      </ul>
    </div>

    <div class="title">
      <h2>How to Handle Personal Information on the Website</h2>
      <p>
        We will not disclose personal information or sell/rent any items on this web site.
        However, the personal information may be disclosed in the following cases.
      </p>
      <ul class="checklist">
        <li>In case that the customer agreed the disclosure or sharing of the information.</li>
        <li>In case that public agency such as a court and police department requested us to disclose the information to
          them legally.</li>
        <li>In case that the customer's action on the web site violates the agreement, and disclosure is judged to be
          necessary to protect our right, properties, services, etc. </li>
      </ul>
    </div>

    <div class="title">
      <h2>Link Sites</h2>
      <p>
        Any Website of any third party other than SANKEN that is linked from or to the Website (hereinafter "Link
        Sites") is operated by such third party on such third party's own responsibility, but not by SANKEN. Such Link
        Site should be accessed and used under the use conditions specified for it. SANKEN has no responsibility for any
        damages arising from or in connection with the access to or use of such Link Site.
      </p>
      <p>
        In linking from the external to the information (including documents and images) on the Website, the user,
        whether a business concern or an individual, is not required to make any prior application for such linking,
        provided, however, that such user is required to agree to these requirements for use of the Website and the
        following "linking conditions"
      </p>
      <ul class="checklist">
        <li>SANKEN has no responsibility for any damages arising from or in connection with linking with the Website.
        </li>
        <li>It is not promised that the information or URL on the Website will be maintained permanently, but it may be
          subject to change without notice in principle.</li>
        <li>It is absolutely prohibited to link such a Website with the Website that may lead to infringement of the
          property rights or any other rights possessed by SANKEN or interference with SANKEN's business activities.
        </li>
        <li>
          It is absolutely prohibited to link from any Website or mail containing the following information to the
          Website:
          <ul class="checklist">
            <li >Information against public order and morals.</li>
            <li >Illegal information.</li>
            <li >Pornographic contents. and.</li>
            <li >Information to abuse or defame SANKEN or its affiliated companies.</li>
          </ul>
        </li>
        <li>Linking with the Website does not represent or imply any contractual relationship between SANKEN and such
          linking party.</li>
      </ul>
    </div>

    <div class="title">
      <h2>Governing Law and Jurisdiction</h2>
      <p>
        Unless otherwise provided herein, the access to and the use of the Website, and the application and construction
        of these requirements for use shall be governed by the laws of Japan.
      </p>
      <p>
        Unless otherwise provided herein, any dispute arising from or in connection with the access to and use of the
        Website shall finally be settled at the Hiroshima District Court as the competent jurisdictional court.
      </p>
    </div>

    <div class="title">
      <h2>Recommended System Requirements for Browser</h2>
      <p>
        For best results when using the Website, it is recommended that you observe the system requirements listed
        below. Viewing the Website on a system that falls below the minimum requirements, or depending on the browser
        settings on a system that meets the requirements, the Website may not be usable, or may not display correctly.
      </p>
    </div>

    <div class="title">
      <h2>Windows</h2>
      <ul class="checklist">
        <li>Internet Explorer 6 or higher</li>
        <li>Mozilla Firefox 3 or higher</li>
        <li>Macintosh Safari 3 or higher</li>
      </ul>
    </div>

    <div class="title">
      <h2>Download the latest browser is here</h2>
      <ul class="checklist">
        <li><a href="http://www.microsoft.com/japan/windows/ie/default.mspx" title="A new window will open"
            target="_blank" class="styleArrow">Download the latest browser Microsoft Internet Explorer</a></li>
        <li>
          <a href="http://mozilla.jp/firefox/" title="A new window will open" target="_blank"
            class="styleArrow">Download the latest browser Mozilla Firefox</a>
        </li>
        <li>
          <a href="http://www.apple.com/jp/safari/" title="A new window will open" target="_blank"
            class="styleArrow">Download the latest browser Safari</a>
        </li>
      </ul>
    </div>

  </div>
</section>

<app-footer lang="en"></app-footer>
