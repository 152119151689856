<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Maintenance</h3>
          </div>
          <p>
            Introduction of maintenance activities including safety training.
          </p>
          <p>
            Our maintenance activities cover aluminium melting furnaces, aluminium heat treatment furnaces, steel
            reheating furnaces, steel heat treatment furnaces, zinc galvanizing furnaces etc. amounting approximately
            700 cases a year, which can contribute to stable production of our customers.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="7"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Maintenance Bases</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <img src="img/maintenance/mainte_01.gif" alt="" />
			  
            </div>
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_02.jpg" alt="" />
				<p>Sanken Sangyo Co., Ltd.</p>
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_03.jpg" alt="" />
				<p>NEU-Sanken (China)</p>
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_04.jpg" alt="" />
				<p>Sanken Insertec Europe (Spain)</p>
              </div>
              <div class="col-md-3 text-center">
                <img src="img/maintenance/mainte_05.jpg" alt="" />
				<p>PT. SWIF ASIA (Indonesia)</p>
              </div>
            </div>
          </div>

          <div class="blog-post-style-one">
            <p>We will respond quickly and flexibly providing experienced and skilled staff from 6 domestic maintenance
              bases along with 3 overseas group companies.
            </p>
            <hr />
          </div>


          <div class="blog-post-style-one">
            <h3>Maintenance activities</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_06.jpg" alt="" />
				<p>Exhaust gases analysis</p>
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_07.jpg" alt="" />
				<p>Diagnosis of furnace insulation</p>
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_08.jpg" alt="" />
				<p>Adjustment of combustion</p>
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_09.jpg" alt="" />
				<p>Refractory installation</p>
              </div>
              <div class="col-md-2 text-center">
                <img src="img/maintenance/mainte_10.jpg" alt="" />
				<p>Oxide removal</p>
              </div>
            </div>
          </div>


          <div class="blog-post-style-one">
            <h3>Safety activities</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/maintenance/mainte_11.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/maintenance/mainte_12.jpg" alt="" />
              </div>
            </div>
            <p>We will respond quickly and flexibly providing experienced and skilled staff from 6 domestic maintenance
              bases along with 3 overseas group companies.
            </p>
          </div>

          <div class="blog-post-style-one">
            <h3>Training activities</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/maintenance/mainte_13.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/maintenance/mainte_14.jpg" alt="" />
              </div>
            </div>
            <p>In addition to full-time maintenance staff, we are permanently committing to employees training so that
              all company staff can perform maintenance work.
            </p>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>