<div id="minimal-bootstrap-carousel" class="carousel slide carousel-fade slider-home-two thm-slider-wrapper"
  data-ride="carousel">
  <!-- Wrapper for slides -->
  <div class="carousel-inner" role="listbox">

    <div class="item active slide-1" style="background-image: url(img/key_image_top.jpg);background-position: center;">
      <!-- <div class="carousel-caption">
        <div class="thm-container">
          <div class="box valign-bottom text-right">
            <div class="content text-left">
              <div class="banner-home-two-box" data-animation="animated fadeInRightBannerCustom">
                <h2 class="banner-home-two-title">Providing Best <br />Craftman Service</h2>
                <p class="banner-home-two-p">Craftio is one of the world’s top carpenter <br /> work firms. We help
                  global leaders with their <br /> organization's most critical issues and op- <br /> portunities.</p>
                <a href="#" class="banner-home-two-btn">Our services</a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

  </div>
  <!-- Controls -->
  <!-- <a class="left carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="prev">
    <i class="fa fa-angle-left"></i>
    <span class="sr-only">Previous</span>
  </a>
  <a class="right carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="next">
    <i class="fa fa-angle-right"></i>
    <span class="sr-only">Next</span>
  </a> -->
</div>