<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>กลุ่มบริษัทและหุ้นส่วนทางการค้า</h3>
          </div>
          <!-- <p>
            Introduction of our group-company and collaborator.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="COMP" menuIndex="6"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>กลุ่มบริษัทและหุ้นส่วนทางการค้า</h3>
          </div>

          <div class="blog-post-style-one">
            <div class="text-center">
              <img src="img/partner/group01.gif" alt="" />
            </div>
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>กลุ่มบริษัท</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of groupCompany">
                <div class="col-md-3 text-box">
                  <h4>
                    <img src="{{item.image}}" alt="" />
                  </h4>
                </div>
                <div class="text-box angular-with-newlines">
                  <p>{{item.companyName}}</p>
                  <p>{{item.address}}</p>
                  <p>{{item.phone}}</p>
                  <p>{{item.facsimile}}</p>
                  <p>{{item.description}}</p>
                  <p><a href="{{item.url}}">{{item.url}}</a></p>
                </div>
              </div>

            </div>
          </div>

          <div class="blog-post-style-one">
            <h3>หุ้นส่วนทางการค้า</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of partner">
                <div class="col-md-3 text-box">
                  <h4>
                    <img src="{{item.image}}" alt="" />
                  </h4>
                </div>
                <div class="text-box angular-with-newlines">
                  <p>{{item.companyName}}</p>
                  <p>{{item.address}}</p>
                  <p>{{item.phone}}</p>
                  <p>{{item.facsimile}}</p>
                  <p>{{item.description}}</p>
                  <p><a href="{{item.url}}">{{item.url}}</a></p>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>