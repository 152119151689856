import { Component, OnInit } from '@angular/core';
import { Research } from 'src/app/model/Research';

@Component({
  selector: 'app-th-oversea',
  templateUrl: './oversea.th.component.html',
  styleUrls: ['./oversea.th.component.css']
})
export class OverseaThComponent implements OnInit {

  asia: Research[];
  northAmerica: Research[];
  europe: Research[];

  constructor() { }

  ngOnInit() {

    this.asia = [{
      country: "จีน",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาหลอมเศษอลูมิเนียม, เตาหลอมอะลูมิเนียมแบบ Combination, เตา Dosing, กระบวนการแปรสภาพอลูมิเนียมก่อนหลอม ,เตาเผาแบบ Roller รุ่นT6,เตาเผาแบบ Walking beam รุ่น T6, เตาเผาอลูมิเนียมแบบ billet , เตาเผาเพื่ออัดความร้อน, เตาเผาเหล็กแบบ Batch ขนากใหญ่,ระบบอุ่นเบ้ารับน้ำโลหะ , กระบวนการชุบสังกะสี, เตาม้วน"
    }, {
      country: "เกาหลี",
      description: "เตาอบอะลูมิเนียมแบบ Tower,  กระบวนการแปรสภาพอลูมิเนียมก่อนหลอม ,เตาเผาแบบ Roller รุ่นT6,เตาเผาแบบ Batch รุ่นT6, เตา Rotary , เตาอุ่นแบบเหล็กแท่งแบบ Pusher, เตาเผาแบบ Batch สำหรับขึ้นรูปขนาดใหญ่, เตาเผาแบบ Car bottom, ระบบอุ่นอ่างรับน้ำโลหะ, เตาชุบท่อเหล็กสังกะสี"
    }, {
      country: "ได้หวัน",
      description: "เตาอบอะลูมิเนียมแบบ Tower, เตาเผารุ่นT6 , เตาอุ่นแบบเหล็กแท่งแบบ Pusher, เตา Rotary สำหรับเหล็ก , เตาชุบท่อเหล็กสังกะสี, เตาชุบสังกะสี, ระบบอุ่นอ่างรับน้ำโลหะ, เตาเผาแบบ Car bottom ,ระบบการแปรสภาพอลูมิเนียมก่อนหลอม"
    }, {
      country: "อินโดนีเซีย",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาหลอมเศษอลูมิเนียม, เตาหลอมอะลูมิเนียมแบบ Combination, เตา dosing,ระบบการแปรสภาพอลูมิเนียมก่อนหลอม, เตาเผาแบบ Roller รุ่นT2 T5 และ T6, ระบบระบายความร้อน, เตาเผาแบบ Walking beam รุ่น T6, เตาชุบเย็น"
    }, {
      country: "ไทย",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาหลอมเศษอลูมิเนียม, เตาหลอมอะลูมิเนียมแบบ Combination, เตาหลอมอลูมิเนียมแบบต่อเนื่อง, เตาให้ความร้อนแบบต่อเนื่อง, ระบบการแปรสภาพอลูมิเนียมก่อนหลอม"
    }, {
      country: "มาเลเซีย",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาอุ่นเหล็กแท่งแบบ Pusher, เตาหลอมอลูมิเนียมแบบต่อเนื่อง"
    }, {
      country: "เวียดนาม",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาอุ่นแบบเหล็กแท่งแบบ Pusher"
    }, {
      country: "ฟิลิปปินส์",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาอุ่นแบบเหล็กแท่งแบบ Pusher ,เตาเผาแบบ Roller hearth สำหรับขึ้นรูป, เตาชุบสังกะสี"
    }, {
      country: "เมียนม่า",
      description: "เตาอุ่นเหล็กแท่งแบบ Pusher,ระบบอุ่นเบ้ารับน้ำโลหะ "
    }, {
      country: "มองโกเลีย",
      description: "เตาอุ่นเหล็กแท่งแบบ Pusher"
    }, {
      country: "อินเดีย",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาแบบ dosing, เตาเผาแบบ Car bottom"
    }, {
      country: "ออสเตรเลีย",
      description: "เตาหลอมและแปรสภาพเศษอลูมิเนียม"
    }];

    this.northAmerica = [{
      country: "สหรัฐอเมริกา",
      description: "เตาเผาอลูมิเนียมแบบต่อเนื่อง, เตาเผารุ่นT5, เตาหลอมอะลูมิเนียมแบบ Tower, เตาเผาไฟฟ้าขนาดใหญ่แบบตั้ง"
    }, {
      country: "เม็กซิโก",
      description: "เตาอบอะลูมิเนียมแบบ Tower, เตา Dosing, เตาอุ่นแบบCar bottom , เตาเผาแบบCar bottom,เตาเผาแบบแกนหมุน, เตาทำความเย็น,เตาเผาแบบแนวตั้งและใช้พลังงานก๊าซ, เตาไฟฟ้าเพื่อวัดความเค้น"
    }, {
      country: "บราซิล",
      description: "เตาเผาเหล็กแท่งแบบ Pusher"
    }];

    this.europe = [{
      country: "อังกฤษ",
      description: "เตาหลอมอะลูมิเนียมแบบ Tower, เตาเผารุ่นT6"
    }, {
      country: "สเปน",
      description: "เตาหลอมอะลูมิเนียม"
    }, {
      country: "บัลแกเรีย",
      description: "เตาอุ่นโลหะแบบ Car bottom เพื่อขึ้นรูป,  เตาหลอมแบบ Car bottom,  เตาเผาแบบ Car bottom, เตาเผาแบบแกนหมุน,เตาเผาแบบแนวตั้งและใช้พลังงานก๊าซ, เตาอบแห้ง,ระบบอุ่นเบ้ารับน้ำโลหะ "
    }, {
      country: "ตุรกี",
      description: "เตาอุ่นเหล็กแท่งแบบ Pusher, เตาแผาแบบ Roller"
    }, {
      country: "อิหร่าน",
      description: "เตาหลอมโละหะผสมทองแดง"
    }, {
      country: "แอลจีเรีย",
      description: "เตาอุ่นโลหะแบบ Car bottom เพื่อขึ้นรูป, เตาอุ่นแบบ Batch, เตาหลอมแบบ Car bottom, เตาอุ่นก่อนหลอม แบบ Car bottom,  เตาอบแห้ง"
    }, {
      country: "อียิปต์",
      description: "เตาอุ่นสำหรับขึ้นรูปเหล็ก, เตาอบแห้ง"
    }, {
      country: "ไนจีเรีย",
      description: "เตาอุ่นเหล็กแท่งแบบ Pusher, เตาอุ่นสำหรับขึ้นรูปเหล็ก"
    }];

  }

}
