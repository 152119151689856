import { Component, OnInit, Input } from '@angular/core';
import { MenuInfo } from 'src/app/model/Menu';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  @Input() lang: string;
  menu: MenuInfo[];
  header: string;
  readmore: string;
  toggle: boolean;

  constructor() { }

  ngOnInit() {

    this.toggle = (this.lang == 'en')

    this.menu = (this.lang == 'en')
      ? this.getEngMenuDetail()
      : this.getThaiMenuDetail();
  }

  getEngMenuDetail() {
    this.header = 'Our Business'
    this.readmore = 'Read more'
    return [
      {
        icon: 'img/home/index_image02.jpg',
        name: 'Our Only-One',
        description: 'Our own original product supporting industry.',
        link: '/en/home'
      }, {
        icon: 'img/home/index_image03.jpg',
        name: 'Products (Aluminium Areas)',
        description: 'Our furnaces for aluminium industry.',
        link: '/en/aluminium'
      }, {
        icon: 'img/home/index_image04.jpg',
        name: 'Products (Steel Areas)',
        description: 'Our furnaces for steel industry.',
        link: '/en/steel'
      }, {
        icon: 'img/home/index_image05.jpg',
        name: 'Research & Development',
        description: 'Our present and past research & development.  ',
        link: '/en/rd'
      }, {
        icon: 'img/home/index_image06.jpg',
        name: 'Overseas References',
        description: 'Our references for overseas countries. ',
        link: '/en/oversea'
      }, {
        icon: 'img/home/index_image07.jpg',
        name: 'Energy Saving',
        description: 'Energy-saving technologies we have worked on.',
        link: '/en/energy'
      }, {
        icon: 'img/home/index_image08.jpg',
        name: 'Proposal of Renovation',
        description: 'Innovation which we have worked on in energy saving.',
        link: '/en/proposal'
      }, {
        icon: 'img/home/index_image09.jpg',
        name: 'Maintenance',
        description: 'Maintenance activities including safety training.',
        link: '/en/maintenance'
      }
    ]
  }

  getThaiMenuDetail() {
    this.header = 'แนะนำธุรกิจ'
    this.readmore = 'อ่านเพิ่มเติม'
    return [
      {
        icon: 'img/home/index_image02.jpg',
        name: 'จุดเด่นของเรา',
        description: 'แนะนำผลิตภัณฑ์ที่เป็นจุดเด่นของเรา',
        link: '/th/onlyone'
      }, {
        icon: 'img/home/index_image03.jpg',
        name: 'ผลิตภัณฑ์(กลุ่มอลูมิเนียม)',
        description: 'แนะนำผลิตภัณฑ์ในอุตสาหกรรมอลูมินียม',
        link: '/th/aluminium'
      }, {
        icon: 'img/home/index_image04.jpg',
        name: 'ผลิตภัณฑ์(กลุ่มเหล็ก)',
        description: 'แนะนำผลิตภัณฑ์ในอุตสาหกรรมเหล็ก',
        link: '/th/steel'
      }, {
        icon: 'img/home/index_image05.jpg',
        name: 'การพัฒนาและค้นคว้าวิจัย',
        description: 'การค้นคว้าวิจัยและพัฒนาตั้งแต่อดีตจนถึงปัจจุบัน',
        link: '/th/rd'
      }, {
        icon: 'img/home/index_image06.jpg',
        name: 'ลูกค้าและผลงาน',
        description: 'กลุ่มลูกค้าจากทั่วโลกที่ใช้ผลิตภัณฑ์และบริการของเรา',
        link: '/th/oversea'
      }, {
        icon: 'img/home/index_image07.jpg',
        name: 'การประหยัดพลังงาน',
        description: 'เทคโนโลยีที่นำมาใช้เพื่อช่วยในการประหยัดพลังงาน',
        link: '/th/thergy'
      }, {
        icon: 'img/home/index_image08.jpg',
        name: 'นวัตกรรมและเทคโนโลยี',
        description: 'นวัตกรรมและเทคโนโลยีที่นำมาใช้ในการสร้างผลิตภัณฑ์',
        link: '/th/proposal'
      }, {
        icon: 'img/home/index_image09.jpg',
        name: 'การซ่อมบำรุง',
        description: 'บริการซ่อมบำรุงและอบรมพนักงาน',
        link: '/th/maintenance'
      }
    ]
  }

}
