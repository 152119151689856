<section class="about-section">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-8">
        <div class="about-content">
          <div class="title">
            <h2>{{header}}</h2>
          </div><!-- /.title -->
          <p>{{introduction}}
          </p>
          <hr />
          <p>
            {{content}}
          </p>
          <br />
          <!--<a [routerLink]="['/en/whatsanken']" class="view-all">view Sanken</a>-->
        </div><!-- /.about-content -->
      </div><!-- /.col-md-8 -->
      <div class="col-md-4">
        <img src="img/icorp02.gif" class="resize-image" alt="Awesome Image" />
      </div><!-- /.col-md-4 -->
    </div><!-- /.row -->
  </div><!-- /.thm-container -->
</section><!-- /.about-section -->