<header class="header home-page-two">
  <nav class="navbar navbar-default header-navigation stricky">
    <div class="thm-container clearfix">

      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".main-navigation"
          aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse main-navigation" id="main-nav-bar">

        <div *ngIf="toggle">
          <ul class="nav navbar-nav navigation-box">
            <li><a [routerLink]="['/en/home']">Home</a></li>
            <li>
              <a [routerLink]="['/en/introbiz']">Products & Services</a>
              <ul class="sub-menu">
                <li><a [routerLink]="['/en/onlyone']">Our Only-One</a></li>
                <li><a [routerLink]="['/en/aluminium']">Products (Aluminium Areas)</a></li>
                <li><a [routerLink]="['/en/steel']">Products (Steel Areas)</a></li>
                <li><a [routerLink]="['/en/rd']">Research & Development</a></li>
                <li><a [routerLink]="['/en/oversea']">Overseas References</a></li>
                <li><a [routerLink]="['/en/energy']">Energy Saving</a></li>
                <li><a [routerLink]="['/en/proposal']">Proposal of Renovation</a></li>
                <li><a [routerLink]="['/en/maintenance']">Maintenance</a></li>
              </ul>
            </li>
            <li>
              <a [routerLink]="['/en/compinfo']">About Us</a>
              <ul class="sub-menu">
                <li><a [routerLink]="['/en/whatsanken']">What's sanken</a></li>
                <li><a [routerLink]="['/en/business']">Business Philosophy</a></li>
                <li><a [routerLink]="['/en/topmsg']">Top Message</a></li>
                <li><a [routerLink]="['/en/outline']">Outline of The Company</a></li>
                <li><a [routerLink]="['/en/history']">History</a></li>
                <li><a [routerLink]="['/en/bizlocate']">Business Locations</a></li>
                <li><a [routerLink]="['/en/partner']">Group Company / Partner</a></li>
                <li><a [routerLink]="['/en/environment']">Environmental Activities</a></li>
              </ul>
            </li>
            <li><a [routerLink]="['/en/news']">News</a></li>
            <li><a [routerLink]="['/en/contactus']">Contact Us</a></li>
          </ul>
        </div>

        <div *ngIf="!toggle">
          <ul class="nav navbar-nav navigation-box">
            <li><a [routerLink]="['/th/home']">หน้าแรก</a></li>
            <li>
              <a [routerLink]="['/th/introbiz']">สินค้าและบริการ</a>
              <ul class="sub-menu">
                <li><a [routerLink]="['/th/onlyone']">จุดเด่นของเรา</a></li>
                <li><a [routerLink]="['/th/aluminium']">ผลิตภัณฑ์(กลุ่มอลูมิเนียม)</a></li>
                <li><a [routerLink]="['/th/steel']">ผลิตภัณฑ์(กลุ่มเหล็ก)</a></li>
                <li><a [routerLink]="['/th/rd']">การพัฒนาและค้นคว้าวิจัย</a></li>
                <li><a [routerLink]="['/th/oversea']">ลูกค้าและผลงาน</a></li>
                <li><a [routerLink]="['/th/energy']">การประหยัดพลังงาน</a></li>
                <li><a [routerLink]="['/th/proposal']">นวัตกรรมและเทคโนโลยี</a></li>
                <li><a [routerLink]="['/th/maintenance']">การซ่อมบำรุง</a></li>
              </ul>
            </li>
            <li>
              <a [routerLink]="['/th/compinfo']">ข้อมูลบริษัท</a>
              <ul class="sub-menu">
                <li><a [routerLink]="['/th/whatsanken']">ธุรกิจหลักขององค์กร</a></li>
                <li><a [routerLink]="['/th/business']">แนวคิดการดำเนินธุรกิจ</a></li>
                <li><a [routerLink]="['/th/topmsg']">สาส์นจากผู้บริหาร</a></li>
                <li><a [routerLink]="['/th/outline']">ข้อมูลบริษัท</a></li>
                <li><a [routerLink]="['/th/history']">ประวัติความเป็นมา</a></li>
                <li><a [routerLink]="['/th/bizlocate']">ที่ตั้ง</a></li>
                <li><a [routerLink]="['/th/partner']">บริษัทในเครือ/หุ้นส่วนทางการค้า</a></li>
                <li><a [routerLink]="['/th/environment']">กิจกรรมเพื่อสิ่งแวดล้อม</a></li>
              </ul>
            </li>
            <li><a [routerLink]="['/th/news']">ข่าวสาร</a></li>
            <li><a [routerLink]="['/th/contactus']">ติดต่อเรา</a></li>
          </ul>
        </div>

      </div>

      <div class="right-side-box">
        <!-- <a class="search-toggle popup-with-zoom-anim" href="#test-search"><i class="icon icon-Search"></i></a> -->
        <div *ngIf="toggle">
          <a [routerLink]="['/th/home']" class="rqa-btn">Thai</a>
        </div>
        <div *ngIf="!toggle">
          <a [routerLink]="['/en/home']" class="rqa-btn">Eng</a>
        </div>
      </div>
    </div>

  </nav>
</header>