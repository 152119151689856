<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ลูกค้าและผลงาน</h3>
          </div>
          <!-- <p>
            Introduction of our references for overseas countries.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="4"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>ผลิตภัณฑ์ของเราได้รับความสนใจจากทั่วโลก (ข้อมูล ณ เดือนมีนาคม 2013)</h3>
            <br />
            <p>
              แต่ละสินค้าของเราถูกส่งไปหลายๆที่บนโลก
            </p>
            <br />
            <div class="text-center">
              <img src="img/oversea/results01.gif" alt="" />
            </div>
            <br />
            <p>
              ข้อมูลอ้างอิงสำคัญบางส่วนของเรามีดังนี้
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เอเชีย / โอเชียเนีย</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of asia">
                <div class="col-md-2 text-box">
                  <h4>{{item.country}}</h4>
                </div>
                <div class="text-box">
                  <p>{{item.description}}</p>
                </div>
              </div>

            </div>
          </div>

          <div class="blog-post-style-one">
            <h3>อเมริกาเหนือ / อเมริกาใต้</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of northAmerica">
                <div class="col-md-2 text-box">
                  <h4>{{item.country}}</h4>
                </div>
                <div class="text-box">
                  <p>{{item.description}}</p>
                </div>
              </div>

            </div>
          </div>

          <div class="blog-post-style-one">
            <h3>ยุโรป / แอฟริกา</h3>
            <div class="comments-area">

              <div class="single-comment" *ngFor="let item of europe">
                <div class="col-md-2 text-box">
                  <h4>{{item.country}}</h4>
                </div>
                <div class="text-box">
                  <p>{{item.description}}</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>