import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-th-topmsg',
  templateUrl: './topmsg.th.component.html',
  styleUrls: ['./topmsg.th.component.css']
})
export class TopmsgThComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
