import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.css']
})
export class IntroductionComponent implements OnInit {

  @Input() lang: string;
  header: string;
  introduction: string;
  content: string;

  constructor() { }

  ngOnInit() {

    if (this.lang == 'en') {
      this.getEngligh();
    } else {
      this.getThai();
    }

  }

  getEngligh() {

    this.header = 'From Hiroshima to around the world!'
    this.introduction = 'Sanken Sangyo Co., Ltd. is a company to do the plan, the design, the construction, and the maintenance of Industrial Furnace. Since 1949, we have made the industrial furnace.We have delivered products, technologies, and services that our customers satisfied in more than 30 countries as well as Japan.'
    this.content = "In order to produce our familiar metallic products or components, for example, aluminium motor wheels, melting process of stock and heat treatment process of castings are essential. Industrial furnace is essential equipment for such processes. Features of our industrial furnaces are not only excellent in energy-saving, scrap recycling and other advanced technologies, but also are contributing to the environmental conservation. Also, we have ability to propose a new perspective on customer's needs such as cost reduction as well as integrated ability to offer consistent services from initial planning to after - sales maintenance, in that way, we have been highly reputed by many customers until now."
  }

  getThai() {
    this.header = 'จากเมืองฮิโรชิมาสู่ทั่วโลก!'
    this.introduction = 'บริษัท ซังเค็นซังเกียว จำกัด เป็นบริษัทที่ดำเนินการวางแผน ออกแบบ ก่อสร้างและบำรุงรักษาเตาอุตสาหกรรม เราได้เริ่มทำธุรกิจเตาอุสาหกรรมตั้งแต่ปี พ.ศ.2492 จนถึงปัจจุบันเราได้มีการส่งมอบผลิตภัณฑ์ เทคโนโลยี และบริการที่เกี่ยวข้องกับเตาอุตสาหกรรม รวมถึงสร้างความพึงพอใจให้แก่ลูกค้าทั้งในประเทศญี่ปุ่น และอีกมากกว่า 30 ประเทศทั่วโลก'
    this.content = ""
  }

}
