import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: '1949', description: 'Established Sanken Sangyo Co., Ltd.' },
      { name: '1970', description: 'Technical tie-up with Chugai Ro Co., Ltd. on continuous reheating furnace' },
      { name: '1980', description: 'Started collaboration with Sun Furnace Co., Ltd. on electric furnaces' },
      { name: '1990', description: 'Established Sanken Tech Co.,Ltd.' },
      { name: '1992', description: 'Established Sanken Access Co.,Ltd.' },
      { name: '1994', description: 'Won the Energy Conservation Award from The Japan Machinery Federation' },
      { name: '1996', description: 'Technical License Agreement with Insertec, Spain' },
      { name: '1997', description: 'Established NEU—Sanken with Northeastern University in China' },
      { name: '1999', description: 'Obtained ISO 9001 certification' },
      { name: '2000', description: 'Renewed Technical License Agreement with SAC, Korea' },
      { name: '2002', description: 'Established Sanken Insertec Europe (SIE) with Insertc in Spain' },
      { name: '2008', description: 'Head Office moved to present location' },
      { name: '2009', description: 'Split into Sanken Holdings Co., Ltd.(Holding Company) and Sanken Sangyo Co., Ltd. (Operational Company)' },
      { name: '2011', description: 'Established PT. SWIF ASIA with PT. WIN in Indonesia' },
    ]
  }

}
