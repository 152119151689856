import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'src/app/model/Category';

@Component({
  selector: 'app-introbiz',
  templateUrl: './introbiz.component.html',
  styleUrls: ['./introbiz.component.css']
})
export class IntrobizComponent implements OnInit {

  category: Category[];

  constructor() { }

  ngOnInit() {
    this.category = this.getIntroductionOfBiz()
  }

  getIntroductionOfBiz() {
    return [
      {
        image: 'img/introbiz/infoImage02.jpg',
        name: 'Our Only-One',
        description: 'Introduction of our own original product supporting industry.',
        link: '/en/onlyone'
      }, {
        image: 'img/introbiz/infoImage03.jpg',
        name: 'Products (Aluminium Areas)',
        description: 'Introduction of our proud furnaces for melting, heat-treatment and scrap processing for aluminium industry.',
        link: '/en/aluminium'
      }, {
        image: 'img/introbiz/infoImage04.jpg',
        name: 'Products (Steel Areas)',
        description: 'Introduction of our proud furnaces for reheating, heat treatment and zinc galvanizing for steel industry.',
        link: '/en/steel'
      }, {
        image: 'img/introbiz/infoImage05.jpg',
        name: 'Research & Development',
        description: 'Introduction of our present and past research & development.',
        link: '/en/rd'
      }, {
        image: 'img/introbiz/infoImage06.jpg',
        name: 'Overseas References',
        description: 'Introduction of our references for overseas countries.',
        link: '/en/oversea'
      }, {
        image: 'img/introbiz/infoImage07.jpg',
        name: 'Energy Saving',
        description: 'Introduction of energy-saving technologies we have worked on.',
        link: '/en/energy'
      }, {
        image: 'img/introbiz/infoImage08.jpg',
        name: 'Proposal of Renovation',
        description: 'Introduction of innovation examples we have worked on in energy saving.',
        link: '/en/proposal'
      }, {
        image: 'img/introbiz/infoImage09.jpg',
        name: 'Maintenance',
        description: 'Introduction of maintenance activities including safety training.',
        link: '/en/maintenance'
      }
    ]
  }

}
