<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Proposal of Renovation</h3>
          </div>
          <p>
            Introduction of innovation examples we have worked on in energy saving.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="6"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Continuous Steel Reheating Furnace – Fuel Conversion</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_01.jpg" alt="" />
				<p>Before fuel conversion (fuel: Low sulfur Bunker A) </p>
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_02.jpg" alt="" />
				<p>Reheating furnace under construction </p>
              </div>
            </div>
            <p>This renovation was addressed to fuel conversion and replacement of the upper furnace body of the
              existing walking beam reheating furnace. Only 35 days of short shutdown period after starting dismantling,
              the furnace could be put into reoperation. After renovation, the reheating capacity was increased by
              approximately 8% and the specific fuel consumption was decreased by approximately 9%. Also, properly
              controlled furnace pressure and strengthened thermal insulation decreased the furnace skin temperature to
              a large degree, and improved working environments.
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Aluminium Melting Furnace – Fuel Conversion to Gas</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_03.jpg" alt="" />
				<p>Before fuel conversion (fuel: Bunker A) </p>
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_04.jpg" alt="" />
				<p>After fuel conversion (fuel: City gas 13A) </p>
              </div>
            </div>
            <p>This renovation was addressed to conversion from bunker-fired conventional burners to gas-fired
              regenerative burners. Along with labor saving by automating temperature control for both molten metal and
              furnace atmosphere, the specific fuel consumption was improved by approximately 30%, and CO2 emission was
              also decreased by 50% by a synergy of conversion to natural gas base city gas.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Roll Heat Treatment Furnace – Fuel Conversion to Gas</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_05.jpg" alt="" />
				<p>Before fuel conversion (fuel: Bunker A) </p>
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_06.jpg" alt="" />
				<p>After fuel conversion (fuel: City gas 13A) </p>
              </div>
            </div>
            <p>With the fuel conversion of car bottom roll heat treatment furnace, we have installed downwardly high
              velocity burners on the roof which are On/Off cycle and zone free controlled, as a result, temperature
              distribution in the furnace was improved and specific fuel consumption, emissions of NOx, CO2 and CO were
              also substantially reduced.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Renewal of Rotary Hearth Heating Furnace for Forging</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/proposal/improve_07.jpg" alt="" />
				<p>Conventional rotary hearth furnace</p>
              </div>
              <div class="col-md-6">
                <img src="img/proposal/improve_08.jpg" alt="" />
				<p>Renewed rotary hearth furnace </p>
              </div>
            </div>
            <p>We have made revolutionary improvements to an existing rotary hearth heating furnace used for die
              forging, which achieve energy saving by 50% or more and reduce scale losses. Additionally, automated
              loading/unloading system for the materials to be heated has made operators free from extremely hot work.
              Taking this opportunity, a new provision was added to the furnace which allows the hearth to be extracted
              outside the furnace for easy maintenance.
            </p>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>