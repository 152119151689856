<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ติดต่อเรา</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-page">
  <div class="thm-container">
    <div class="title">
      <h2>ที่ตั้ง</h2>
      <p>
        บริษัท ซังเค็น อินดัสเทรียล เฟอร์เนส (ประเทศไทย) จำกัด <br />
        88/31 นิคมอุตสาหกรรมเอเชีย หมู่ที่4 ตำบลคลองสวน อำเภอบางบ่อ จังหวัดสมุทรปราการ ประเทศไทย 10560 <br />
        โทรศัพท์ : +66-2-130-6236<br />
        แฟกซ์ : +66-2-130-6238<br />
        อีเมล์ : inquiry@sanken.co.th
      </p>
    </div>

  </div>
</section>


<app-footer lang="th"></app-footer>