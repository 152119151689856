<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ธุรกิจหลักขององค์กร</h3>
          </div>
          <!-- <p>
            Introduction our main business line.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="COMP" menuIndex="0"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>What's SANKEN</h3>
            <br />
            <p>บริษัท ซังเค็นซังเกียว จำกัด เป็นบริษัทที่ดำเนินการวางแผน ออกแบบ ก่อสร้างและบำรุงรักษาเตาอุตสาหกรรม
              เราได้เริ่มทำธุรกิจเตาอุสาหกรรมตั้งแต่ปี พ.ศ.2492 จนถึงปัจจุบันเราได้มีการส่งมอบผลิตภัณฑ์ เทคโนโลยี
              และบริการที่เกี่ยวข้องกับเตาอุตสาหกรรม รวมถึงสร้างความพึงพอใจให้แก่ลูกค้าทั้งในประเทศญี่ปุ่น และอีกมากกว่า
              30 ประเทศทั่วโลก
            </p>
            <hr/>
          </div>

          <div class="blog-post-style-one">
            <h3>ความจำเป็นของอุตสาหกรรมเตาหลอม</h3>
            <br />
            <div class="text-center">
              <img src="img/whatsanken/icorp02.gif" alt="" />
            </div>
          </div>

          <div class="blog-post-style-one">
            <p>เตาอุตสาหกรรมเป็นอุปกรณ์สำคัญ สำหรับผลิตผลิตภัณฑ์โลหะหรือส่วนประกอบต่างๆ ที่เราคุ้นเคยในชีวิตประจำวัน
              เช่น ชิ้นส่วนยานยนต์ ซึ่งในการผลิตจำเป็นต้องผ่านกระบวนการหลอมและหล่อด้วยความร้อน อุตสาหกรรมเตาหลอมของเรา
              ไม่เพียงแต่ยอดเยี่ยมในด้านการประหยัดพลังงาน การรีไซเคิลเศษเหล็ก และใช้เทคโนโลยีขั้นสูงเท่านั้น
              แต่ยังมีส่วนร่วมในการอนุรักษ์สิ่งแวดล้อมอีกด้วย
              <br />
              นอกจากนี้เรายังสามารถตอบสนองความต้องการของลูกค้าได้ในทุกด้าน เช่น การลดต้นทุนในการผลิต
              รวมถึงความสามารถที่จะนำเสนอบริการครบวงจรตั้งแต่เริ่มต้นวางแผนการผลิตไปจนถึงการบำรุงรักษาและการให้บริการหลังการขาย
              ด้วยเหตุผลนี้เราจึงได้รับความนิยมจากลูกค้าจำนวนมากมาตั้งแต่อดีตจนถึงปัจจุบัน
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>รูปแบบการดำเนินธุรกิจ</h3>
            <br />
            <p>เราสามารถกล่าวได้ว่าธุรกิจเตาหลอมอุตสาหกรรมนั้น แฝงอยู่ในทุกกลุ่มอุตสาหกรรมการผลิต
              ไม่ว่าจะเป็นอุตสาหกรรมยานยนต์ ซึ่งถือเป็นลูกค้าหลักของธุรกิจอุตสาหกรรมเตาหลอม ที่มีการผลิตโครงสร้าง
              และชิ้นส่วนล้อยานยนต์ อุตสาหกรรมเหล็ก ที่มีการผลิตและขึ้นรูปเหล็ก
              หลากหลายรูปแบบเพื่อตอบสนองการใช้งานที่แตกต่างกันออกไป
              <br />
              รวมถึงผลิตภัณฑ์โลหะอื่นๆที่ใช้ในการผลิตสาธารณูปโภคขั้นพื้นฐาน เช่น กังหันในโรงไฟฟ้า
              วัสดุสถาปัตยกรรมที่ใช้สำหรับการก่อสร้างอาคาร
              <br />
              นอกจากนี้เตาหลอมอุตสาหกรรมยังขยายไปไปถึงอุตสาหกรรมการบินและอวกาศ เนื่องจากใช้ในการผลิตชิ้นส่วนจรวดอวกาศ
              และมีส่วนเกี่ยวข้องในการผลิตจรวดอวกาศนานาชาติ HIIB อยู่แล้ว
              ซึ่งถือเป็นอุตสาหกรรมที่มีการใช้เทคโนโลยีที่ทันสมัยที่สุด
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เป้าหมายของบริษัท</h3>
            <br />
            <h4>มีส่วนร่วมในการอนุรักษ์สิ่งแวดล้อมโลก</h4>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/icorp03.jpg" alt="" />
            </div>
            <p>
              การพัฒนาผลิตภัณฑ์และเทคโนโลยีของเรา ยังคงควบคู่ไปกับการอนุรักษ์สิ่งแวดล้อมของโลกเสมอ
              เนื่องจากเราตะหนักถึงปัญหาสิ่งแวดล้อมโลก ที่กำลังทวีความรุนแรงขึ้นเรื่อยๆ
              จากที่ชั้นโอโซนของโลกกำลังถูกทำลายลง ปัจจุบันส่งผลกระทบต่อเนื่อง
              ทำให้สภาพอากาศมีความผิดปกติและกระทบต่อสิ่งมีชีวิตบนโลก
              ดังนั้นการดำเนินงานของเราจึงมุ่งเน้นการประหยัดพลังงาน ลดการปล่อยก๊าซเรือนกระจก
              และใช้ทรัพยากรที่มีอยู่ให้เกิดประโยชน์สูงสุด
            </p>
            <br />
            <h4>มีส่วนร่วมในการพัฒนาความเป็นอยู่และสังคมของมนุษย์</h4>
            <br />
            <p>
              อุตสาหกรรมเตาหลอมของเรา มีส่วนร่วมในการพัฒนาความเป็นอยู่มนุษย์ ไม่ว่าจะเป็นยานพาหนะต่างๆ
              ที่ส่งผลให้มนุษย์มีชีวิตความเป็นอยู่มีความสะดวกสบายมากขึ้น หรือใบพัดกังหันในโรงไฟฟ้า
              ล้วนแต่ผลิตขึ้นด้วยเตาหลอมอุตสาหกรรมของเราทั้งสิ้น
            </p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>