<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>


<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Our Only-One</h3>
          </div>
          <p>Introduction of our own original product supporting industry.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="0" ></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Heating Furnace for Hot Pressing</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_01.jpg" alt="" />
            </div>
            <p>Hot pressing is an innovative technology not only to achieve automotive lightening, but also to produce
              automotive body components with high mechanical strength by simultaneously performing a press forming
              and
              quenching hot heated steel plate. We have developed a roller hearth heating furnace dedicated for hot
              pressing, making further improvements, contribute actively to reduce carbon emissions in automotive
              steel
              product sector, too. We can provide a hot pressing heating furnace in both gas-fired and electric
              heating
              designs.
            </p>
            <!-- <div class="text-right">
              <a href="#" class="read-more">Read More</a>
            </div> -->
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Intelligent Walking Beam Heat Treatment Furnace "ITG-T6"</h3>
            <br />
            <div class="col-md-5 text-center">
              <img src="img/onlyone/one_02.jpg" alt="" />
            </div>
            <p>We have developed a new multiple-stage continuous walking beam design as T6 heat treatment furnace for
              alloy motor wheels.
              Sensing wheel diameter at charge inlet end, the beam traveling pitch of each individual stage is
              automatically controlled. This furnace is so advanced in energy saving, combining this higher productivity
              with our original hot gas recirculation system within the furnace for both rapid heating-up and
              temperature uniformity of the work-pieces.
            </p>
            <!-- <div class="text-right">
              <a href="#" class="read-more">Read More</a>
            </div> -->
            <hr />
          </div>


          <div class="blog-post-style-one">
            <h3>Aluminium Recycling Rotary Kiln / IDEX and Eco-Kiln</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_03.jpg" alt="" />
            </div>
            <p>Pretreatment rotary kilns, IDEX and Eco-Kiln, are also available.
              These are used to continuously dry machining chip being wetted with coolant prior to melting, achieving
              incineration of fume generating from coolant during drying and detoxifying it, which is energy and
              resources saving unit with higher productivity, facilitating in-house recycling of the machining chip and
              lowering production costs.
              Also, we have a lot of references of chip drying unit, including for decoating UBC.
              Eco-Kiln is suitable for drying a small amount of machining chip (up to approx. 350 kg/h), IDEX is
              suitable for drying a large amount of machining chip and decoating UBC as well.
              Not drying/decoating unit alone, we can provide an entire pretreatment line including crusher, centrifuge,
              etc. for machining chip, and including debaler/shredder, etc. for UBC.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Electric Melting & Holding Furnace "S-MIC"</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_04.jpg" alt="" />
            </div>
            <p>S-MIC is a fully electrified melting & holding furnace, equipping specially high-efficient and high-power
              immersion heaters. This design can substantially reduce oxidation loss of molten metal with no carbon
              emission. This is also an energy-saving furnace that can produce high quality molten metal in lower costs.
              S-MIC, our product name, means Sanken Melting, Immersion and Circulation.
            </p>
            <!-- <div class="text-right">
              <a href="#" class="read-more">Read More</a>
            </div> -->
            <br/>
            <br/>
            <br/>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Large Steel Ingot Reheating Furnace for Free Forging</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_05.jpg" alt="" />
            </div>
            <p>Photo shows one the world's largest car bottom reheating furnace for 650 tons steel ingot.
              Those batch furnaces are equipped with our patented multi-port regenerative burner system and state of the
              art combustion control technologies to provide advanced performances in heating/soaking and energy saving
              abilities, thus we make a great contribution to the development of Japanese steel casting and forging
              industry.
            </p>
            <br/>
            <br/>
            <br/>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Vertical Sprit Electric Heat Treatment Furnace "VSEF"</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_06.jpg" alt="" />
            </div>
            <p>VSEF is used for heat treatment for long forged products such as turbine rotor shaft and vessel propeller
              shaft. Vertical 2-sprit furnace design enables operator to handle work-piece horizontally, requiring no
              conventional deep pit or high-lift overhead crane. VSEF is floor-mounted, our original heat treatment
              furnace combining workability and heat treating performances.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Continuous Steel Pipe Heat Treatment Furnace</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/onlyone/one_08.jpg" alt="" />
            </div>
            <p>We can provide a roller hearth steel pipe heat treatment furnace highlighting the heat-treated quality of
              the work-pieces. Our newly developed transportation system makes no scratch on the surface of the
              work-piece during traveling in the furnace. Our original burner arrangement and recirculation fans exhibit
              excellent temperature uniformity. This furnace is also energy-saving design equipping a recuperator and an
              air-fuel ratio control system.
            </p>
            <!-- <div class="text-right">
              <a href="#" class="read-more">Read More</a>
            </div> -->
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
