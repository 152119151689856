import { Component, OnInit } from '@angular/core';
import { Outline } from 'src/app/model/Outline';

@Component({
  selector: 'app-th-environment',
  templateUrl: './environment.th.component.html',
  styleUrls: ['./environment.th.component.css']
})
export class EnvironmentThComponent implements OnInit {

  outline: Outline[];

  constructor() { }

  ngOnInit() {
    this.outline = [
      { name: 'หมายเลขใบรับรอง', description: '0000723' },
      { name: 'ผู้ได้รับการรับรอง', description: 'Sanken Sangyo Co., Ltd.' },
      { name: 'ที่ตั้งและโรงงาน', description: 'สำนักงานใหญ่, สาขาโตเกียว, สาขาโอซาก้า, สาขาชุบุ , สาขาฮอกไกโด, สาขาโฮกูริกุ และโรงงานผลิต' },
      { name: 'วันที่ได้รับการรับรอง', description: '31 มีนาคม 2549' },
      { name: 'วันที่เปลี่ยนแปลงล่าสุด', description: '31 มีนาคม 2555' },
      { name: 'ผู้ออกใบรับรอง', description: 'Institute for Promoting Sustainable Societies' },
    ]
  }

}
