import { Component, OnInit } from '@angular/core';
import { News } from 'src/app/model/News';

@Component({
  selector: 'app-th-news',
  templateUrl: './news.th.component.html',
  styleUrls: ['./news.th.component.css']
})
export class NewsThComponent implements OnInit {

  news: News[];

  constructor() { }

  ngOnInit() {

    this.news = [];
    this.news.push(this.getNews(new Date(), 'เปิดตัวเว็บไซต์', 'เปิดตัวเว็บไซต์'))

  }

  getNews(date: Date, title: string, content: string) {
    let item = new News();
    item.id = '1';
    item.date = new Date('2019-03-31');
    item.title = title;
    item.content = content;
    return item;
  }

}
