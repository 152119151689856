<app-header lang="th"></app-header>

<app-nav lang="th"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>ผลิตภัณฑ์ (กลุ่มเหล็ก)</h3>
          </div>
          <!-- <p>
            Introduction of our proud furnaces for reheating, heat treatment and zinc galvanizing for steel industry.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="th" menuType="INTRO" menuIndex="2"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>เตาหลอมสำหรับหลอมเหล็กขนาดใหญ่</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_01.jpg" alt="" />
            </div>
            <p>เตาหลอม-อุ่นร้อน ด้านล่างรถยนต์สำหรับอุตสาหกรรมการตีเหล็กแบบอิสระ
              เป็นเตาที่ลดการสูญเสียความร้อนให้น้อยที่สุดด้วยโครงสร้างเตาไม่ใช้น้ำหล่อเย็น,
              มีกลไลในการปิดผนึกเครื่องจักรอย่างมิดชิด
              และยังเป็นเตาหลอมประหยัดพลังงานมีประสิทธิภาพการทำความร้อนที่สม่ำเสมอ
              โดยมีระบบเผาไหม้ติดหลังคาด้วยระบบหัวเตาแบบ regenerative ที่มีหลายช่อง (roof-mounted multi-port
              regenerative burner system) และด้วยระบบเทคโนโลยีควบคุมสัดส่วนของ อากาศ-เชื้อเพลิง
              ฃผลิตภัณฑ์ของเราจึงประหยัดพลังงานและมีการปล่อยก๊าซ Nitrogen oxides (NOx) ในระดับต่ำ
              ซึ่งสามารถจุก้อนโลหะ/เหล็กกล้าได้ถึง 650 ตัน
              <br />
            </p>
            <br />
            <br />
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-หล่อเย็นด้วยไฟฟ้าแบบแท่งแนวตั้ง “VSEF”</h3>
            <br />
            <div class="col-md-12 shift-top shift-bottom">
              <div class="col-md-4 text-center">
                <img src="img/steel/steel_03.jpg" alt="" />
              </div>
              <div class="col-md-4 text-center">
                <img src="img/steel/steel_04.jpg" alt="" />
              </div>
              <div class="col-md-4 text-center">
                <img src="img/steel/steel_12.jpg" alt="" />
              </div>
            </div>
            <p>เตาแบบแยกความร้อนแนวตั้งแบบแยกส่วน
              พัฒนาโดยเน้นจุดเด่นของเตาแนวตั้งในเรื่องของระบบควบคุมอุณหภูมิเพื่อปรับสมดุล
              และข้อดีของระบบแยกเข้าไว้ด้วยกันเพื่อให้ชิ้นงานสามารถเคลื่อนย้ายในแนวนอนได้
              ดังนั้นจึงไม่จำเป็นต้องมีหลุมลึกธรรมดาหรือเครนเหนือศีรษะยกสูง ทำให้สามารถปฎิบัติงานได้อย่างปลอดภัย
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาเผาแบบเคลื่อนที่เป็นเตาชุบความร้อน</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_05.jpg" alt="" />
            </div>
            <p>เราได้ออกแบบและพัฒนาเตาเผารูปแบบใหม่ที่เน้นเคลื่อนย้ายตัวเตาเผา ซึ่งเตาเผาแบบนี้มีข้อดีหลายประการ เช่น
              ช่วยลดต้นทุนการสร้างและค่าใช้จ่ายในการซ่อมแซม
              ช่วยปรับปรุงประสิทธิภาพการผลิตและความปลอดภัยเมื่อโหลดชิ้นงานเข้าเตา
              ลดการเสียรูปของชิ้นงานระหว่างการอบชุบเป็นต้น
              นอกจากนี้เตาหลอมของพวกเราก็ได้ติดตั้งทั้งหัวเตาเผาแบบอุ่นตัวเอง(self-recuperative burners) และ
              ด้วยระบบเผาไหม้แบบเกิดใหม่(regenerative burners ) อีกด้วย
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาม้วน (Coiling Furnace)</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_06.jpg" alt="" />
            </div>
            <p>เตาม้วน (Coiling Furnace) เป็นอุปกรณ์ทางความร้อนที่ใช้เพื่อหมุนด้วยแผ่นเหล็กกล้าแสตนเลสร้อน
              ด้วยความเร็วสูง ทำความให้เดือดและทำให้ร้อนขณะกลิ้งในเตาม้วน Steckel mill. S, heat and hot
              นี่เป็นหนึ่งในผลิตภัณฑ์ที่ไม่เหมือนใครของพวกเรา
              เตาม้วนของพวกเราได้เพิ่มระบบกู้คืนการสูญเสียความร้อนโดยเครื่องแลกเปลี่ยนความร้อนและหัวเตาเผาแบบแบบปล่อย
              NOx ปริมาณต่ำเท่ามาตรฐาน
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-อุ่น แบบไม่มีปฎิกริยา Oxidation สำหรับเหล็กกล้าม้วนแบบพิเศษ</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_07.jpg" alt="" />
            </div>
            <p>เตาหลอมต่อเนื่องแบบไม่มีปฎิกริยา Oxidation ถูกใช้เพื่ออุ่นเเหล็กกล้าผสม เช่น นิกเกิล (nickel, Ni)
              การใช้ลูกกลิ้งแบบเตาพิเศษที่ถูกพัฒนารุ่นล่าสุดของเรา จะทำให้เราสามารถสร้างเร่งความร้อนได้สูงถึง 1300 องศา
              C สำหรับวัตถุที่มีมิติมากถึง 1350 มิลลเมิตร (กว้าง) 200 มิลลิเมตร (หนา( และ 12,000mm (ยาว)
              <br />
              <br />
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาเผาความร้อนอย่างต่อเนื่อง</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_08.jpg" alt="" />
            </div>
            <p>เราได้ทำการพัฒนาเตาหลอม-อุ่นโลหะแบบต่อเนื่องหลายประเภท เช่น เครื่องฉายลำแสง, เตาเผาเคลื่อนย้ายได้,
              เตาเผาแบบหมุนและอื่นๆ สำหรับเตาหลอมที่ติดตั้งเครื่องฉายลำแสงความร้อนขนาดใหญ่
              เรามีการร่วมมือกันทางเทคนิคกับ บริษัทญี่ปุ่น Chugai Ro Co., Ltd., Japan. เมื่อไม่นานมานี้
              เราได้มีการประยุกต์ใช้งานหัวเตาเผาแบบเกิดใหม่ (regenrative burner) ได้รับความสนใจเป็นจำนวนมาก
              นอกจากนี้เราได้รวบรวมระบบควบคุมเข้ากับเทคโนโลยีควบคุมสัดส่วนของ อากาศ-เชื้อเพลิง
              และมีการติดตั้งลงไปยังหัวเตาเผาแต่ละชิ้น
              ซึ่งช่วยในการประหยัดพลังงานและปรับปรุงการผลิตให้มีประสิทธิภาพยิ่งขึ้น
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอมโลหะสำหรับการอัดความร้อน</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/steel/steel_09.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_10.jpg" alt="" />
              </div>
            </div>
            <p>เพื่อการออกแบบเตาหลอมโลหะที่เข้ากับความต้องการของผลิตภัณฑ์ของท่านลูกค้า เราได้ทำการทดสอบ
              สภาวะการทำความร้อนทีดีและเหมาะสมที่สุด โดยมีตัวแปรคืออุณหภูมิและระยะเวลาที่ใช้ทดสอบกับเตาหลอม
              โดยอ้างอิงจากความต้องการ (requirement)ของลูกค้าเช่น รูปร่าง ความหนาของวัสดุ ด้วยการชุบ/ไม่ชุบโลหะ
              (galvanized) ความหนาของชั้นเคลือบ (galvanized layer)
              สำหรับรูปแบบของเตาหลอม เราใช้ดีไซน์แบบเตาหลอมสายพานเป็นหลัก แต่เราสามารถจัดหาดีไซน์แบบ เครื่องฉายลำแสง
              หรือ สานพานลำเลียงแบบกันความร้อน สำหรับแหล่งพลังงานความร้อน เรามีดีไซน์แบบให้พลังงานความร้อนด้วยไฟฟ้า
              หรือให้พลังงานด้วยก๊าซ ซึ่งจะลดระดับออกซิเจนในเตาหลอมให้ต่ำกว่าสัดส่วนของการติดไฟ(อากาศ-เชื้อเพลง)
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>อ่างเซรามิค - เตาหลอมชุบสังกะสี</h3>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/steel/steel_11.jpg" alt="" />
            </div>
            <p>เราภูมิใจที่ได้ครองส่วนแบ่งตลาดเป็นอันดับต้นๆ
              ของเตาหลอมชุบสังกะสีแบบต่อเนื่องสำหรับลวดเหล็กกล้าแบบมีคาร์บอนสูง (hard steel wire)เช่น เชือกลวด และ
              ลวดเหล็กกล้าแบบมีคาร์บอนต่ำ (soft steel) เช่นรั้ว
              และเช่นเดียวกันกับเตาหลอมสำหรับโครงสร้างเหล็กกล้าที่มีขนาดใหญ่เช่นสะพาน
              สำหรับการเคลือบพื้นผิวเหล็กด้วยสังกะสี (galvanizing ) เรามี
              เครื่องอำนวยความสะดวกแบบระบบไฟฟ้าสำหรับชุบสังกะสีซึ่งถูกพัฒนาใหม่ล่าสุดเช่นเดียวกับแทงค์แช่กรดประสิทธิภาพสูงและป้องกันไอหรือควันที่เกิดจากการหล่อเย็น
              เรายังมีรุ่นประหยัดพลังงานที่ใช้การรวมกันของหัวเตาเผาแบบอุ่นตัวเอง (ซีรีย์ REKUMAT ผลิตโดยเยอรมัน)
              และท่อเซรามิกจุ่มซึ่งหลอมสังกะสีเหลวจากข้างใน
              <br />
              เราได้ออกแบบอ่างเซรามิกแบบใหม่เพื่อให้สามารถรับมือกับการชุบสังกะสีและอลูมิเนียม
              ซึ่งสามารถลดค่าบำรุงรักษาและอัตราการสูญเสียโลหะสังกะสี
              และยังช่วยลดความเสี่ยงของการต้องหยุดผลิตแบบไม่ทันตั้งตัวได้อย่างมาก
              และช่วยลดอัตราการเสียโอกาสที่เกิดจากการใช้โลหะจนหมด
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาเผาความร้อนท่อเหล็กอย่างต่อเนื่อง</h3>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/steel/steel_13.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_14.jpg" alt="" />
              </div>
            </div>
            <p>เตาหลอม-หล่อเย็นสายพานสำหรับงานท่อเหล็กถูกติดตั้งด้วย หัวเตาเผาแบบด้านข้าง (side
              burners)ที่ถูกพัฒนารุ่นใหม่ล่าสุด ซึ่งมาพร้อมกับท่อรังสี
              และการจัดการอย่างเหมาะสมของพวกเราต่อระบบหัวเตาเผาซึ่งปรับปรุงความนิ่งของอุณหภูมิสำหรับทิศทางตรงข้ามของเตาหลอม
              และช่วยอำนวยความสะดวก รูปแบบการให้ความร้อนแบบแนวนอน (longitudinal heating patterns) ภายในเตาหลอม
              เรายังออกแบบท่อที่ยืดออก (elongated tube) ให้อย่างอิสระตามความต้องการของลูกค้า เช่นระยะเวลาในแต่ละส่วน
              สำหรับการหลอม จุ่ม และการหล่อเย็น นอกจากนั้นเรายังป้องกันท่อไม่ให้มีรอยขีดข่วนด้วยกลไกการนำทางท่อของเรา
              (pipe guide mechanism) เพื่อที่จะสร้างดีไซน์แบบประหยัดพลังงาน เราจึงได้นำ EBC system
              (ระบบควบคุมการเผาไหม้แบบง่าย) ซึ่งสามารถทำให้แก๊ซลุกเป็นไฟด้วยอากาศที่ถูกสันดาปมาก่อนแล้ว (preheated
              combustion air) ผ่านเครื่องแลกเปลี่ยนความร้อน(recuperator) ในอัตราส่วน อากาศ-เชื้อเพลิง โดยมีย่านการวัด
              (turn-down ratio) ที่เหมาะสม
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>เตาหลอม-หล่อเย็น และเตาหลอมแบบม้วนความเร็วสูงที่แตกต่าง (roll rapid differential heating furnace)</h3>
            <br />
            <p>นี่คือผลิตภัณฑ์เตาหลอมที่สร้างขึ้นเพื่อกรรมวิธีทางความร้อนทางการรีดเหล็ก (mill
              rolls)ซึ่งถูกใช้สำหรับกระบวนการ การรีดร้อน (hot rolling)
              โดยจะมีการหมุนปรับอุปกรณ์สายพานและระยะเวลาในการให้ความร้อนของเตาเผาซึ่งสามารถทำได้จากระยะไกล
              โดยอ้างอิงจากเส้นผ่าศุนย์กลางและความยาวของการม้วนที่ต้องการ
              วัสดุหลอมถูกออกแบบมาให้ถูกแยกออกเพื่อให้เข้ากันได้กับการม้วนและ
              หัวเตาเผาแบบแผ่ความร้อนที่ถูกติดตั้งมากับผนังเตาหลอมเป็นจำนวนมากซึ่งสามารถหลอมผิวเนื้อของเหล็กรีดได้อย่างเป็นหนึ่งเดียวกันและอย่างรวดเร็ว
              <br />
              <br />
            </p>
            <div class="col-md-12 text-center">
              <div class="col-md-6">
                <img src="img/steel/steel_15.jpg" alt="" />
              </div>
              <div class="col-md-6">
                <img src="img/steel/steel_16.jpg" alt="" />
              </div>
            </div>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="th"></app-footer>