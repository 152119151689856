<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Energy Saving</h3>
          </div>
          <p>
            Introduction of energy-saving technologies we have worked on.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="INTRO" menuIndex="5"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>Energy-saving Technologies We have Worked on</h3>
            <br />
            <div class="col-md-6 text-left">
              <img src="img/energy/ene_save01.gif" alt="" />
            </div>
            <p>In 1970, we built Japan’s first energy-saving industrial furnace lined with ceramic fiber blanket, since
              then, we have been promoting energy saving in the industrial furnaces, developing new combustion and waste
              heat recovery technologies one after another.
              <br />
              <br />
              <br />
            </p>
            <hr />
          </div>

          <div class="service-single-content">
            <h3>Equipment improvements to achieve energy savings</h3>
            <div class="row image-col">
              <div class="col-md-6">
                <p>Factors as object of energy saving are roughly summarized as the following 3 items.</p>
                <ul class="checklist">
                  <li><img src="img/service-bulleted-list.png" alt="Awesome Image" /> Furnace body design
                    (downsizing, strengthened insulating, reduction of miscellaneous losses such as cooling water loss,
                    etc.)</li>
                  <li><img src="img/service-bulleted-list.png" alt="Awesome Image" /> Waste heat recovery
                    (recuperator, regenerative burner, etc.)</li>
                  <li><img src="img/service-bulleted-list.png" alt="Awesome Image" /> Combustion control
                    (air-fuel ratio control, furnace pressure control, optimization of heating curve, etc.)</li>
                </ul>
              </div>
              <div class="col-md-6 text-right">
                <img src="img/energy/ene_save02.gif" alt="" />
              </div>
            </div>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Example of furnace body design (downsizing, strengthened insulating, reduction of miscellaneous losses)
            </h3>
            <br />

            <h4>Downsizing Design of Aluminium Heat Treatment Furnace</h4>
            <br />
            <div class="col-md-4 text-left shift-right shift-bottom">
              <img src="img/energy/ene_save01.jpg" alt="" />
            </div>
            <p>
              Minimized furnace body reduces furnace surface area by 25%, achieving energy and installation space
              savings.
            </p>
            <br />
          </div>

          <br />
          <br />
          <br />
		  
          <div class="blog-post-style-one">
            <h4>Highly-insulated Furnace Body</h4>
			<br />
			<p>
              Weight-saving of lining materials has reduced both inertia heat loss and furnace shell radiation heat loss
              dramatically.
            </p>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/energy/ene_save02.jpg" alt="" />
				<p>Typical ceramic fiber block</p>
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save03.gif" alt="" />
              </div>
			  <br>
			  <br>
              <div class="col-md-6">
                <img src="img/energy/ene_save03.jpg" alt="" />
				<p>Conventional refractory</p>
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save04.gif" alt="" />
              </div>
            </div>
            
          </div>

          <br />
          <br />
		  <br />
          <div class="blog-post-style-one">
            <h4>Reduction of Cooling Water Heat Loss</h4>
            <br />
            <div class="col-md-12 text-center shift-bottom">
              <div class="col-md-6">
                <img src="img/energy/ene_save04.jpg" alt="" />
				<p>Fully waterless-designed large reheating furnace for forging</p>
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save05.jpg" alt="" />
				<p>Strengthened insulation of skids in continuous reheating furnace</p>
              </div>
            </div>
            <p>
              Renovation to highly-insulated refractory constitution has reduced cooling water heat loss by 30%. Not
              only that, we have eliminated cooling water heat loss from large ingot reheating furnace for free forging
              by making the lintel to be waterless design.
            </p>
            <hr />
          </div>
		
          <div class="blog-post-style-one">
            <h3>Example of Waste Heat Recovery</h3>
            <br />
			<div class="col-md-12 text-center shift-bottom shift-top">
              <div class="col-md-6">
                <img src="img/energy/ene_save06.jpg" alt="" />
				<p>Patented multi-port regenerative burner</p>
              </div>
              <div class="col-md-6">
                <img src="img/energy/ene_save07.jpg" alt="" />
				<p>Heat treatment furnaces with recuperative gas burner</p>
              </div>
            </div>
            <div class="col-md-4 text-left shift-right-2">
              <img src="img/energy/ene_save05.gif" alt="" />
            </div>
            
            <p>Self-recuperative burners have less heat loss compared to the conventional central recuperator, thanks to
              this burner design, we have achieved approximately 15% of energy saving in car bottom heat treatment
              furnaces.
			  <br />
              Our patented multi-port regenerative burner system is excellent in temperature uniformity in the furnace
              chamber because of distributed flame patterns. Combine with our own air-fuel ratio control technology per
              each individual burner, we can expect stable and energy-saving effect.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Combustion Control (air-fuel ratio control, furnace pressure control, optimization of heating curve,
              etc.)</h3>
            <br />
            <h4>Air-fuel Ratio Control Technology</h4>
            <br />
            <p>
              Our original air-fuel ratio control technology per each individual burner is a control system that can
              adjust individual burner combustion in response to the every changing situation. Particularly, in
              regenerative burner control, it can reduce oxygen level in the furnace as well as power consumptions of
              combustion air blower and exhaust induced draft fan.
            </p>
            <br />
            <h4>Energy Saving by Optimization of Heating Curve</h4>
            <br />
            <p>
              Our new On/Off control has a functionality to minimize the deviation between heating curve and process
              temperatures, and can improve uselessness and unreasonableness in burner combustion by its automated
              function to change control zone partitioning.
            </p>
            <br />
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>