<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>Business Locations</h3>
          </div>
          <p>
            Introduction of our offices within Japan and abroad.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details single-blog-post-page sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="COMP" menuIndex="5"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">

          <div class="blog-post-style-one">
            <h3>Business Locations</h3>
            <br />
            <div class="text-center">
              <img src="img/bizlocate/location_01.gif" style="width: 80%;" alt="" />
            </div>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-details-section">
  <div class="thm-container">

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=34.465512%2C132.388064&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Head Office / Factory</h3>
        </div>
        <div class="content-text">
          <p>
            3-1-2 Tomonishi, <br />
            Asaminami-ku, <br />
            Hiroshima 731-3169, <br />
            Japan<br />
            Phone: +81-82-849-6790<br />
            Facsimile: +81-82-849-6890
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=35.693366%2C139.775525&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Tokyo Office</h3>
        </div>
        <div class="content-text">
          <p>
            2-6-2 Iwamotocho, <br />
            Chiyoda-ku, <br />
            Tokyo 101-0032, <br />
            Japan<br />
            Phone: +81-3-3865-1271<br />
            Facsimile: +81-3-3865-1276
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=34.968842%2C137.059882&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Chubu Office</h3>
        </div>
        <div class="content-text">
          <p>
            1-1-1 Mikawaanjocho, <br />
            Anjo-shi, <br />
            Aichi 446-0051, <br />
            Japan<br />
            Phone: +81-566-72-5571<br />
            Facsimile: +81-566-72-5554
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=42.639348%2C141.612852&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Hokkaido Office</h3>
        </div>
        <div class="content-text">
          <p>
            3-9-19 Motonakanocho, <br />
            Tomakomai-shi, <br />
            Hokkaido 053-0005, <br />
            Japan<br />
            Phone: +81-144-35-5281<br />
            Facsimile: +81-144-35-5288
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=34.72714%2C135.494485&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Osaka Office</h3>
        </div>
        <div class="content-text">
          <p>
            4-12-15 Nishinakashima,<br />
            yodogawa-ku,<br />
            Osaka 532-0011,<br />
            Japan<br />
            Phone: +81-6-6886-6366<br />
            Facsimile: +81-6-6886-6377
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=36.738852%2C137.018709&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Hokuriku Office</h3>
        </div>
        <div class="content-text">
          <p>
            1-8-34, Ekinan, <br />
            Takaoka-shi, <br />
            Toyama 933-0871, <br />
            Japan<br />
            Phone: +81-766-21-7850<br />
            Facsimile: +81-766-21-7850
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=35.425479%2C133.246351&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Yasugi Office</h3>
        </div>
        <div class="content-text">
          <p>
            KanamoriShogyo Bldg.F2 SouthBlock 754-9 Yasugi-cho, <br />
            Yasugi-shi, <br />
            Shimane 692-0011, <br />
            Japan<br />
            Phone: +81-854-21-9300<br />
            Facsimile: +81-854-21-9301
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=34.380053%2C132.458552&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Sanken Access Co., Ltd.</h3>
        </div>
        <div class="content-text">
          <p>
            1-1-72 Higashisendamachi, <br />
            Naka-ku, <br />
            Hiroshima 730-0053, <br />
            Japan
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=34.464663%2C132.387506&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Sanken Tech Co., Ltd.</h3>
        </div>
        <div class="content-text">
          <p>
            3-1-2 Tomonishi Asaminami-ku, <br />
            Hiroshima 731-3169, <br />
            Japan<br />
          </p>
        </div>
        <p class="price"><span>Japan</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=41.685661%2C123.371575&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Shenyang NEU-Sanken Industrial Furnace Mfg. Co., Ltd.</h3>
        </div>
        <div class="content-text">
          <p>
            NO.12 Xuelian Street, <br />
            Sujiatun District,<br />
            Shenyang, China.
          </p>
        </div>
        <p class="price"><span>China</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=43.231834%2C-2.869161&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>Sanken Insertec Europe S.A.</h3>
        </div>
        <div class="content-text">
          <p>
            Avda. Cervantes, <br />
            6-Apartado 109 48970 BASAURI,<br />
            Vizcaya,<br />
            Spain
          </p>
        </div>
        <p class="price"><span>Spain</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=6.395189%2C107.200267&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>PT . SWIF Asia</h3>
        </div>
        <div class="content-text">
          <p>
            Greenland International Industrial Center Blok CC No.10<br />
            - Kota Deltamas Cikarang Pusat<br />
            - Bekasi 17530<br />
            - Indonesia
          </p>
        </div>
        <p class="price"><span>Indonesia</span></p>
      </div>
    </div>

    <div class="product-details-content">
      <div class="img-box">
        <div class="mapouter">
          <div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas"
              src="https://maps.google.com/maps?q=13.661861%2C100.912389&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
              href="https://www.crocothemes.net"></a></div>
        </div>
      </div>
      <div class="content-box">
        <div class="title">
          <h3>SANKEN INDUSTRIAL FURNACE (THAILAND) CO.,LTD</h3>
        </div>
        <div class="content-text">
          <p>
            88/31 Moo.4, Tambol Klong Suan, Amphur Bangbor, <br />
            Samutprakarn Province,<br />
            THAILAND<br />
            10560<br />
          </p>
        </div>
        <p class="price"><span>THAILAND</span></p>
      </div>
    </div>

  </div><!-- /.thm-container -->
</section><!-- /.product-details-section -->

<app-footer lang="en"></app-footer>
