import { Component, OnInit, Input } from '@angular/core';
import { FooterInfo, Link } from 'src/app/model/Footer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() lang: string;
  footer: FooterInfo;

  constructor() { }

  ngOnInit() {

    this.footer = {};
    this.footer.intro = '';


    this.getQuicklink();
    this.getBusiness();
    this.getCompanyInfo();

  }

  getCompanyInfo() {

    this.footer.compInfo = [];

    if (this.lang == 'en') {
      this.footer.compInfo.push(this.getLink("What's sanken", '/' + this.lang + '/whatsanken'));
      this.footer.compInfo.push(this.getLink('Business Philosophy', '/' + this.lang + '/business'));
      this.footer.compInfo.push(this.getLink('Top Message', '/' + this.lang + '/topmsg'));
      this.footer.compInfo.push(this.getLink('Outline of The Company', '/' + this.lang + '/outline'));
      this.footer.compInfo.push(this.getLink('History', '/' + this.lang + '/history'));
      this.footer.compInfo.push(this.getLink('Business Locations', '/' + this.lang + '/bizlocate'));
      this.footer.compInfo.push(this.getLink('Group Company / Partner', '/' + this.lang + '/partner'));
      this.footer.compInfo.push(this.getLink('Environmental Activities', '/' + this.lang + '/environment'));
    } else {
      this.footer.compInfo.push(this.getLink("ธุรกิจหลักขององค์กร", '/' + this.lang + '/whatsanken'));
      this.footer.compInfo.push(this.getLink('แนวคิดการดำเนินธุรกิจ', '/' + this.lang + '/business'));
      this.footer.compInfo.push(this.getLink('สาส์นจากผู้บริหาร', '/' + this.lang + '/topmsg'));
      this.footer.compInfo.push(this.getLink('ข้อมูลบริษัท', '/' + this.lang + '/outline'));
      this.footer.compInfo.push(this.getLink('ประวัติความเป็นมา', '/' + this.lang + '/history'));
      this.footer.compInfo.push(this.getLink('ที่ตั้ง', '/' + this.lang + '/bizlocate'));
      this.footer.compInfo.push(this.getLink('บริษัทในเครือ/หุ้นส่วนการค้า', '/' + this.lang + '/partner'));
      this.footer.compInfo.push(this.getLink('กิจกรรมเพื่อสิ่งแวดล้อม', '/' + this.lang + '/environment'));
    }
  }

  getBusiness() {

    this.footer.business = [];

    if (this.lang == 'en') {
      this.footer.business.push(this.getLink('Our Only-One', '/' + this.lang + '/onlyone'));
      this.footer.business.push(this.getLink('Products (Aluminium Areas)', '/' + this.lang + '/aluminium'));
      this.footer.business.push(this.getLink('Products (Steel Areas)', '/' + this.lang + '/steel'));
      this.footer.business.push(this.getLink('Research & Development', '/' + this.lang + '/rd'));
      this.footer.business.push(this.getLink('Energy Saving', '/' + this.lang + '/oversea'));
      this.footer.business.push(this.getLink('Overseas References', '/' + this.lang + '/energy'));
      this.footer.business.push(this.getLink('Proposal of Renovation', '/' + this.lang + '/proposal'));
      this.footer.business.push(this.getLink('Maintenance', '/' + this.lang + '/maintenance'));
    } else {
      this.footer.business.push(this.getLink('จุดเด่นของเรา', '/' + this.lang + '/onlyone'));
      this.footer.business.push(this.getLink('ผลิตภัณฑ์(กลุ่มอลูมิเนียม)', '/' + this.lang + '/aluminium'));
      this.footer.business.push(this.getLink('ผลิตภัณฑ์(กลุ่มเหล็ก)', '/' + this.lang + '/steel'));
      this.footer.business.push(this.getLink('การพัฒนาและค้นคว้าวิจัย', '/' + this.lang + '/rd'));
      this.footer.business.push(this.getLink('ลูกค้าและผลงาน', '/' + this.lang + '/oversea'));
      this.footer.business.push(this.getLink('การประหยัดพลังงาน', '/' + this.lang + '/energy'));
      this.footer.business.push(this.getLink('นวัตกรรมและเทคโนโลยี', '/' + this.lang + '/proposal'));
      this.footer.business.push(this.getLink('การซ่อมบำรุง', '/' + this.lang + '/maintenance'));
    }
  }

  getQuicklink() {

    this.footer.quicklink = [];

    if (this.lang == 'en') {
      this.footer.quicklink.push(this.getLink('Term of Use', '/' + this.lang + '/termofuse'));
      this.footer.quicklink.push(this.getLink('Privacy Policy', '/' + this.lang + '/privacy'));
    } else {
      this.footer.quicklink.push(this.getLink('ข้อตกลงในการใช้งาน', '/' + this.lang + '/termofuse'));
      this.footer.quicklink.push(this.getLink('นโยบายความเป็นส่วนตัว', '/' + this.lang + '/privacy'));
    }
  }


  getLink(name: string, link: string) {
    let q1 = new Link();
    q1.name = name
    q1.link = link
    return q1;
  }

}
