<app-header lang="en"></app-header>

<app-nav lang="en"></app-nav>

<section class="our-approach">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="our-approach-content">
          <div class="title">
            <h3>What's SANKEN</h3>
          </div>
          <p>
            Introduction our main business line.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-single-details sec-pad">
  <div class="thm-container">
    <div class="row">
      <div class="col-md-3">
        <app-sidebar lang="en" menuType="COMP" menuIndex="0"></app-sidebar>
      </div>

      <div class="col-md-9">
        <div class="has-left-sidebar service-single-content">
          <div class="blog-post-style-one">
            <h3>What's SANKEN</h3>
            <br />
            <div class="text-center">
              <img src="img/whatsanken/icorp01.gif" alt="" />
            </div>
          </div>

          <div class="blog-post-style-one">
            <h3>Necessity of Industrial Furnaces</h3>
            <br />
            <div class="text-center">
              <img src="img/whatsanken/icorp02.gif" alt="" />
            </div>
          </div>

          <div class="blog-post-style-one">
            <p>In order to produce our familiar metallic products or components, for example, aluminium motor wheels,
              melting process of stock and heat treatment process of castings are essential. Industrial furnace is
              essential equipment for such processes.

              Features of our industrial furnaces are not only excellent in energy-saving, scrap recycling and other
              advanced technologies, but also are contributing to the environmental conservation.

              Also, we have ability to propose a new perspective on customer's needs such as cost reduction as well as
              integrated ability to offer consistent services from initial planning to after-sales maintenance, in that
              way, we have been highly reputed by many customers until now.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Field of activity</h3>
            <br />
            <p>We have mainly supported by customers mainly in the steel industry and the automotive industry. Most of
              metallic products around us, such as motor wheels, electronic components used for mobile phone, aluminium
              window frame and so on, are manufactured via industrial furnaces.

              Fundamental metallic products of our daily lives, such as Turbine in the power plant, crankshaft for large
              vessel like oil tanker, architectural materials used for building construction, etc. are also manufactured
              via industrial furnaces.

              Industrial furnaces are absolutely necessary to manufacture products not only for personal belongings but
              also for social infrastructure. It is not too much to say that we cannot live without industrial furnaces.

              By being used in the manufacture of parts of space rocket, industrial furnaces have expanded the playing
              field to the aerospace industry. Our Industrial furnaces are already involved in the production of
              international space rocket HIIB, working on most advanced technology field is expected.
            </p>
            <hr />
          </div>

          <div class="blog-post-style-one">
            <h3>Our Dream</h3>
            <br />
            <h4>Contribution to Global Environmental Conservation</h4>
            <br />
            <div class="col-md-4 text-left shift-right">
              <img src="img/icorp03.jpg" alt="" />
            </div>
            <p>
              Recently, global environmental issues are now changing from the world-scale destruction of the ozone
              layer to the abnormal weather that affects the civic life.

              By addressing the reduction of greenhouse gas emission as well as the realization of resources and energy
              savings through the development of products and furnace technologies, we will continue to contribute to
              the conservation of irreplaceable global environments.
            </p>
            <br />
            <h4>Contribution to the harmonious development of human society</h4>
            <br />
            <p>
              Vehicles and vessels manufactured via our industrial furnace activate the distribution of goods everywhere
              in the world, making our lives more comfortable.

              In the same way, turbine rotor in the power plant is manufactured using our industrial furnaces produces
              electric power indispensable for human life in the world.

              Through industrial furnaces, we will continue to contribute to the harmonious development of human
              society.
            </p>
            <br />
            <hr />
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-footer lang="en"></app-footer>
