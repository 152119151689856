import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'src/app/model/Category';

@Component({
  selector: 'app-th-introbiz',
  templateUrl: './introbiz.th.component.html',
  styleUrls: ['./introbiz.th.component.css']
})
export class IntrobizThComponent implements OnInit {

  category: Category[];

  constructor() { }

  ngOnInit() {
    this.category = this.getIntroductionOfBiz()
  }

  getIntroductionOfBiz() {
    return [
      {
        image: 'img/introbiz/infoImage02.jpg',
        name: 'จุดเด่นของเรา',
        description: 'แนะนำผลิตภัณฑ์ที่เป็นจุดเด่นของเรา',
        link: '/th/onlyone'
      }, {
        image: 'img/introbiz/infoImage03.jpg',
        name: 'ผลิตภัณฑ์(กลุ่มอลูมิเนียม)',
        description: 'แนะนำผลิตภัณฑ์ เพื่อใช้ในอุตสาหกรรมอลูมินียม',
        link: '/th/aluminium'
      }, {
        image: 'img/introbiz/infoImage04.jpg',
        name: 'ผลิตภัณฑ์(กลุ่มเหล็ก)',
        description: 'แนะนำผลิตภัณฑ์ เพื่อใช้ในอุตสาหกรรมเหล็ก',
        link: '/th/steel'
      }, {
        image: 'img/introbiz/infoImage05.jpg',
        name: 'การพัฒนาและค้นคว้าวิจัย',
        description: 'การค้นคว้าวิจัยและพัฒนาตั้งแต่อดีตจนถึงปัจจุบัน',
        link: '/th/rd'
      }, {
        image: 'img/introbiz/infoImage06.jpg',
        name: 'ลูกค้าและผลงาน',
        description: 'กลุ่มลูกค้าจากทั่วโลกที่ใช้ผลิตภัณฑ์และบริการของเรา',
        link: '/th/oversea'
      }, {
        image: 'img/introbiz/infoImage07.jpg',
        name: 'การประหยัดพลังงาน',
        description: 'เทคโนโลยีที่นำมาใช้เพื่อช่วยในการประหยัดพลังงาน',
        link: '/th/thergy'
      }, {
        image: 'img/introbiz/infoImage08.jpg',
        name: 'นวัตกรรมและเทคโนโลยี',
        description: 'นวัตกรรมและเทคโนโลยีที่นำมาใช้ในการสร้างผลิตภัณฑ์',
        link: '/th/proposal'
      }, {
        image: 'img/introbiz/infoImage09.jpg',
        name: 'การซ่อมบำรุง',
        description: 'บริการซ่อมบำรุงและอบรมพนักงาน',
        link: '/th/maintenance'
      }
    ]
  }

}
